import React, { useEffect, useMemo, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Dashboard from "./pages/Dashboard";
import MainNavbar from './components/MainNavbar';
import DefaultBreadcrumb from './components/Breadcrumb';
import { Menu } from './utils/Menu';
import { PageFooter } from './components/PageFooter';
import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
import "../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";
import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap5.css";
import "./assets/_styles.scss";
import {registerLicense} from '@syncfusion/ej2-base';
import Login from './pages/Auth/Login';
import authReducer, { authInitialState } from './reducers/auth';
import ChangePassword from './pages/Auth/ChangePassword';
import ApiCaller from './components/ApiTemplate';
import StateContext, { usePersistedReducer } from './reducers';
import salesReducer, { salesInitialState } from './reducers/sales';
import InvoicePrint from './pages/Sales/public/InvoicePrint';
import DeliveryOrderPrint from './pages/Sales/public/DeliveryOrderPrint';
import DeliveryOrderReceiptPrint from './pages/Sales/public/DeliveryOrderReceiptPrint';
import { Alert } from 'react-bootstrap';
import menuReducer, { menuInitialState } from './reducers/menu';
import background from "./assets/images/dashboard-logo.png";
import backgroundLogin from './assets/images/_login-cover-2.jpg';
import Sidebar from './components/Sidebar';
import moment from 'moment';
import DeliveryOrderReceiptCustomerPrint from './pages/Sales/public/DeliveryOrderReceiptCustomerPrint';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

function App() {
    const { state: authState, dispatch: setAuthState } = usePersistedReducer(authReducer, authInitialState, process.env.REACT_APP_AUTH_PERSIST_STORAGE_KEY);
    const { state: salesState, dispatch: setSalesState } = usePersistedReducer(salesReducer, salesInitialState, process.env.REACT_APP_SALES_PERSIST_STORAGE_KEY);
    const { state: menuState, dispatch: setMenuState } = usePersistedReducer(menuReducer, menuInitialState, process.env.REACT_APP_MENU_PERSIST_STORAGE_KEY);
    const [ showMenu, setShowMenu ] = useState(false);
    const [ showSidebar, setShowSidebar ] = useState(menuInitialState?.showSidebar ?? false);

    const providerState = useMemo(() => {
        const apiCaller = new ApiCaller({authState});

        return {
            authState,
            setAuthState,
            salesState,
            setSalesState,
            menuState,
            setMenuState,
            apiCaller,
        };
    }, [authState, setAuthState, salesState, setSalesState, menuState, setMenuState]);

    useEffect(() => {
        // console.log({authState});
        setShowMenu(authState && authState.username && authState.hasPassword);
    }, [authState, setAuthState]);

    const routes = useMemo(() => (
        Menu.filter((m) => authState[process.env.REACT_APP_BUILD_ID] && authState.menus?.length && authState.menus.filter(am => m.children.filter(c => c.code === am.kode_menu).length > 0).length > 0)
            .map((m, idx) => {
                return (
                    <Route key={`route_${idx}`} path={m.path} element={m.component} />
                );
            })
    ), [authState]);

    useEffect(() => {
        // console.log({menuState});
        setShowSidebar(menuState && menuState.showSidebar);
    }, [menuState]);

    return (
        <StateContext.Provider value={providerState}>
            <BrowserRouter>
                <div
                    style={
                        menuState?.showBackground ?
                        {
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "200px",
                            backgroundPosition: "center",
                            backgroundColor: "white",
                        }
                        :
                        (
                            authState?.hasPassword ?
                            {
                                backgroundColor: "white"
                            }
                            :
                            {
                                backgroundImage: `url(${backgroundLogin})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundColor: "white",
                            }
                        )
                    }
                    className="vh-100 vw-100 d-flex flex-column"
                >
                    <main className="flex-shrink-0 h-75">
                        <Sidebar show={showSidebar} />
                        <div
                            onClick={() => {
                                if (menuState && menuState.showSidebar)
                                    setMenuState({
                                        ...menuState,
                                        type: "toggle",
                                        showSidebar: false,
                                    });
                            }}
                        >
                            <MainNavbar />
                        {
                            !authState[process.env.REACT_APP_BUILD_ID] ?
                            <Alert variant="danger">Update aplikasi ditemukan. Mohon tekan <b>CTRL+F5</b>, <b>logout</b>, lalu <b>login ulang</b> agar aplikasi dapat digunakan kembali.</Alert>
                            :
                            null
                        }
                        {
                            showMenu ?
                            <DefaultBreadcrumb />
                            :
                            null
                        }
                            <Routes>
                                <Route path="/" element={authState?.token && authState.token !== "" ? <Dashboard /> : <Navigate to="/login" replace />} />
                                {
                                    routes
                                }
                                <Route path="/login" element={<Login />} />
                                <Route path="/change-password" element={<ChangePassword />} />
                                <Route path="/invoice/print" element={<InvoicePrint />} />
                                <Route path="/invoice/do/print" element={<DeliveryOrderReceiptCustomerPrint />} />
                                <Route path="/do/print" element={<DeliveryOrderPrint />} />
                                <Route path="/do/receipt/print" element={<DeliveryOrderReceiptPrint />} />
                            </Routes>
                        </div>
                    </main>
                    <footer
                        className="footer d-flex flex-wrap justify-content-center align-items-center mt-auto pb-3"
                        onClick={() => {
                            if (menuState && menuState.showSidebar)
                                setMenuState({
                                    ...menuState,
                                    type: "toggle",
                                    showSidebar: false,
                                });
                        }}
                    >
                        <span className={`${authState?.hasPassword ? "text-dark" : "text-white"} font-size-sm`}>Copyright © <a href="https://www.novitate.net/" className={`fw-bold${authState?.hasPassword ? "" : " text-white"}`} rel="noreferrer noopener" target="_blank">Novitate</a> {moment().format("YYYY")}. All Rights Reserved.</span>
                    </footer>
                </div>
            </BrowserRouter>
        </StateContext.Provider>
    );
}

export default App;
