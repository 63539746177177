import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import { ComboBox } from "../../../components/Dropdowns";
import TooltipButton from "../../../components/TooltipButton";

function BarangCustomerEditor(props) {
    const {
        itemName,
        initialCustomers,
        onHide,
        authState,
    } = props;

    const [ selectedCustomers, setSelectedCustomers ] = useState(initialCustomers);
    const [ selectedCustomer, setSelectedCustomer ] = useState(null);
    const [ currentEditedCustomer, setCurrentEditedCustomer ] = useState(null);
    const [ refs, setRefs ] = useState([]);
    const [ reload, setReload ] = useState(0);

    const onCustomerSelected = useCallback((val) => {
        setSelectedCustomer(val ? {...val} : null);
    }, []);

    const onSelectClick = useCallback(() => {
        if (!selectedCustomer || selectedCustomers?.filter(c => c.ucode_customer === selectedCustomer.ucode).length >= 1)
            return;

        setSelectedCustomers([
            ...selectedCustomers,
            {
                ucode_customer: selectedCustomer.ucode,
                kode_customer: selectedCustomer.kode,
                nama_customer: selectedCustomer.nama,
                edit_mode: true,
            },
        ]);
        setReload(reload === 1 ? 0 : 1);
    }, [selectedCustomers, selectedCustomer, reload]);

    useEffect(() => {
        if (!currentEditedCustomer)
            return;

        const kodeEditorRef = refs.filter(r => r.ucode_customer === currentEditedCustomer.ucode_customer)[0]?.kodeRef.current;
        const namaEditorRef = refs.filter(r => r.ucode_customer === currentEditedCustomer.ucode_customer)[0]?.namaRef.current;

        if (kodeEditorRef)
            kodeEditorRef.value = currentEditedCustomer.kode;

        if (namaEditorRef)
            namaEditorRef.value = currentEditedCustomer.nama;
    }, [currentEditedCustomer, refs]);

    useEffect(() => {
        if (!selectedCustomers?.length)
        {
            setRefs([]);
            return;
        }

        setRefs(selectedCustomers.map(c => ({
            ucode_customer: c.ucode_customer,
            kodeRef: createRef(),
            namaRef: createRef(),
        })));
    }, [selectedCustomers]);

    const columns = useMemo(() => [
        {
            Header: "Kode Customer",
            accessor: "kode_customer",
        },
        {
            Header: "Nama Customer",
            accessor: "nama_customer",
        },
        {
            Header: "Kode Barang",
            id: "kode",
            Cell: ({row: {original}}) => {
                const kodeRef = refs.filter(r => r.ucode_customer === original.ucode_customer)[0].kodeRef;

                if (original.edit_mode)
                    return (
                        <Form.Control
                            type="text"
                            id={`kode_${original.ucode_customer}`}
                            name={`kode_${original.ucode_customer}`}
                            autoComplete="off"
                            ref={kodeRef}
                        />
                    );
                
                return (
                    <Form.Control
                        type="text"
                        id={`kode_${original.ucode_customer}`}
                        name={`kode_${original.ucode_customer}`}
                        value={original.kode ?? ""}
                        autoComplete="off"
                        ref={kodeRef}
                        disabled={true}
                    />
                );
            },
        },
        {
            Header: "Nama Barang",
            id: "nama",
            Cell: ({row: {original}}) => {
                const namaRef = refs.filter(r => r.ucode_customer === original.ucode_customer)[0].namaRef;

                if (original.edit_mode)
                    return (
                        <Form.Control
                            type="text"
                            id={`kode_${original.ucode_customer}`}
                            name={`kode_${original.ucode_customer}`}
                            autoComplete="off"
                            ref={namaRef}
                        />
                    );
                
                return (
                    <Form.Control
                        type="text"
                        id={`nama_${original.ucode_customer}`}
                        name={`nama_${original.ucode_customer}`}
                        value={original.nama ?? ""}
                        autoComplete="off"
                        ref={namaRef}
                        disabled={true}
                    />
                );
            },
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        original.edit_mode ?
                        <TooltipButton
                            title="Update"
                            size="xs-custom"
                            variant="success"
                            className="me-1"
                            onClick={() => {
                                const currentCustomers = JSON.parse(JSON.stringify(selectedCustomers));
                                const currentCustomer = currentCustomers.filter(c => c.ucode_customer === original.ucode_customer)[0];
                                currentCustomer.edit_mode = false;
                                const ref = refs.filter(r => r.ucode_customer === original.ucode_customer)[0];
                                currentCustomer.kode = ref?.kodeRef.current?.value;
                                currentCustomer.nama = ref?.namaRef.current?.value;
                                setCurrentEditedCustomer(null);
                                setSelectedCustomers([...currentCustomers]);
                            }}
                        >
                            <i className="fas fa-save" />
                        </TooltipButton>
                        :
                        <TooltipButton
                            title="Ubah"
                            size="xs-custom"
                            variant="warning-custom"
                            className="me-1"
                            onClick={() => {
                                const currentCustomers = JSON.parse(JSON.stringify(selectedCustomers));
                                const currentCustomer = currentCustomers.filter(c => c.ucode_customer === original.ucode_customer)[0];
                                currentCustomer.edit_mode = true;
                                const ref = refs.filter(r => r.ucode_customer === original.ucode_customer)[0];
                                currentCustomer.kode = ref?.kodeRef.current?.value;
                                currentCustomer.nama = ref?.namaRef.current?.value;
                                setSelectedCustomers([...currentCustomers]);
                                setCurrentEditedCustomer(currentCustomer);
                            }}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    }
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="danger"
                            onClick={() => {
                                const filtered = selectedCustomers.filter(c => c.ucode_customer !== original.ucode_customer);
                                setSelectedCustomers(filtered);
                                setReload(reload === 1 ? 0 : 1);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </InputGroup>
                )
            },
            maxWidth: 80,
            width: 80,
        },
    ], [selectedCustomers, reload, refs]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={selectedCustomers}
                reload={reload}
                maxRowCount={selectedCustomers.length}
                showPager={false}
            />
        );
    }, [columns, selectedCustomers, reload]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="3" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide(selectedCustomers)}
                        disabled={selectedCustomers.filter(c => !c.kode || c.kode === "" || c.edit_mode).length}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onHide, selectedCustomers]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide([], true)}
            title={itemName ?? "Barang"}
            titleDetails="Update Data Customer"
            dialogClassName="modal-lg-width"
            footer={footer}
        >
            <Container>
                <Row className="justify-content-md-start mb-3">
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Customer: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="customers/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onCustomerSelected(val)}
                            selectedItem={selectedCustomer}
                            authState={authState}
                            minLength={0}
                            additionalButtons={[
                                (
                                    <InputGroup.Text onClick={() => onSelectClick()} className={!selectedCustomer || selectedCustomers?.filter(c => c.ucode_customer === selectedCustomer.ucode).length >= 1 ? null : "cursor-pointer bg-primary text-white"}>
                                        <i className="fas fa-arrow-down"></i>
                                    </InputGroup.Text>
                                )
                            ]}
                            additionalParam={{
                                customItemName: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                {
                    grid
                }
                </Row>
            </Container>
        </CustomModal>
    );
}

export default BarangCustomerEditor;