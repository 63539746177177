import React, { useEffect, useState } from 'react';
import Fetch from "./fetch";
import Downshift from "downshift";
import {
    Form, Button, InputGroup
} from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";

function ComboBox(props) {

    const {
        prefix,
        suffix,
        refreshInputValue,
        setRefreshInputValue,
        endpoint,
        authState,
        additionalParam,
        onInputValueChange,
        onChange,
        selectedItem,
        className,
        idField,
        valueField,
        isLookup,
        sortByName,
        placeholder: initialPlaceholder,
        disabled,
        minLength,
        size,
        additionalButtons,
        filterName,
    } = props;

    const accessToken = authState?.token;

    // console.log({authState: props.authState, accessToken, endpoint, selectedItem, additionalParam});

    const [currentInputValue, setCurrentInputValue] = useState("");
    const baseEndpoint = `${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${isLookup ? "/lookup" : ""}`;
    const placeholder = initialPlaceholder ? initialPlaceholder : '-- cari data --';

    useEffect(() => {
        if (!refreshInputValue)
            return;

        setCurrentInputValue("");
        setRefreshInputValue(false);
    }, [refreshInputValue]);

    return (
        <div className={`downshift d-flex flex-row justify-content-start align-items-center w-100 p-0${className ? ` ${className}` : ""}`}>
            <Downshift
                //itemToString={(item) => item ? item.value : ""}
                itemToString={(item) => item ? item[valueField] : currentInputValue}
                initialSelectedItem={selectedItem}
                selectedItem={selectedItem ?? {}}
                onChange={onChange}
                selectedItemChanged={(prevItem, item) => {
                    // console.log("selectedItemChanged", { prevItem, item })
                    return (prevItem !== item);
                }}
                onSelect={(selectedItem, stateAndHelpers) => {
                    // console.log("onSelect", { selectedItem, stateAndHelpers })
                }}
                onStateChange={(changes, stateAndHelpers) => {
                    // console.log("onStateChange", { changes, stateAndHelpers })
                }}
                onInputValueChange={(inputValue, stateAndHelpers) => {
                    // console.log("onInputValueChange", { inputValue, stateAndHelpers })
                    if (onInputValueChange) {
                        setCurrentInputValue(inputValue);
                        onInputValueChange(inputValue);
                    }
                }}
            >
                {({
                    inputValue,
                    getInputProps,
                    getMenuProps,
                    getItemProps,
                    getToggleButtonProps,
                    selectedItem,
                    highlightedIndex,
                    isOpen,
                    clearSelection,
                }) => {
                    //isOpen = true;
                    const menuClasses = classNames({
                        'is-open': isOpen,
                    })
                    const fgClasses = classNames({
                        'focused': isOpen,
                        'mb-0': true,
                    })
                    const toggleBtnProps = getToggleButtonProps();

                    return (
                        <div>
                            <div>
                                <Form.Group className={fgClasses}>
                                    <InputGroup className={menuClasses} size={size}>
                                        {prefix.map(m => <div className="input-group-append">{m}</div>)}
                                        <Form.Control
                                            {...getInputProps({
                                                placeholder: placeholder,
                                                onClick: toggleBtnProps.onClick,
                                                onBlur: (e) => {
                                                    // console.log("on blur: ", {e});
                                                    toggleBtnProps.onBlur(e);

                                                    if (onInputValueChange) {
                                                        onChange();
                                                    }
                                                },
                                                disabled: disabled,
                                                value: inputValue ?? currentInputValue,
                                            })}
                                            size={size}
                                        />
                                    {
                                        selectedItem ?
                                        <InputGroup.Text size={size} className="cursor-pointer" onClick={() => {
                                            if (disabled)
                                                return;
                                            
                                            clearSelection();
                                        }}>
                                            <i className="fas fa-times"></i>
                                        </InputGroup.Text>
                                        :
                                        <InputGroup.Text size={size} className="cursor-pointer" {...toggleBtnProps}>
                                            <i className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                        </InputGroup.Text>
                                    }
                                    {
                                        additionalButtons.map(b => b)
                                    }
                                        {suffix.map(m => <div className="input-group-append">{m}</div>)}
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <ul {...getMenuProps()} className={menuClasses}>

                                {(() => {
                                    if (!isOpen) {
                                        return null
                                    }

                                    if (minLength > 0 && (!inputValue || inputValue.length < minLength)) {
                                        return (
                                            <li key={-1} disabled>Harap masukkan {minLength} karakter untuk memulai pencarian</li>
                                        )
                                    }

                                    const defaultFilterName = "filter";

                                    let param = {
                                        [filterName ?? defaultFilterName]: inputValue ?? ""
                                    };

                                    if (additionalParam)
                                        param = {
                                            ...additionalParam,
                                            [filterName ?? defaultFilterName]: inputValue ?? ""
                                        }

                                    return (
                                        <Fetch
                                            url={baseEndpoint + endpoint}
                                            params={param}
                                            headers={{
                                                "Content-Type": "application/json",
                                                "Authorization": `Bearer ${accessToken}`
                                            }}
                                            minLength={minLength}
                                            authState={authState}
                                            filterName={filterName}
                                        >
                                            {({ loading, error, data: items }) => {
                                                if (loading) {
                                                    return <li key={-1} disabled className="font-sm-custom">Loading...</li>
                                                }

                                                if (error) {
                                                    return <li key={-1} disabled className="font-sm-custom">Error! ${error}</li>
                                                }

                                                if (!items || !Array.isArray(items) || !items.length) {
                                                    return <li key={-1} disabled className="font-sm-custom">Data tidak ditemukan</li>
                                                }

                                                if (isLookup && sortByName) items.sort((a, b) => a.lookupValue.localeCompare(b.lookupValue));
                                                
                                                return items.map((item, index) => {
                                                    //const item = { id, value };
                                                    const itemClasses = classNames({
                                                        'is-active': highlightedIndex === index,
                                                        'is-selected': selectedItem === item,
                                                        "font-sm-custom": true,
                                                    })

                                                    return (
                                                        <li
                                                            //key={id}
                                                            key={item[idField]}
                                                            {...getItemProps({
                                                                item,
                                                                index,
                                                                // isActive: highlightedIndex === index,
                                                                // isSelected: selectedItem === item,
                                                            })}
                                                            className={itemClasses}
                                                        >
                                                            {/* {value} */}
                                                            {item[valueField]}
                                                        </li>
                                                    );
                                                })
                                            }}
                                        </Fetch>
                                    )
                                })()}
                            </ul>
                        </div>
                    )
                }}
            </Downshift>
        </div>
    );
}

ComboBox.propTypes = {
    selectedItem: PropTypes.object,
    valueField: PropTypes.string,
    idField: PropTypes.string,
    onChange: PropTypes.func,
    endpoint: PropTypes.string.isRequired,
    accessToken: PropTypes.string,
    minLength: PropTypes.number,
    isLookup: PropTypes.bool,
    sortByName: PropTypes.bool,
    refreshInputValue: PropTypes.bool,
    setRefreshInputValue: PropTypes.func,
    prefix: PropTypes.array,
    suffix: PropTypes.array,
    additionalParam: PropTypes.object,
    onInputValueChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    additionalButtons: PropTypes.array,
    filterName: PropTypes.string,
}

ComboBox.defaultProps = {
    minLength: 3,
    idField: "lookupId",
    valueField: "lookupValue",
    onChange: () => { },
    isLookup: true,
    sortByName: false,
    refreshInputValue: false,
    setRefreshInputValue: () => { },
    prefix: [],
    suffix: [],
    disabled: false,
    additionalButtons: [],
    filterName: null,
}

function DropdownBox(props) {

    let placeholder = 'placeholder' in props && props.placeholder ? props.placeholder : '-- Select --';

    return (
        <div className="downshift">
            <Downshift
                itemToString={(item) => item ? item[props.valueField] : ""}
                selectedItem={props.selectedItem ?? {}}
                onChange={props.onChange}
            >
                {({
                    inputValue,
                    getInputProps,
                    getMenuProps,
                    getItemProps,
                    getToggleButtonProps,
                    selectedItem,
                    highlightedIndex,
                    isOpen,
                    clearSelection,
                }) => {
                    const menuClasses = classNames({
                        'is-open': isOpen
                    })
                    const fgClasses = classNames({
                        'focused': isOpen,
                        'mb-0': true
                    })
                    const toggleBtnProps = getToggleButtonProps({ disabled: !!props.disabled });

                    return (
                        <div>
                            <div>
                                <Form.Group className={fgClasses}>
                                    <InputGroup className={menuClasses}>
                                        <Form.Control className="cursor-pointer"

                                            {...getInputProps({
                                                placeholder: placeholder,
                                                onClick: toggleBtnProps.onClick,
                                                onBlur: toggleBtnProps.onBlur,
                                                readOnly: !!props.disabled ? false : true,
                                                disabled: !!props.disabled,
                                                value: inputValue ?? ""
                                            })}
                                        >
                                        </Form.Control>
                                        {selectedItem && Object.keys(selectedItem).length > 0 && !props.disableClear ? (
                                            <InputGroup.Addon addonType="append" onClick={clearSelection}>
                                                <InputGroup.Text>
                                                    <i className="fas fa-times "></i>
                                                </InputGroup.Text>
                                            </InputGroup.Addon>
                                        ) : (
                                            <InputGroup.Addon addonType="append" {...toggleBtnProps}>
                                                <InputGroup.Text>
                                                    <i className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                                </InputGroup.Text>
                                            </InputGroup.Addon>
                                        )}
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <ul {...getMenuProps()} className={menuClasses} style={{ zIndex: "99999" }}>
                                {isOpen && props.data && props.data.length > 0 &&
                                    props.data.map((item, index) => {
                                        const itemClasses = classNames({
                                            'is-active': highlightedIndex === index,
                                            'is-selected': selectedItem === item
                                        })
                                        // console.log(item.html)
                                        const listItem = props.html ? (
                                            <li
                                                key={item[props.idField]}
                                                {...getItemProps({
                                                    item,
                                                    index
                                                })}
                                                className={itemClasses}
                                                dangerouslySetInnerHTML={{ __html: item.html }}
                                            ></li>
                                        ) : (
                                            <li
                                                key={item[props.idField]}
                                                {...getItemProps({
                                                    item,
                                                    index
                                                })}
                                                className={itemClasses}
                                            >
                                                {item[props.valueField]}
                                            </li>
                                        )

                                        return listItem;
                                    })
                                }
                                {isOpen && (!props.data || props.data.length === 0) &&
                                    <li
                                        {...getItemProps({ item: {} })}
                                        disabled
                                    >
                                        No items found
                                    </li>
                                }
                            </ul>
                        </div>
                    )
                }}
            </Downshift>
        </div>
    )
}

DropdownBox.propTypes = {
    selectedItem: PropTypes.object,
    valueField: PropTypes.string,
    idField: PropTypes.string,
    onChange: PropTypes.func,
    data: PropTypes.array.isRequired,
    disableClear: PropTypes.bool,
    html: PropTypes.bool,
}

DropdownBox.defaultProps = {
    idField: "lookupId",
    valueField: "lookupValue",
    onChange: () => { },
    disableClear: false,
    html: false,
}

// function MultiSelect(props, items) {
//     const [inputValue, setInputValue] = useState('')

//     const {
//         getSelectedItemProps,
//         getDropdownProps,
//         addSelectedItem,
//         removeSelectedItem,
//         selectedItems,
//     } = useMultipleSelection({ initialSelectedItems: [items[0], items[1]] })

//     const getFilteredItems = (items) => {
//         return items.filter((item) => {
//             return selectedItems.indexOf(item) < 0 && item.toLowerCase().startsWith(inputValue.toLowerCase())
//         })
//     }

//     const {
//         isOpen,
//         getToggleButtonProps,
//         getLabelProps,
//         getMenuProps,
//         getInputProps,
//         getComboboxProps,
//         highlightedIndex,
//         getItemProps,
//         selectItem,
//     } = useCombobox({
//         inputValue,
//         items: getFilteredItems(items),
//         onStateChange: ({inputValue, type, selectedItem}) => {
//             switch (type) {
//                 case useCombobox.stateChangeTypes.InputChange:
//                 setInputValue(inputValue)
//                 break
//                 case useCombobox.stateChangeTypes.InputKeyDownEnter:
//                 case useCombobox.stateChangeTypes.ItemClick:
//                 case useCombobox.stateChangeTypes.InputBlur:
//                 if (selectedItem) {
//                     setInputValue('')
//                     addSelectedItem(selectedItem)
//                     selectItem(null)
//                 }

//                 break
//                 default:
//                 break
//             }
//         },
//     })
// }

// MultiSelect.propTypes = {
//     selectedItems: PropTypes.arrayOf(PropTypes.object),
//     valueField: PropTypes.string,
//     idField: PropTypes.string,
//     onChange: PropTypes.func,
//     data: PropTypes.array.isRequired,
//     disableClear: PropTypes.bool,
// }

// MultiSelect.defaultProps = {
//     idField: "lookupId",
//     valueField: "lookupValue",
//     onChange: () => {}, 
//     disableClear: false,
// }

class MultiDownshift extends React.Component {
    state = { selectedItems: [] }

    stateReducer = (state, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                }
            default:
                return changes
        }
    }

    handleSelection = (selectedItem, downshift) => {
        const callOnChange = () => {
            const { onSelect, onChange } = this.props
            const { selectedItems } = this.state
            if (onSelect) {
                onSelect(selectedItems, this.getStateAndHelpers(downshift))
            }
            if (onChange) {
                onChange(selectedItems, this.getStateAndHelpers(downshift))
            }
        }
        if (this.state.selectedItems.includes(selectedItem)) {
            this.removeItem(selectedItem, callOnChange)
        } else {
            this.addSelectedItem(selectedItem, callOnChange)
        }
    }

    removeItem = (item, cb) => {
        this.setState(({ selectedItems }) => {
            return {
                selectedItems: selectedItems.filter((i) => i !== item),
            }
        }, cb)
    }
    addSelectedItem(item, cb) {
        this.setState(
            ({ selectedItems }) => ({
                selectedItems: [...selectedItems, item],
            }),
            cb,
        )
    }

    getRemoveButtonProps = ({ onClick, item, ...props } = {}) => {
        return {
            onClick: (e) => {
                // TODO: use something like downshift's composeEventHandlers utility instead
                onClick && onClick(e)
                e.stopPropagation()
                this.removeItem(item)
            },
            ...props,
        }
    }

    getStateAndHelpers(downshift) {
        const { selectedItems } = this.state
        const { getRemoveButtonProps, removeItem } = this
        return {
            getRemoveButtonProps,
            removeItem,
            selectedItems,
            ...downshift,
        }
    }
    render() {
        const { render, children = render, ...props } = this.props
        // TODO: compose together props (rather than overwriting them) like downshift does
        return (
            <Downshift
                {...props}
                stateReducer={this.stateReducer}
                onChange={this.handleSelection}
                selectedItem={null}
            >
                {(downshift) => children(this.getStateAndHelpers(downshift))}
            </Downshift>
        )
    }
}

class MultiSelect extends React.Component {
    constructor(props) {
        super();


    }
    input = React.createRef()
    itemToString = (item) => (item ? item.name : '')
    handleChange = (selectedItems) => {
        this.props.handleSelected(selectedItems);
    }

    render() {
        return (
            <div className="downshift"
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   marginTop: 50,
            // }}
            >
                <MultiDownshift
                    onChange={this.handleChange}
                    itemToString={this.itemToString}
                    className="downshift"
                >
                    {({
                        getInputProps,
                        getToggleButtonProps,
                        getMenuProps,
                        // note that the getRemoveButtonProps prop getter and the removeItem
                        // action are coming from MultiDownshift composibility for the win!
                        getRemoveButtonProps,
                        removeItem,

                        isOpen,
                        inputValue,
                        selectedItems,
                        getItemProps,
                        highlightedIndex,
                        toggleMenu,
                    }) => (
                        <div
                            // style={{width: 500, margin: 'auto', position: 'relative'}}
                            className="downshift"
                        >
                            <div
                                // style={{
                                //   cursor: 'pointer',
                                //   position: 'relative',
                                //   borderRadius: '12px',
                                //   borderTopRadius: 6,
                                //   borderBottomRightRadius: isOpen ? 0 : 6,
                                //   borderBottomLeftRadius: isOpen ? 0 : 6,
                                //   padding: 10,
                                //   paddingRight: 50,
                                //   boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
                                //   borderColor: '#000',
                                //   borderTopWidth: '1',
                                //   borderRightWidth: 1,
                                //   borderBottomWidth: 1,
                                //   borderLeftWidth: 1,
                                //   borderStyle: 'solid',
                                // }}
                                style={{
                                    borderBottomRightRadius: isOpen ? 0 : "0 0 .28571429rem .28571429rem",
                                    borderBottomLeftRadius: isOpen ? 0 : "0 0 .28571429rem .28571429rem"
                                }}

                                onClick={() => {
                                    toggleMenu()
                                    !isOpen && this.input.current.focus()
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'right',
                                    }}
                                >
                                    {selectedItems.length > 0
                                        ? selectedItems.map((item) => (
                                            <div
                                                key={item.lookupId}
                                                style={{
                                                    margin: 4,
                                                    paddingTop: 6,
                                                    paddingBottom: 2,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    display: 'inline-block',
                                                    wordWrap: 'none',
                                                    backgroundColor: '#d1d4d6',
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'grid',
                                                        gridGap: 6,
                                                        gridAutoFlow: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <span title={item.lookupValue}
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            width: "80px"
                                                        }}
                                                    >{item.lookupValue}</span>
                                                    <Button
                                                        {...getRemoveButtonProps({ item })}
                                                        style={{
                                                            cursor: 'pointer',
                                                            lineHeight: 0.8,
                                                            border: 'none',
                                                            backgroundColor: 'transparent',
                                                            padding: '0',
                                                            fontSize: '16px',
                                                        }}
                                                        size="sm-custom"
                                                    >
                                                        𝘅
                                                    </Button>
                                                </div>
                                            </div>
                                        ))
                                        : ""
                                    }

                                    <InputGroup className={isOpen ? "is-open" : ""}>
                                        <Form.Control

                                            {...getInputProps({
                                                placeholder: "-- Select --",
                                                ref: this.input,
                                                onKeyDown(event) {
                                                    if (event.key === 'Backspace' && !inputValue) {
                                                        removeItem(selectedItems[selectedItems.length - 1])
                                                    }
                                                },
                                                // style:{
                                                //   border: 'none',
                                                //   marginLeft: 6,
                                                //   flex: 1,
                                                //   fontSize: 14,
                                                //   minHeight: 27,
                                                // },
                                            })}
                                        ></Form.Control>
                                        <Button
                                            {...getToggleButtonProps({
                                                // prevents the menu from immediately toggling
                                                // closed (due to our custom click handler above).
                                                onClick(event) {
                                                    event.stopPropagation()
                                                },
                                            })}
                                            size="sm-custom"
                                        >
                                            <InputGroup.Text>
                                                <i className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                            </InputGroup.Text>
                                        </Button>
                                    </InputGroup>


                                    {/* <Button
                    {...getToggleButtonProps({
                      // prevents the menu from immediately toggling
                      // closed (due to our custom click handler above).
                      onClick(event) {
                        event.stopPropagation()
                      },
                    })}
                    >
                    <ArrowIcon isOpen={isOpen} /> 
                    <i className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </Button>*/}
                                </div>
                                {/* <Button
                    {...getToggleButtonProps({
                      // prevents the menu from immediately toggling
                      // closed (due to our custom click handler above).
                      onClick(event) {
                        event.stopPropagation()
                      },
                    })}
                  >
                    <ArrowIcon isOpen={isOpen} />
                  </Button> */}
                            </div>

                            <ul {...getMenuProps({ isOpen })} className={isOpen ? "is-open" : ""}>
                                {isOpen
                                    ? this.props.items.map((item, index) => (
                                        <li
                                            key={item.lookupId}
                                            {...getItemProps({
                                                item,
                                                index,
                                                //isActive: highlightedIndex === index,
                                                //isSelected: selectedItems.includes(item),
                                            })}
                                            className={({
                                                'is-active': highlightedIndex === index,
                                                'is-selected': selectedItems === item
                                            })}
                                        >
                                            {item.lookupValue}
                                        </li>
                                    ))
                                    : null}
                            </ul>
                        </div>
                    )}
                </MultiDownshift>
            </div>
        )
    }
}

export {
    ComboBox,
    DropdownBox,
    MultiSelect,
}