import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

function ErrorAlert(props) {
    const {
        onHide,
        bodyText,
        confirmButtonText,
    } = props;

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            className="font-sm-custom"
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">Error!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="m-0 p-0">
                    <Col lg="12" className="m-0 p-0">
                        {bodyText}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="danger" onClick={onHide} size="sm-custom">
                {confirmButtonText}
            </Button>
            </Modal.Footer>
        </Modal>
    );
};

ErrorAlert.propTypes = {
    onHide: PropTypes.func.isRequired,
    bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    confirmButtonText: PropTypes.string.isRequired,
};

ErrorAlert.defaultProps = {
    confirmButtonText: "Ok",
};

export default ErrorAlert;