import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Row, Spinner } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import moment from "moment";
import SalesOrderEditor from './SalesOrderEditor';
import TooltipButton from '../../../components/TooltipButton';
import ModalAlert from '../../../components/ModalAlert';
import { SHOWN_ERROR_CODE, SO_STATUS_CANCELED, SO_STATUS_CONFIRMED, SO_STATUS_PROCESS } from '../../../utils/Common';
import ErrorAlert from '../../../components/ErrorAlert';
import { useStateContext } from '../../../reducers';
import { useSalesContext } from './Context';
import { handleChangeSOStatus } from '../../../utils/api/sales';
import SalesOrderDeliveryOrderPrintDialog from './SalesOrderDeliveryOrderPrintDialog';

function SalesOrder(props) {
    const {accessLevel} = props;
    const [itemInEdit, setItemInEdit] = useState(null);
    const [selectedDataForDO, setSelectedDataForDO] = useState(null);
    const [reload, setReload] = useState(0);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [errorObj, setErrorObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [exportToExcel, setExportToExcel] = useState(false);
    const { apiCaller, authState } = useStateContext();
    const { loadNotifCount } = useSalesContext();

    const handlePrint = useCallback((ucode) => {
        window.open(`invoice/print?id=${ucode}`, "Invoice", "width=800,height=600");
    }, []);

    const loadStatusList = useCallback(() => {
        setLoading(true);
        // console.log({confirmationObj});
        apiCaller.get("statuses/search/type?type=SO")
            .then((response) => {
                // console.log({response});
                setStatusList(response.data.data);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller]);

    useEffect(() => {
        loadStatusList();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setReload(reload === -1 ? -2 : -1);
        }, process.env.REACT_APP_RELOAD_INTERVAL);
      
        return () => clearInterval(interval);
    }, [reload]);

    const handleGenerateDO = useCallback((ucode) => {
        setLoading(true);
        // console.log({confirmationObj});
        apiCaller.post(`salesorders/generatedo?id=${ucode}`)
            .then((response) => {
                // console.log({response});
                setConfirmationObj(null);
                setLoading(false);
                setReload(reload ? 0 : 1);
            })
            .catch((error) => {
                console.error({error});
                setErrorObj({
                    message: "Proses pembuatan Surat Jalan gagal!",
                });
                setLoading(false);
            })
        ;
    }, [apiCaller, reload]);

    const columns = useMemo(() => {
        return [
            // {
            //     Header: "",
            //     accessor: "check",
            //     Cell: ({row: {original}}) => {
            //         return (
            //             <Form.Check />
            //         );
            //     },
            //     width: 50,
            //     disableFilters: true,
            //     centered: true,
            // },
            {
                Header: "No. Sales Order",
                accessor: "no_transaksi",
                id: "no_transaksi",
            },
            {
                Header: "No. Purchase Order",
                accessor: "no_purchase_order",
                id: "no_purchase_order",
            },
            {
                Header: "Tanggal",
                id: "tanggal_transaksi",
                type: "datetimeoffset",
                accessor: (row) => moment.utc(row.tanggal_transaksi).local().format("DD-MM-YYYY HH:mm"),
                disableFilters: true,
                sortType: ({values: {tanggal_transaksi: a}}, {values: {tanggal_transaksi: b}}) => {
                    const dateA = moment(a, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    const dateB = moment(b, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    // console.log({a, dateA, b, dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "Nama Customer",
                accessor: "nama_customer",
                id: "nama_customer",
            },
            {
                Header: "Telepon",
                accessor: "telp",
                id: "telp",
            },
            {
                Header: "Status",
                accessor: "nama_status",
                id: "nama_status",
            },
            {
                Header: "Jatuh Tempo",
                id: "tanggal_jatuh_tempo",
                type: "date",
                accessor: (row) => row.tanggal_jatuh_tempo ? moment(row.tanggal_jatuh_tempo).format("DD-MM-YYYY") : "-",
                disableFilters: true,
                sortType: ({values: {tanggal_jatuh_tempo: a}}, {values: {tanggal_jatuh_tempo: b}}) => {
                    const dateA = a !== "-" ? moment(a, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    const dateB = b !== "-" ? moment(b, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    // console.log({a, dateA, b, dateB, check: dateA < dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "DO Tersedia",
                accessor: "has_delivery_order",
                id: "has_delivery_order",
                type: "boolean",
                Cell: ({row: {original}}) => {
                    return (
                        <>
                        {
                            original.has_delivery_order ?
                            <i className="fas fa-check-circle text-success" />
                            :
                            <i className="fas fa-times-circle text-danger" />
                        }
                        </>
                    );
                },
                disableFilters: true,
                centered: true,
                width: 100,
            },
            {
                Header: "Request dari Customer",
                accessor: "dari_customer",
                id: "dari_customer",
                type: "boolean",
                Cell: ({row: {original}}) => {
                    return (
                        <>
                        {
                            original.dari_customer > 0 ?
                            <i className="fas fa-check-circle text-success" />
                            :
                            <i className="fas fa-times-circle text-danger" />
                        }
                        </>
                    );
                },
                disableFilters: true,
                centered: true,
                width: 100,
            },
            {
                Header: "",
                id: "action",
                Cell: ({row: {original}}) => {
                    if (loading)
                        return (
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                                <Spinner animation="border" role="status" />
                            </div>
                        )

                    return (
                        <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                            <TooltipButton
                                title={original.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || original.has_delivery_order) ? "Lihat" : "Edit"}
                                size="xs-custom"
                                variant="success"
                                className="me-1"
                                onClick={() => {
                                    setItemInEdit(original);
                                }}
                            >
                                <i className={`fas fa-${original.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || original.has_delivery_order) ? "search" : "edit"}`} />
                            </TooltipButton>
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Approve"
                                size="xs-custom"
                                variant="primary"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pengubahan Status",
                                        message: <span>Status Sales Order akan diubah ke <b className="text-success">{statusList ? statusList.filter(s => s.kode === SO_STATUS_CONFIRMED)[0].nama : ""}</b> dan Sales Order tidak akan bisa diubah. Lanjutkan?</span>,
                                        onConfirmed: () => {
                                            setConfirmationObj(null);
                                            setLoading(true);
                                            handleChangeSOStatus(
                                                original.ucode,
                                                SO_STATUS_CONFIRMED,
                                                apiCaller,
                                                () => {
                                                    setLoading(false);
                                                    setReload(reload ? 0 : 1);
                                                },
                                                (error) => {
                                                    setErrorObj({
                                                        message: `Proses perubahan status Sales Order gagal!${error.response.status === SHOWN_ERROR_CODE ? ` ${error.response.data.data.message}` : ""}`,
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        },
                                    })
                                }}
                                disabled={original.kode_status !== SO_STATUS_PROCESS || (!original?.dari_customer && (!original?.no_purchase_order))}
                            >
                                <i className="fas fa-check" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Batal"
                                size="xs-custom"
                                variant="danger"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pengubahan Status",
                                        message: <span>Status Sales Order akan diubah ke <b className="text-danger">{statusList ? statusList.filter(s => s.kode === SO_STATUS_CANCELED)[0].nama : ""}</b> dan Sales Order tidak akan bisa diubah. Lanjutkan?</span>,
                                        onConfirmed: () => {
                                            setConfirmationObj(null);
                                            setLoading(true);
                                            handleChangeSOStatus(
                                                original.ucode,
                                                SO_STATUS_CANCELED,
                                                apiCaller,
                                                () => {
                                                    setLoading(false);
                                                    setReload(reload ? 0 : 1);
                                                },
                                                (error) => {
                                                    setErrorObj({
                                                        message: `Proses perubahan status Sales Order gagal!${error.response.status === SHOWN_ERROR_CODE ? ` ${error.response.data.data.message}` : ""}`,
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        },
                                    })
                                }}
                                disabled={original.kode_status !== SO_STATUS_PROCESS}
                            >
                                <i className="fas fa-times" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Generate SJ"
                                size="xs-custom"
                                variant="danger"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pembuatan Surat Jalan",
                                        message: <span>Surat Jalan akan dibuat secara otomatis. Lanjutkan?</span>,
                                        onConfirmed: () => handleGenerateDO(original.ucode),
                                    })
                                }}
                                disabled={original.kode_status !== SO_STATUS_CONFIRMED || original.jumlah_sj > 0 || !original.dari_customer}
                            >
                                <i className="fas fa-angle-double-right" />
                            </TooltipButton>
                        }
                            <TooltipButton
                                title="Print"
                                size="xs-custom"
                                variant="dark"
                                className="me-1"
                                onClick={() => {
                                    handlePrint(original.ucode);
                                }}
                                disabled={original.kode_status === SO_STATUS_CANCELED}
                            >
                                <i className="fas fa-print" />
                            </TooltipButton>
                            <TooltipButton
                                title="Generate Delivery Order"
                                size="xs-custom"
                                variant="dark"
                                onClick={() => {
                                    setSelectedDataForDO(original.ucode);
                                }}
                                disabled={original.kode_status === SO_STATUS_CANCELED || !original.has_complete_sj || original.dari_customer}
                            >
                                <i className="fas fa-clipboard-list" />
                            </TooltipButton>
                        </InputGroup>
                    )
                },
                width: 250,
                sticky: "right",
            },
        ]
    }, [apiCaller, reload, handlePrint, handleGenerateDO, statusList, loading, accessLevel, authState]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="salesorders"
                reload={reload}
                rowColorFunction={(data) => {
                    // console.log({data});
                    if (data.kode_status === SO_STATUS_PROCESS)
                        return null;
                    
                    return {
                        backgroundColor: data.jumlah_sj > 0 ? "#e3fcde" : (data.kode_status === SO_STATUS_CANCELED ? "#ffcbc7" : "#fcfade"),
                    };
                }}
                reloadCallback={() => loadNotifCount()}
                initialSortBy={[
                    {
                        id: "tanggal_transaksi",
                        desc: "desc",
                    }
                ]}
                excelFileNameTemplate="sales_order"
                exportToExcel={exportToExcel}
                setExportToExcel={setExportToExcel}
            />
        );
    }, [reload, columns, loadNotifCount, exportToExcel]);

    return (
        <>
            <Container fluid>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                        {
                            accessLevel.can_add > 0 ?
                            <Button size="xs-custom" variant="dark" className="me-1" onClick={() => setItemInEdit({})} disabled={loading}><i className="fas fa-plus me-1" />Tambah Data</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => setExportToExcel(true)}><i className="fas fa-file-excel me-1" />Export Excel</Button>
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            {
                itemInEdit ?
                <SalesOrderEditor
                    onHide={() => {
                        setItemInEdit(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                    data={itemInEdit}
                    onSave={() => {
                        // console.log("on save");
                        setItemInEdit(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                    statusList={statusList}
                />
                :
                null
            }
            {
                confirmationObj ?
                <ModalAlert
                    onHide={() => setConfirmationObj(null)}
                    onSave={() => confirmationObj.onConfirmed()}
                    title={confirmationObj.title}
                    bodyText={confirmationObj.message}
                />
                :
                null
            }
            {
                errorObj ?
                <ErrorAlert
                    onHide={() => setErrorObj(null)}
                    bodyText={errorObj.message}
                />
                :
                null
            }
            {
                selectedDataForDO ?
                <SalesOrderDeliveryOrderPrintDialog
                    id={selectedDataForDO}
                    onClose={() => setSelectedDataForDO(null)}
                />
                :
                null
            }
            </Container>
        </>
    );
}

export default SalesOrder;
