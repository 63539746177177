import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import Dropzone from '../../../components/Dropzone';
import { JPEG_FILE_EXTENSION, JPEG_FILE_TYPE_DROPZONE, JPG_FILE_EXTENSION, JPG_FILE_TYPE_DROPZONE, objectWithoutProperties, PNG_FILE_EXTENSION, PNG_FILE_TYPE_DROPZONE, separateBase64String } from "../../../utils/Common";
import { ComboBox } from "../../../components/Dropdowns";

function DivisiEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, parentObj: data.parent ? {ucode: data.parant, nama: data.nama_parent, kode: data.kode_parent} : null});
    const [loading, setLoading] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller, authState } = useStateContext();

    useEffect(() => {
        setLoading(loadingLogo);
    }, [loadingLogo]);

    useEffect(() => {
        if (!data)
            return;

        loadLogo();
    }, [data]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    const loadLogo = useCallback(() => {
        if (!data?.ucode)
            return;

        setLoadingLogo(true);

        apiCaller.get(`divisions/search/logo?id=${data?.ucode}`)
            .then((response) => {
                setItemInEdit({
                    ...itemInEdit,
                    logo: response.data.data.logo,
                    tipe_file_logo: response.data.data.tipe_file_logo,
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoadingLogo(false))
        ;
    }, [data, apiCaller, itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama);
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "kode_parent",
            "nama_parent",
            "parentObj",
        ])};
        // console.log({dataToSend});
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`divisions`, JSON.stringify(dataToSend)) : apiCaller.put(`divisions/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave]);

    const onParentSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            parentObj: val,
            parent: val?.ucode,
            nama_parent: val?.nama,
            kode_parent: val?.kode,
        });
    }, [itemInEdit]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="2" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="2" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    const handleImageSelected = useCallback((base64Str, fileStrKey, fileTypeKey) => {
        const separationResult = separateBase64String(base64Str);
        setItemInEdit({...itemInEdit, [fileStrKey]: separationResult.base64Str, [fileTypeKey]: separationResult.mimeType});
    }, [itemInEdit]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Divisi"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-70"
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container className="font-sm-custom">
                <Row className="justify-content-md-center mb-3">
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Kode:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                            name="kode"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Nama: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                            name="nama"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Induk:
                        </Form.Label>
                        <ComboBox
                            endpoint="divisions/search/name/exclude/self/children"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onParentSelected(val)}
                            selectedItem={itemInEdit?.parentObj}
                            authState={authState}
                            minLength={0}
                            additionalParam={{
                                id: itemInEdit?.ucode
                            }}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Alamat:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.alamat ? itemInEdit.alamat : ""}
                            name="alamat"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Kota:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.kota ? itemInEdit.kota : ""}
                            name="kota"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            No. Telepon:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.tlp ? itemInEdit.tlp : ""}
                            name="tlp"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            NPWP:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.npwp ? itemInEdit.npwp : ""}
                            name="npwp"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            No. Rekening:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.rek_number ? itemInEdit.rek_number : ""}
                            name="rek_number"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Nama Pemilik Rekening:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.rek_name ? itemInEdit.rek_name : ""}
                            name="rek_name"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Form.Label>
                                Logo:
                            </Form.Label>
                        </Row>
                        <Row className="m-0 p-0">
                            <Col lg={itemInEdit?.logo ? 6 : 12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                <Dropzone
                                    dropText={(
                                        <span>
                                            Pilih logo.<br/>Tekan area ini, atau tarik foto ke sini.
                                        </span>
                                    )}
                                    setBinaryStr={(str) => handleImageSelected(str, "logo", "tipe_file_logo")}
                                    accept={{
                                        [PNG_FILE_TYPE_DROPZONE]: [PNG_FILE_EXTENSION],
                                        [JPG_FILE_TYPE_DROPZONE]: [JPG_FILE_EXTENSION],
                                        [JPEG_FILE_TYPE_DROPZONE]: [JPEG_FILE_EXTENSION],
                                    }}
                                />
                            </Col>
                        {
                            itemInEdit?.logo ?
                            <Col lg="6" className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                <Image src={`data:${itemInEdit.tipe_file_logo};base64,${itemInEdit.logo}`} className="img-preview" />
                            </Col>
                            :
                            null
                        }
                        </Row>

                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={5}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default DivisiEditor;