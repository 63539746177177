import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Inject } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, GridComponent, Resize } from "@syncfusion/ej2-react-grids";
import { componentToHex, JPEG_FILE_EXTENSION, JPEG_FILE_TYPE_DROPZONE, JPG_FILE_EXTENSION, JPG_FILE_TYPE_DROPZONE, PNG_FILE_EXTENSION, PNG_FILE_TYPE_DROPZONE, separateBase64String, SJ_STATUS_NEW, useWindowDimensions } from "../../../utils/Common";
import { comboboxEditParams, defaultToolbarOptions, numericEditParams, textareaParams } from "../../../utils/Grid";
import { Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { useStateContext } from "../../../reducers";
import { handleChangeDOStatus } from "../../../utils/api/sales";
import ModalAlert from "../../../components/ModalAlert";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { ComboBox } from "../../../components/Dropdowns";
import Dropzone from '../../../components/Dropzone';

const readonlyColumns = [
    "nama_satuan",
    "nama_kategori_brg",
    "nama_group_brg",
    "qty_stok",
    "qty_tersedia",
    "qty_sisa",
];

function SuratJalanEditor(props) {
    const {
        onHide,
        data,
        onSave,
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, tanggal_transaksi: data?.tanggal_transaksi ? moment(data.tanggal_transaksi).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")});
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [reloadWarehouse, setReloadWarehouse] = useState(false);
    const [loadSODetails, setLoadSODetails] = useState(false);
    const [selectedSalesOrderDetails, setSelectedSalesOrderDetails] = useState([]);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [errorObj, setErrorObj] = useState(null);
    const {height} = useWindowDimensions();
    const { apiCaller, authState } = useStateContext();
    const gridRef = useRef(null);

    const loadDetail = useCallback(() => {
        if (!data || !data.ucode || !data.ucode_sales_order)
            return;
        
        setLoading(true);
        const apiCalls = [
            apiCaller.get(`deliveryorders/detail/all?id=${data.ucode}`),
            apiCaller.get(`salesorders/search/id?id=${data.ucode_sales_order}`),
            apiCaller.get(`salesorders/search/do/items/id?id=${data.ucode_sales_order}`),
        ];

        if (data.ucode_gudang)
            apiCalls.push(apiCaller.get(`warehouses/search/id?id=${data.ucode_gudang}`));

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                const { data: { data: { details: newDetails, images } } } = responses[0];
                setDetails(newDetails);
                const newItemInEdit = itemInEdit ? JSON.parse(JSON.stringify(itemInEdit)) : {};
                newItemInEdit.bukti_pesan = images?.bukti_pesan;
                newItemInEdit.tipe_file_bukti_pesan = images?.tipe_file_bukti_pesan;

                const { data: {data: selected_so} } = responses[1];
                newItemInEdit.sales_order = selected_so;

                setSelectedSalesOrderDetails([...responses[2].data.data]);

                if (data.ucode_gudang)
                {
                    const { data: {data: selected_warehouse} } = responses[3];
                    newItemInEdit.gudang = selected_warehouse;
                }

                setItemInEdit(newItemInEdit);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [data, apiCaller, itemInEdit]);

    useEffect(() => {
        loadDetail();
    }, [data]);

    // useEffect(() => console.log({details}), [details]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    const autoGenerateDetails = useCallback(() => {
        if (!itemInEdit?.ucode_sales_order)
        {
            setDetails([]);
            return;
        }
        
        setLoading(true);

        const apiCalls = [
            apiCaller.get(`salesorders/search/do/details/all?id=${itemInEdit.ucode_sales_order}`),
            apiCaller.get(`salesorders/search/do/items/id?id=${itemInEdit.ucode_sales_order}`),
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                setDetails([...responses[0].data.data.map((d, idx) => ({...d, ucode: idx + 1}))]);
                setSelectedSalesOrderDetails([...responses[1].data.data]);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [itemInEdit, apiCaller]);

    useEffect(() => {
        // console.log({loadSODetails});
        if (!loadSODetails)
            return;

        setLoadSODetails(false);
        autoGenerateDetails();
    }, [loadSODetails]);

    const handleSave = useCallback((approve = false) => {
        if (!itemInEdit)
            return;
        
        setLoading(true);

        const detailToSend = details.map(d => {
            return {
                ucode: d.ucode,
                ucode_surat_jalan: d.ucode_surat_jalan,
                ucode_sales_order_d: d.ucode_sales_order_d,
                qty_kirim: d.qty_kirim,
                ucode_status: d.ucode_status,
                keterangan: d.keterangan,
            };
        });

        const dataToSend = {
            ucode: itemInEdit.ucode,
            ucode_sales_order: itemInEdit.ucode_sales_order,
            tanggal_transaksi: moment(itemInEdit.tanggal_transaksi).format("YYYY-MM-DD"),
            ucode_status: itemInEdit.ucode_status,
            ucode_gudang: itemInEdit.ucode_gudang,
            from_so: itemInEdit.from_so,
            no_bukti_penerimaan_barang: itemInEdit.no_bukti_penerimaan_barang,
            bukti_pesan: itemInEdit.bukti_pesan,
            tipe_file_bukti_pesan: itemInEdit.tipe_file_bukti_pesan,
            keterangan: itemInEdit.keterangan,
            details: [...detailToSend],
        };

        // console.log({itemInEdit, detailToSend, dataToSend});
        const request = itemInEdit && !itemInEdit.ucode ? apiCaller.post(`deliveryorders`, JSON.stringify(dataToSend)) : apiCaller.put(`deliveryorders/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                // setLoading(false);
                if (!approve)
                {
                    setLoading(false);
                    onSave();
                    return;
                }

                handleChangeDOStatus(
                    itemInEdit.ucode ? itemInEdit.ucode : response.data.data,
                    data.next_kode_status,
                    apiCaller,
                    () => {
                        setLoading(false);
                        onSave();
                    },
                    (error) => {
                        console.error({error});
                        setLoading(false);
                    },
                );
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, details, apiCaller, data, onSave]);

    const validate = useCallback(() => {
        if (!gridRef.current)
        {
            if (!disableSave)
                setDisableSave(true);
            
            return;
        }

        const changes = gridRef.current.getBatchChanges();
        // console.log("validate", {itemInEdit, changes});
        setDisableSave(
            !itemInEdit
            || (itemInEdit.ucode && itemInEdit.kode_status !== SJ_STATUS_NEW && (!authState.override_status || itemInEdit.has_delivery_order))
            || !itemInEdit.tanggal_transaksi
            || !itemInEdit.ucode_gudang
            || (itemInEdit?.sales_order && !itemInEdit.sales_order.dari_customer && (!itemInEdit.no_bukti_penerimaan_barang || !itemInEdit.tipe_file_bukti_pesan))
            || (
                changes &&
                (
                    (changes.addedRecords && changes.addedRecords.length > 0)
                    || (changes.changedRecords && changes.changedRecords.length > 0)
                    || (changes.deletedRecords && changes.deletedRecords.length > 0)
                )
            )
            || !details.length
            || details.filter(d => !d.ucode_sales_order_d || d.ucode_sales_order_d === "" || d.qty_sisa < d.qty_kirim || (!d.qty_stok && d.qty_stok !== 0)).length > 0
        );
    }, [gridRef, itemInEdit, details, disableSave, authState]);

    useEffect(() => {
        // console.log({itemInEdit});
        validate();
    }, [itemInEdit, details]);

    const onSalesOrderSelected = useCallback((val) => {
        if (itemInEdit?.ucode_sales_order !== val?.ucode)
            setReloadWarehouse(true);

        setItemInEdit({
            ...itemInEdit,
            ucode_sales_order: val?.ucode,
            sales_order: val,
        });

        if (!val?.ucode || details.filter(d => d.ucode_sales_order === val?.ucode).length === 0)
            setLoadSODetails(true);
    }, [itemInEdit, details]);

    useEffect(() => {
        if (!reloadWarehouse)
            return;
        
        setReloadWarehouse(false);

        if (!itemInEdit?.ucode_sales_order)
        {
            setItemInEdit({...itemInEdit, gudang: null, ucode_gudang: null});
            return;
        }

        setLoading(true);
        apiCaller.get(`salesorders/warehouse?id=${itemInEdit.ucode_sales_order}`)
            .then(response => {
                const {data: {data: currentGudang}} = response;
                setItemInEdit({...itemInEdit, gudang: currentGudang ? {...currentGudang} : null, ucode_gudang: currentGudang?.ucode ?? null});
            })
            .catch(error => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [reloadWarehouse]);

    const onWarehouseSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_gudang: val?.ucode,
            gudang: val,
        });
    }, [itemInEdit]);

    const onItemChanged = (selectedData, columnName, rowData, rowIndex) => {
        // console.log({selectedData, rowData, rowIndex});

        const setData = (isClearing = false) => {
            if (!gridRef || !gridRef.current)
                return;

            const currentRows = gridRef.current.getCurrentViewRecords();
            // console.log({currentRows});

            if (!isClearing && currentRows.filter(r => r.ucode_sales_order_d === selectedData.ucode_sales_order_d && r.ucode !== rowData.ucode).length > 0)
            {
                setData(true);
                setErrorObj({
                    title: "Input Error!",
                    message: "Data barang tidak boleh kembar.",
                })
                return;
            }

            if (!rowData.ucode || rowData.ucode === "" || rowData.ucode === 0 || rowData.ucode === "0" || (typeof rowData.ucode === 'object'))
            {
                const rowIds = currentRows.filter(r => Number.isInteger(r.ucode))?.map(r => r.ucode) ?? [];
                rowIds.sort().reverse();
                const currentRowId = (rowIds[0] ?? 0) + 1;
                // console.log("new row id: ", {currentRowId});
                gridRef.current.updateCell(rowIndex, "ucode", currentRowId);
            }
            
            gridRef.current.updateCell(rowIndex, "ucode_sales_order_d", isClearing ? null : selectedData.ucode_sales_order_d);
            gridRef.current.updateCell(rowIndex, "ucode_brg", isClearing ? null : selectedData.ucode_brg);
            
            if (columnName === "kode_material")
            {
                gridRef.current.updateCell(rowIndex, "kode_brg", isClearing ? null : selectedData.kode_brg);
                gridRef.current.updateCell(rowIndex, "nama_brg", isClearing ? null : selectedData.nama_brg);
            }
            
            if (columnName === "kode_brg")
            {
                gridRef.current.updateCell(rowIndex, "kode_material", isClearing ? null : selectedData.kode_material);
                gridRef.current.updateCell(rowIndex, "nama_brg", isClearing ? null : selectedData.nama_brg);
            }

            if (columnName === "nama_brg")
            {
                gridRef.current.updateCell(rowIndex, "kode_material", isClearing ? null : selectedData.kode_material);
                gridRef.current.updateCell(rowIndex, "kode_brg", isClearing ? null : selectedData.kode_brg);
            }
            
            gridRef.current.updateCell(rowIndex, "nama_satuan", isClearing ? null : selectedData.nama_satuan);
            gridRef.current.updateCell(rowIndex, "nama_kategori_brg", isClearing ? null : selectedData.nama_kategori_brg);
            gridRef.current.updateCell(rowIndex, "nama_group_brg", isClearing ? null : selectedData.nama_group_brg);
            gridRef.current.updateCell(rowIndex, "nama_satuan", isClearing ? null : selectedData.nama_satuan);
            gridRef.current.updateCell(rowIndex, "qty_tersedia", isClearing ? null : selectedData.qty_tersedia);
            gridRef.current.updateCell(rowIndex, "qty_sisa", isClearing ? null : selectedData.qty_sisa);
            gridRef.current.updateCell(rowIndex, "qty_kirim", isClearing ? null : selectedData.qty_kirim);
        };

        setData(!selectedData || isNullOrUndefined(selectedData[columnName]) || selectedData[columnName] === "");
    }

    const onQtyKirimChanged = (args, rowData, rowIndex) => {
        // console.log({args, rowData, rowIndex});

        const setData = (isClearing = false) => {
            if (!gridRef || !gridRef.current)
                return;

            const currentRows = gridRef.current.getCurrentViewRecords();
            // console.log({currentRows});

            if (!isClearing && rowData.qty_sisa < Number.parseFloat(args.value))
            {
                setData(true);
                setErrorObj({
                    title: "Input Error!",
                    message: "Qty Kirim tidak boleh lebih besar dari Qty Sisa.",
                })
                return;
            }

            if (!rowData.ucode || rowData.ucode === "" || rowData.ucode === 0 || rowData.ucode === "0" || (typeof rowData.ucode === 'object'))
            {
                const rowIds = currentRows.filter(r => Number.isInteger(r.ucode))?.map(r => r.ucode) ?? [];
                rowIds.sort().reverse();
                const currentRowId = (rowIds[0] ?? 0) + 1;
                // console.log("new row id: ", {currentRowId});
                gridRef.current.updateCell(rowIndex, "ucode", currentRowId);
            }
        };

        setData(!args?.value);
    }

    const kodeMaterialParams = comboboxEditParams(
        gridRef,
        selectedSalesOrderDetails,
        {text: "kode_material", value: "kode_material"},
        onItemChanged
    );

    const kodeBrgParams = comboboxEditParams(
        gridRef,
        selectedSalesOrderDetails,
        {text: "kode_brg", value: "kode_brg"},
        onItemChanged
    );

    const namaBrgParams = comboboxEditParams(
        gridRef,
        selectedSalesOrderDetails,
        {text: "nama_brg", value: "nama_brg"},
        onItemChanged
    );
    
    const qtyKirimParams = numericEditParams(gridRef, onQtyKirimChanged);

    const keteranganParams = textareaParams(gridRef);

    const handleStockChecking = useCallback(() => {
        // console.log({itemInEdit, details});
        if (!itemInEdit?.sales_order?.ucode_customer || !itemInEdit?.ucode_gudang || !details.filter(d => d.ucode_brg).length)
            return;

        setLoading(true);
        const dataToSend = {item_ids: details.map(d => d.ucode_brg)};

        apiCaller.post(`salesorders/stock/check?cust_id=${itemInEdit.sales_order.ucode_customer}&wh_id=${itemInEdit.ucode_gudang}`, JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                const {data: {data: stockData}} = response;
                const newDetails = JSON.parse(JSON.stringify(details));
                newDetails.forEach(d => {
                    const currentItem = stockData.filter(s => s.ucode_brg === d.ucode_brg)[0];

                    if (!currentItem)
                    {
                        d.qty_stok = 0;
                        return;
                    }

                    d.qty_stok = (currentItem.qty_masuk ?? 0) - (currentItem.qty_keluar ?? 0);
                });
                setDetails([...newDetails]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, itemInEdit, details]);

    const grid = (
        <GridComponent
            dataSource={details}
            allowResizing={true}
            editSettings={{
                mode: "Batch",
                allowAdding: itemInEdit && itemInEdit.ucode_sales_order && (!itemInEdit.kode_status || itemInEdit.kode_status === SJ_STATUS_NEW || (authState.override_status && !itemInEdit.has_delivery_order)),
                allowEditing: itemInEdit && itemInEdit.ucode_sales_order && (!itemInEdit.kode_status || itemInEdit.kode_status === SJ_STATUS_NEW || (authState.override_status && !itemInEdit.has_delivery_order)),
                allowDeleting: itemInEdit && itemInEdit.ucode_sales_order && (!itemInEdit.kode_status || itemInEdit.kode_status === SJ_STATUS_NEW || (authState.override_status && !itemInEdit.has_delivery_order)),
                showConfirmDialog: false,
            }}
            toolbar={[...defaultToolbarOptions, {
                text: "Cek Stok",
                tooltipText: "Cek Stok",
                prefixIcon: "e-replace",
                id: "stockchecking",
            }]}
            ref={(ref) => gridRef.current = ref}
            height={height >= 960 ? 230 : 150}
            actionComplete={(args) => {
                if (args.requestType.toLowerCase() === "batchsave")
                {
                    // console.log("action complete - save", {args});
                    setDetails(args.rows.map(d => ({...d.data})));
                    setDisableSave(true);
                    return;
                }

                validate();
            }}
            batchCancel={(args) => {
                setDetails(args.rows.map(d => ({...d.data})));
                validate();
            }}
            dataBound={(params) => {
                const emptyRow = document.querySelector('.e-emptyrow');
                if (emptyRow)
                {
                    emptyRow.cells[0].innerText = "Data tidak ditemukan.";
                    return;
                }
            }}
            cellEdit={(args) => {
                if (args.columnName === 'nama_satuan' || args.columnName === 'nama_kategori_brg' || args.columnName === 'nama_group_brg' || args.columnName === 'qty_tersedia' || args.columnName === 'qty_sisa') {
                    args.cancel = true;
                    return;
                }
            }}
            cellSave={(args) => {
                if (!disableSave)
                    setDisableSave(args.value !== args.previousValue);
            }}
            queryCellInfo={(args) => {
                if (readonlyColumns.includes(args.column.field)) { 
                    const val = ("255,219,219").split(",");
                    const str = "#" + componentToHex(parseInt(val[0])) + componentToHex(parseInt(val[1])) + componentToHex(parseInt(val[2]));
                    args.cell.bgColor = str;
                }
            }}
            toolbarClick={(args) => {
                if (args.item.id === "stockchecking") {
                    handleStockChecking();
                    return;
                }
            }}
            beforeBatchAdd={(args) => {
                const gridApi = gridRef?.current;
                const checked = JSON.parse(JSON.stringify(details));

                if (gridApi)
                {
                    const {addedRecords} = gridApi.getBatchChanges();
                    
                    if (addedRecords.length)
                        addedRecords.forEach(r => {
                            checked.push({
                                ...r
                            });
                        });
                }

                const rowIds = checked.filter(r => Number.isInteger(r.ucode))?.map(r => r.ucode) ?? [];
                rowIds.sort().reverse();
                const currentRowId = (rowIds[0] ?? 0) + 1;
                args.defaultData = {
                    ...args.defaultData,
                    ucode: currentRowId,
                    qty_stok: null,
                }
            }}
        >
            <ColumnsDirective>
                <ColumnDirective field='ucode' headerText='ID' textAlign='Left' width={120} isPrimaryKey={true} isIdentity={true} visible={false} type="string" />
                <ColumnDirective field='ucode_surat_jalan' headerText='DO_ID' textAlign='Left' width={120} visible={false} type="string" />
                <ColumnDirective field='ucode_sales_order_d' headerText='SO_ID' textAlign='Left' width={120} visible={false} type="string" />
            {
                itemInEdit?.punya_kode_brg_sendiri || itemInEdit?.sales_order?.punya_kode_brg_sendiri ?
                <ColumnDirective field='kode_material' headerText='No. Material' textAlign='Left' width={120} edit={kodeMaterialParams} type="string" />
                :
                null
            }
                <ColumnDirective field='kode_brg' headerText='Kode Barang' textAlign='Left' width={120} edit={kodeBrgParams} type="string" />
                <ColumnDirective field='nama_brg' headerText='Nama Barang' textAlign='Left' width={120} edit={namaBrgParams} type="string" />
                <ColumnDirective field='nama_satuan' headerText='Satuan' textAlign='Left' width={120} type="string" />
                <ColumnDirective field='nama_kategori_brg' headerText='Kategori Barang' textAlign='Left' width={120} type="string" />
                <ColumnDirective field='nama_group_brg' headerText='Grup Barang' textAlign='Left' width={120} type="string" />
                <ColumnDirective field="qty_stok" headerText="Qty Stok" headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                <ColumnDirective field='qty_tersedia' headerText='Qty Approved' headerTextAlign="Left" textAlign='Right' width={100} format="N2" type="number" />
                <ColumnDirective field='qty_sisa' headerText='Qty Sisa' headerTextAlign="Left" textAlign='Right' width={100} format="N2" type="number" />
                <ColumnDirective field='qty_kirim' headerText='Qty Kirim' headerTextAlign="Left" textAlign='Right' width={100} edit={qtyKirimParams} format="N2" type="number" />
                <ColumnDirective field='keterangan' headerText='Keterangan' textAlign='Left' width={120} edit={keteranganParams} type="string" />
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, Resize]}/>
        </GridComponent>
    );

    const handleImageSelected = useCallback((base64Str, fileStrKey, fileTypeKey) => {
        const separationResult = separateBase64String(base64Str);
        setItemInEdit({...itemInEdit, [fileStrKey]: separationResult.base64Str, [fileTypeKey]: separationResult.mimeType});
    }, [itemInEdit]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Keluar
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            {
                data && data.next_kode_status && !disableSave ?
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={() => {
                            setConfirmationObj({
                                title: "Konfirmasi Pengubahan Status",
                                message: <span>Data akan disimpan, status Surat Jalan akan diubah ke <b className="text-success">{data.next_nama_status}</b> dan Surat Jalan tidak akan bisa diubah. Lanjutkan?</span>,
                                onConfirmed: () => {
                                    setConfirmationObj(null);
                                    handleSave(true);
                                },
                            });
                        }}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        {data.next_nama_status}
                    </Button>
                </Col>
                :
                null
            }
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave, data]);

    const notes = useMemo(() => {
        const result = itemInEdit?.updated_by ?
            [
                <>Status: <u>{`${itemInEdit?.nama_status ?? "N/A"}`}</u> oleh <u>{itemInEdit?.updated_by}</u> pada <u>{itemInEdit?.updated_at ? moment(itemInEdit?.updated_at).format("DD-MM-YYYY HH:mm") : "N/A"}</u></>,
                <>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</>,
            ]
            :
            [
                <>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</>,
            ];
        result.push(<>Data hanya bisa disimpan setelah proses cek stok dilakukan untuk setiap barang.</>);
        return result;
    }, [itemInEdit]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Surat Jalan"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-80"
            footer={footer}
            notes={notes}
            useDefaultNotes={false}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    No. Surat Jalan:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.no_transaksi ? itemInEdit.no_transaksi : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Form.Label>
                            Tanggal Surat Jalan: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="date"
                            value={itemInEdit && itemInEdit.tanggal_transaksi ? itemInEdit.tanggal_transaksi : ""}
                            name="tanggal_transaksi"
                            onChange={(e) => {
                                // console.log(e.target.value);
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SJ_STATUS_NEW && (!authState.override_status || itemInEdit.has_delivery_order)}
                        />
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                    {
                        itemInEdit && (itemInEdit.from_so || itemInEdit.ucode) ?
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    No. Sales Order (No. Purchase Order):
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit?.sales_order ? itemInEdit.sales_order.no_transaksi_kombinasi : "-"}
                                </span>
                            </Col>
                        </Row>
                        :
                        <>
                            <Form.Label>
                                No. Sales Order / No. PO: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="salesorders/search/doavailable"
                                idField="ucode"
                                valueField="no_transaksi_kombinasi"
                                isLookup={false}
                                onChange={(val) => onSalesOrderSelected(val)}
                                selectedItem={itemInEdit?.sales_order}
                                authState={authState}
                                minLength={0}
                                disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SJ_STATUS_NEW && (!authState.override_status || itemInEdit.has_delivery_order)}
                            />
                        </>
                    }
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Form.Label>
                            Gudang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="warehouses/search/division"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onWarehouseSelected(val)}
                            selectedItem={itemInEdit?.gudang}
                            authState={authState}
                            minLength={0}
                            disabled={itemInEdit && ((itemInEdit.kode_status && itemInEdit.kode_status !== SJ_STATUS_NEW && (!authState.override_status || itemInEdit.has_delivery_order)) || !itemInEdit.ucode_sales_order)}
                            additionalParam={{
                                id: itemInEdit?.sales_order?.ucode_divisi
                            }}
                        />
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Divisi:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.sales_order?.nama_divisi ? itemInEdit.sales_order.nama_divisi : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Customer:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.sales_order?.nama_customer ? itemInEdit.sales_order.nama_customer : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Alamat Kirim:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.sales_order?.alamat_kirim ? itemInEdit.sales_order?.alamat_kirim : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                {
                    itemInEdit?.sales_order && !itemInEdit.sales_order.dari_customer ?
                    <Col lg="12" className="m-0 p-0">
                        <Row className="m-0 p-0">
                            <Col lg="3" md="12" className="mb-3">
                                <Row className="m-0 p-0">
                                    <Col lg="12" className="m-0 p-0">
                                        <Form.Label>
                                            No. Bukti Penerimaan Barang: <span className="text-danger">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg="12" className="m-0 p-0">
                                        <Form.Control
                                            value={itemInEdit !== null && itemInEdit.no_bukti_penerimaan_barang ? itemInEdit.no_bukti_penerimaan_barang : ""}
                                            name="no_bukti_penerimaan_barang"
                                            onChange={(e) => {
                                                handleTextChanged(e);
                                            }}
                                            autoComplete="off"
                                            disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SJ_STATUS_NEW && (!authState.override_status || itemInEdit.has_delivery_order)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="9" md="12" className="mb-3">
                                <Row className="m-0 p-0">
                                    <Col lg="12" className="m-0 p-0">
                                        <Form.Label>
                                            Bukti Pemesanan: <span className="text-danger">*</span>
                                            {
                                                itemInEdit?.bukti_pesan ?
                                                <>
                                                    <br />
                                                    <Button
                                                        variant="danger"
                                                        type="submit"
                                                        onClick={() => {
                                                            setItemInEdit({...itemInEdit, bukti_pesan: null, tipe_file_bukti_pesan: null});
                                                        }}
                                                        size="sm-custom"
                                                    >
                                                        <i className="fas fa-eraser" />
                                                    </Button>
                                                </>
                                                :
                                                null
                                            }
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="m-0 p-0">
                                    <Col lg={itemInEdit?.bukti_pesan ? 6 : 12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <Dropzone
                                            dropText={(
                                                <span>
                                                    Pilih bukti pemesanan.<br/>Tekan area ini, atau tarik foto ke sini.
                                                </span>
                                            )}
                                            setBinaryStr={(str) => handleImageSelected(str, "bukti_pesan", "tipe_file_bukti_pesan")}
                                            accept={{
                                                [PNG_FILE_TYPE_DROPZONE]: [PNG_FILE_EXTENSION],
                                                [JPG_FILE_TYPE_DROPZONE]: [JPG_FILE_EXTENSION],
                                                [JPEG_FILE_TYPE_DROPZONE]: [JPEG_FILE_EXTENSION],
                                            }}
                                        />
                                    </Col>
                                {
                                    itemInEdit?.bukti_pesan ?
                                    <Col lg="6" className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                        <Image src={`data:${itemInEdit.tipe_file_bukti_pesan};base64,${itemInEdit.bukti_pesan}`} className="img-preview" />
                                    </Col>
                                    :
                                    null
                                }
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    :
                    null
                }
                </Row>
                <Row className="mb-3">
                    <Col lg="12">
                        {grid}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={3}
                        />
                    </Col>
                </Row>
            </Container>
        {
            confirmationObj ?
            <ModalAlert
                onHide={() => setConfirmationObj(null)}
                onSave={() => confirmationObj.onConfirmed()}
                title={confirmationObj.title}
                bodyText={confirmationObj.message}
            />
            :
            null
        }
        {
            errorObj ?
            <ModalAlert
                onHide={() => setErrorObj(null)}
                onSave={() => setErrorObj(null)}
                title={errorObj.title}
                bodyText={errorObj.message}
                showCancelButton={false}
                confirmButtonText="OK"
            />
            :
            null
        }
        </CustomModal>
    );
};

export default SuratJalanEditor;
