import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { useExpanded, useFilters, useFlexLayout, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import Pager from './Pager';
// import { useWindowDimensions } from '../utils/Common';
import { DefaultColumnFilter } from '../utils/Grid';
import { useStateContext } from '../reducers';
import { useSticky } from 'react-table-sticky';
import XLSX from 'sheetjs-style';
import { XLSX_FILE_EXTENSION, XLSX_FILE_TYPE } from '../utils/Common';
import FileSaver from 'file-saver';
import LoaderProgress from './LoaderProgress';
import moment from 'moment';

function DefaultGrid(props) {
    const {
        columns,
        url,
        additionalParams,
        initData,
        reload,
        rowColorFunction,
        reloadCallback,
        initialFilters,
        initialSortBy,
        showPager,
        hidePageSizeSelector,
        defaultPageSize,
        onSetPageNumber,
        onSetPageSize,
        maxRowCount,
        addParamChangedAutoReload,
        onTotalDataChanged,
        excelFileNameTemplate,
        exportToExcel,
        setExportToExcel,
    } = props;

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [totalData, setTotalData] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);
    const [sortBy, setSortBy] = useState([]);
    const [refs, setRefs] = useState([]);
    const [readyToExport, setReadyToExport] = useState(false);
    const [exportedData, setExportedData] = useState([]);
    const [loadingExport, setLoadingExport] = useState(null);
    const skipPageResetRef = useRef();
    const { apiCaller } = useStateContext();
    // const { height } = useWindowDimensions();
    // console.log({height});

    const loadData = useCallback(() => {
        skipPageResetRef.current = true;
        // console.log({url, currentPage, pageSize});

        if (initData?.length)
        {
            setData([...initData]);
            setTotalData(maxRowCount ?? initData.length);
            return;
        }

        if (!url)
        {
            setData([]);
            setTotalData(0);
            return;
        }

        setLoading(reload >= 0);

        let stringFilters = "";

        filters?.forEach((f, idx) => {
            stringFilters += `&${f.id}=${f.value}`;
        });

        const stringSortBy = sortBy?.length ? `&sort-${sortBy[0].id}=${sortBy[0].desc ? "desc" : "asc"}` : "";

        apiCaller.get(`${url}?skip=${(currentPage - 1) * pageSize}&take=${pageSize}${additionalParams && additionalParams !== "" ? `&${additionalParams}` : ""}${stringFilters}${stringSortBy}`)
            .then((response) => {
                const {data: {data: responseData, total}} = response;
                // console.log({responseData, total});
                setData(responseData);
                setTotalData(total);

                if (reloadCallback)
                    reloadCallback(responseData);
            })
            .catch((error) => {
                console.error({error});
                setData([]);
            })
            .finally(() => {
                setLoading(false);
            })
        ;
    }, [url, currentPage, pageSize, reloadCallback, initData, additionalParams, apiCaller, filters, sortBy, reload, maxRowCount]);

    useEffect(() => {
        loadData();
    }, [url, currentPage, pageSize, reload, filters, sortBy, maxRowCount, initData]);

    useEffect(() => {
        if (!addParamChangedAutoReload)
            return;

        loadData();
    }, [additionalParams]);

    useEffect(() => {
        onTotalDataChanged(totalData);
    }, [totalData]);

    useEffect(() => {
        // console.log({columns, data});
        if (!data.length)
        {
            setRefs([]);
            return;
        }

        const newRefs = [];
        data.forEach((d, idx) => {
            columns.forEach((c, c_idx) => {
                newRefs.push({
                    row_id: idx,
                    col_id: c_idx,
                    ref: createRef()
                });
            });
        });
        setRefs([...newRefs]);
    }, [columns, data]);

    const apiGenerator = useCallback((i, generatedData = [], errors = []) => {
        const maxDataDownload = 100;
        const iterationCount = totalData > maxDataDownload ? parseInt(Math.ceil(totalData / maxDataDownload)) : 1;
        // console.log({rows, i});

        if (i >= iterationCount)
        {
            setExportedData([...generatedData]);
            setReadyToExport(true);
            return;
        }

        setLoadingExport({
            now: (Math.ceil(i + 0.5) * 100 / iterationCount) >= 100 ? 99 : Math.round(Math.ceil(i + 0.5) * 100 / iterationCount),
        })
        // console.log({firstData: partialData[0], i});

        const continueProcess = (currentData) => {
            setLoadingExport({
                now: Math.floor((i + 1) * 100 / iterationCount),
            })
            apiGenerator(i + 1, [...generatedData, ...currentData], errors);
        };

        let stringFilters = "";

        filters?.forEach((f) => {
            stringFilters += `&${f.id}=${f.value}`;
        });

        const stringSortBy = sortBy?.length ? `&sort-${sortBy[0].id}=${sortBy[0].desc ? "desc" : "asc"}` : "";

        apiCaller.get(`${url}?skip=${i * maxDataDownload}&take=${maxDataDownload}${additionalParams && additionalParams !== "" ? `&${additionalParams}` : ""}${stringFilters}${stringSortBy}`)
            .then((response) => {
                const {data: {data: responseData}} = response;
                const currentData = responseData.map((d, idx) => {
                    const result = {"No.": (i * maxDataDownload) + idx + 1};
        
                    columns.filter(c => c.Header && c.Header !== "" && c.accessor).forEach(c => {
                        result[c.Header] =
                            d[c.id] !== null && d[c.id] !== undefined ?
                            (
                                c.type === "date" ?
                                moment(d[c.id]).format("DD-MM-YYYY")
                                :
                                c.type === "datetime" ?
                                moment(d[c.id]).format("DD-MM-YYYY HH:mm")
                                :
                                c.type === "datetimeoffset" ?
                                moment.utc(d[c.id]).local().format("DD-MM-YYYY HH:mm")
                                :
                                c.type === "boolean" ?
                                (isNaN(d[c.id]) && d[c.id] ? true : parseInt(d[c.id]) ? true : false)
                                :
                                d[c.id]
                            )
                            :
                            null
                        ;
                    });
        
                    return result;
                });

                setTimeout(() => continueProcess(currentData), 3000);

            })
            .catch((error) => {
                console.error({error});
                setTimeout(() => continueProcess([]), 3000);
            })
            .finally(() => {
                setLoading(false);
            })
        ;
    }, [url, apiCaller, totalData, additionalParams, filters, sortBy, columns]);

    useEffect(() => {
        if (!exportToExcel || !excelFileNameTemplate || !setExportToExcel)
            return;

        setExportToExcel(false);
        setLoadingExport({
            now: 0,
        });
        apiGenerator(0);
    }, [exportToExcel]);

    useEffect(() => {
        if (!readyToExport)
            return;

        setReadyToExport(false);
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const finalExportedData = new Blob([excelBuffer], {type: XLSX_FILE_TYPE});
        FileSaver.saveAs(finalExportedData, `${excelFileNameTemplate}_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
        setExportedData([]);
        setLoadingExport(null);
    }, [readyToExport]);

    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue).replace(/[/-]/g, "")
                        .toLowerCase()
                        .includes(String(filterValue).replace(/[/-]/g, "").toLowerCase())
                    : true
                })
            },
        }),
        []
    );

    const defaultColumn = useMemo(() => ({
        width: 150,
        filter: "text",
        disableSortBy: false,
        Filter: DefaultColumnFilter
    }), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                filters: initialFilters,
                sortBy: initialSortBy,
            },
            filterTypes,
            defaultColumn,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useExpanded,
        useSticky,
    );

    useEffect(() => {
        if (!state?.filters?.length)
            setFilters([]);
        else
            setFilters([...state.filters]);
    }, [state?.filters]);

    useEffect(() => {
        if (!state?.sortBy?.length)
            setSortBy([]);
        else
            setSortBy([...state.sortBy]);
    }, [state?.sortBy]);

    useEffect(() => {
        if (!onSetPageNumber)
            return;

        onSetPageNumber(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (!onSetPageSize)
            return;

        onSetPageSize(pageSize);
    }, [pageSize]);

    const pager = useMemo(() => (
        <Pager
            pageSizeChange={(size) => setPageSize(size)}
            currentPageSize={pageSize}
            pageChange={(page) => setCurrentPage(page)}
            currentPage={currentPage}
            rowNumber={totalData}
            hidePageSizeSelector={hidePageSizeSelector}
        />
    ), [pageSize, currentPage, totalData, hidePageSizeSelector]);

    return (
        <>
        {
            loadingExport ? <LoaderProgress now={loadingExport.now} /> : null
        }
            <Table bordered hover responsive size="sm" {...getTableProps()} className="mb-0">
                <thead className="bg-grid-header d-block font-xs-custom" style={{overflowY: "scroll"}}>
                    {headerGroups.map((headerGroup, idx) => (
                        <>
                            <tr {...headerGroup.getHeaderGroupProps()} key={`row-header-${idx}`}>
                                {headerGroup.headers.map((column, c_idx) => {
                                    // console.log({column});
                                    return (
                                        <th {...column.getHeaderProps()} key={`row-header_cell-${idx}-${c_idx}`} className={`px-2 font-xs-custom d-flex flex-column${column?.sticky ? " bg-grid-header" : ""}`}>
                                            <div {...column.getHeaderProps(column.getSortByToggleProps())} className="mt-2 w-100 d-flex flex-row justify-content-between">
                                                {column.render('Header')}
                                                <span className="ms-2">
                                                    {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? (<i className="fas fa-chevron-down" />)
                                                        : (<i className="fas fa-chevron-up" />)
                                                    : ''}
                                                </span>
                                            </div>
                                            <div className="mt-2 w-100">{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </>
                    ))}
                </thead>
                <tbody
                    {...getTableBodyProps()}
                    className="w-100 grid-body font-xs-custom"
                    // style={{maxHeight: height >= 992 ? "50vh" : height >= 800 ? "41vh" : height >= 540 ? "28vh" : "18vh", overflowY: "scroll"}}
                >
                {
                    loading ?
                    <tr className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                        <td colSpan={columns.length} className="font-xs-custom w-100 d-flex flex-wrap justify-content-center align-items-center">
                            <Spinner
                                as="span"
                                animation="border"
                                variant="secondary"
                            />
                        </td>
                    </tr>
                    :
                    rows.length ?
                    rows.map((row, idx) => {
                        prepareRow(row);
                        const rowStyle = rowColorFunction(row.original);
                        // console.log({row, rowStyle});
            
                        return (
                            <tr {...row.getRowProps()} key={`row-${idx}`} className="w-100" style={{display: "table", ...rowStyle}}>
                                {row.cells.map((cell, c_idx) => {
                                    // console.log({cell, refs});
                                    const currentRef = refs.filter(r => r.row_id === idx && r.col_id === c_idx)[0] ? refs.filter(r => r.row_id === idx && r.col_id === c_idx)[0].ref : null;
                                    const cellKey = `row-cell-${idx}-${c_idx}`;
            
                                    if (!cell.column.clickable)
                                        return (
                                            <td {...cell.getCellProps()} key={cellKey} className={`px-2 font-xs-custom${cell.column && cell.column.centered ? " text-center" : ""} text-break${cell?.column?.sticky ? ` bg-white` : ""}`} ref={currentRef}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-2 font-xs-custom cursor-pointer"
                                            ref={currentRef}
                                            onClick={(e) => cell.column.onClick(currentRef, row, e.target)}
                                            key={cellKey}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        );
                    })
                    :
                    <tr className="w-100 d-flex flex-wrap justify-content-center align-items-center">
                        <td colSpan={columns.length} className="font-xs-custom w-100 d-flex flex-wrap justify-content-center align-items-center">
                            <i>Data tidak ditemukan</i>
                        </td>
                    </tr>
                }
                </tbody>
            </Table>
        {
            showPager &&
            pager
        }
        </>
    );
}

DefaultGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    url: PropTypes.string,
    reload: PropTypes.number.isRequired,
    rowColorFunction: PropTypes.func,
    reloadCallback: PropTypes.func,
    initData: PropTypes.array,
    initialFilters: PropTypes.array,
    initialSortBy: PropTypes.array,
    showPager: PropTypes.bool,
    additionalParams: PropTypes.string,
    hidePageSizeSelector: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    onSetPageNumber: PropTypes.func,
    onSetPageSize: PropTypes.func,
    maxRowCount: PropTypes.number,
    addParamChangedAutoReload: PropTypes.bool,
    onTotalDataChanged: PropTypes.func,
    excelFileNameTemplate: PropTypes.string,
    exportToExcel: PropTypes.bool,
    setExportToExcel: PropTypes.func,
};

DefaultGrid.defaultProps = {
    reload: 0,
    rowColorFunction: () => ({}),
    initData: [],
    initialFilters: [],
    initialSortBy: [],
    showPager: true,
    additionalParams: "",
    hidePageSizeSelector: false,
    defaultPageSize: 100,
    onSetPageNumber: () => {},
    onSetPageSize: () => {},
    addParamChangedAutoReload: true,
    onTotalDataChanged: () => {},
    exportToExcel: false,
};

export default DefaultGrid;