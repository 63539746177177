import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import { objectWithoutProperties } from "../../../utils/Common";

function SysUserEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, divisi: data?.ucode_divisi ? {ucode: data.ucode_divisi, kode: data.kode_divisi, nama: data.nama_divisi} : null});
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller, authState } = useStateContext();

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.username || itemInEdit.username === "" || !itemInEdit.password || itemInEdit.password === "" || !itemInEdit.ucode_divisi);
    }, [itemInEdit]);

    const onDivisionSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_divisi: val?.ucode,
            divisi: val,
        });
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {...objectWithoutProperties(itemInEdit, ["divisi", "kode_divisi", "nama_divisi", "last_logged_in"])};
        // console.log({itemInEdit, dataToSend});
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`sysusers`, JSON.stringify(dataToSend)) : apiCaller.put(`sysusers/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then(() => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="User"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-70 modal-h-md"
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="justify-content-md-center mb-3 w-100">
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Divisi: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="divisions/search/parent/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onDivisionSelected(val)}
                            selectedItem={itemInEdit?.divisi}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    <Col lg="4" md="12">
                        <Form.Label>
                            Username: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            value={itemInEdit?.username ?? ""}
                            onChange={(e) => handleTextChanged(e)}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12">
                        <Form.Label>
                            Password: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            onChange={(e) => handleTextChanged(e)}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default SysUserEditor;