import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import { ComboBox } from "../../../components/Dropdowns";
import TooltipButton from "../../../components/TooltipButton";

function CustomerGudangEditor(props) {
    const {
        divisionId,
        customerName,
        initialWarehouses,
        onHide,
        authState,
    } = props;

    const [ selectedWarehouses, setSelectedWarehouses ] = useState(initialWarehouses);
    const [ selectedWarehouse, setSelectedWarehouse ] = useState(null);
    const [ reload, setReload ] = useState(0);

    const onWarehouseSelected = useCallback((val) => {
        setSelectedWarehouse(val ? {...val} : null);
    }, []);

    const onSelectClick = useCallback(() => {
        if (!selectedWarehouse || selectedWarehouses?.filter(c => c.ucode_gudang === selectedWarehouse.ucode).length >= 1)
            return;

        setSelectedWarehouses([
            ...selectedWarehouses,
            {
                ucode_gudang: selectedWarehouse.ucode,
                kode_gudang: selectedWarehouse.kode,
                nama_gudang: selectedWarehouse.nama,
            },
        ]);
        setReload(reload === 1 ? 0 : 1);
    }, [selectedWarehouses, selectedWarehouse, reload]);

    const columns = useMemo(() => [
        {
            Header: "Kode",
            accessor: "kode_gudang",
        },
        {
            Header: "Nama",
            accessor: "nama_gudang",
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="outline-danger"
                            className="me-2"
                            onClick={() => {
                                const filtered = selectedWarehouses.filter(c => c.ucode_gudang !== original.ucode_gudang);
                                setSelectedWarehouses(filtered);
                                setReload(reload === 1 ? 0 : 1);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 80,
            width: 80,
        },
    ], [selectedWarehouses, reload]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={selectedWarehouses}
                reload={reload}
                maxRowCount={selectedWarehouses.length}
            />
        );
    }, [columns, selectedWarehouses, reload]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="3" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide(selectedWarehouses)}
                        disabled={!selectedWarehouses.length}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onHide, selectedWarehouses]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide([], true)}
            title={customerName ?? "Customer"}
            titleDetails="Update Gudang"
            dialogClassName="modal-lg-width"
            footer={footer}
        >
            <Container>
                <Row className="justify-content-md-start mb-3">
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Gudang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="warehouses/search/division"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onWarehouseSelected(val)}
                            selectedItem={selectedWarehouse}
                            authState={authState}
                            minLength={0}
                            additionalButtons={[
                                (
                                    <InputGroup.Text onClick={() => onSelectClick()} className={!selectedWarehouse || selectedWarehouses?.filter(c => c.ucode_gudang === selectedWarehouse.ucode).length >= 1 ? null : "cursor-pointer bg-primary text-white"}>
                                        <i className="fas fa-arrow-down"></i>
                                    </InputGroup.Text>
                                )
                            ]}
                            additionalParam={{
                                id: divisionId,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                {
                    grid
                }
                </Row>
            </Container>
        </CustomModal>
    );
}

export default CustomerGudangEditor;