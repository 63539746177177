import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStateContext } from "../../../reducers";
import Loader from "../../../components/Loader";
import moment from "moment";
import { useWindowDimensions } from "../../../utils/Common";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink, Image } from '@react-pdf/renderer';
import OpenSansRegular from '../../../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../../assets/fonts/OpenSans-Bold.ttf';
import OpenSansItalic from '../../../assets/fonts/OpenSans-Italic.ttf';
import CalibriRegular from '../../../assets/fonts/calibri.ttf';
import CalibriBold from '../../../assets/fonts/calibrib.ttf';
import CalibriItalic from '../../../assets/fonts/calibrii.ttf';
import { isMobile } from "react-device-detect";
import { Button, Container } from "react-bootstrap";

Font.register({
    family: 'calibri',
    fonts: [
        {
            src: CalibriRegular,
        },
        {
            src: CalibriBold,
            fontWeight: "bold",
        },
        {
            src: CalibriItalic,
            fontStyle: "italic",
        },
    ]
});

Font.register({
    family: 'open-sans',
    fonts: [
        {
            src: OpenSansRegular,
        },
        {
            src: OpenSansBold,
            fontWeight: "bold",
        },
        {
            src: OpenSansItalic,
            fontStyle: "italic",
        },
    ]
});

const maxDataRowInLastPage = 12;
const maxDataRowInPage = 13;

function DeliveryOrderReceiptCustomerContent(props) {
    const {
        itemToShow,
        userData,
    } = props;

    const styles = StyleSheet.create({
        page: {
            fontFamily: "calibri",
        },
        section: {
            marginVertical: 10,
            marginHorizontal: 15,
            padding: 10,
            flexGrow: 1,
        },
        row: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        rowStretch: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-center",
            flexWrap: "wrap",
        },
        col: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        headerTitle: {
            width: "100%",
            fontSize: 14,
            fontWeight: "bold",
            marginVertical: 5,
        },
        header: {
            width: "100%",
            fontSize: 10,
            marginVertical: 2,
        },
        title: {
            width: "100%",
            fontSize: 20,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
            textDecoration: "underline",
        },
        headerDeliveryHeader: {
            width: "100%",
            fontSize: 10,
            fontWeight: "bold",
        },
        headerDeliveryDetail: {
            width: "100%",
            fontSize: 9,
            marginTop: 3,
        },
        descriptionTitle: {
            width: "12%",
            fontSize: 10,
            marginVertical: 2,
            color: "#777",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        descriptionValue: {
            width: "28%",
            fontSize: 10,
            marginVertical: 2,
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        descriptionCenter: {
            width: "20%",
            fontSize: 12,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
        },
        headerDescriptionTitle: {
            width: "25%",
            fontSize: 10,
            color: "#000",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        headerDescriptionValue: {
            width: "75%",
            fontSize: 10,
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        colTitle: {
            fontWeight: "bold",
        },
        col1: {
            width: "5%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col2: {
            width: "10%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col3: {
            width: "10%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col4: {
            width: "25%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col5: {
            width: "5%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "right",
            wordWrap: "break-word",
            wordBreak: "break-word",
            paddingRight: 15,
        },
        col6: {
            width: "20%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col7: {
            width: "10%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col8: {
            width: "15%",
            fontSize: 8,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        colFooter: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
        },
        footer: {
            width: "30%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: 5,
        },
        footerTitle: {
            width: "100%",
            textAlign: "center",
        },
        footerValue: {
            width: "100%",
            textAlign: "center",
            borderTopWidth: 1,
            marginTop: 180,
        },
    });

    const [pageCount, setPageCount] = useState(0);
    const [pageCountArr, setPageCountArr] = useState([]);

    useEffect(() => {
        if (!itemToShow?.details?.length)
            return;

        const currentPageCount = Math.ceil(itemToShow.details.length / maxDataRowInPage) + (itemToShow.details.slice((Math.ceil(itemToShow.details.length / maxDataRowInPage) - 1) * maxDataRowInPage, maxDataRowInPage).length > maxDataRowInLastPage ? 1 : 0);
        const currentPageCountArr = [];

        for(let i = 0; i < currentPageCount; i++)
            currentPageCountArr.push(i);

        // console.log({currentPageCount, currentPageCountArr, itemToShow});
        setPageCount(currentPageCount);
        setPageCountArr(currentPageCountArr);
    }, [itemToShow]);

    // useEffect(() => {
    //     console.log({pageCount, pageCountArr});
    // }, [pageCount, pageCountArr]);

    return (
        <Document>
        {
            pageCountArr.map((c, p_idx) => {
                const currentItemToShow = itemToShow?.details?.length ? itemToShow.details.slice(c * maxDataRowInPage, (c * maxDataRowInPage) + maxDataRowInPage) : [];
                // console.log({c, details: itemToShow?.details, currentItemToShow});

                return (
                    <Page size="A4" style={styles.page} key={`do_cust_page_${p_idx}`}>
                        <View style={styles.section}>
                            <View style={styles.rowStretch}>
                                <View style={{width: "32%"}}>
                                    <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-center"}}>
                                        <View style={{width: "40px", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "start", alignItems: "start"}}>
                                        {
                                            itemToShow?.logo_divisi ?
                                            <Image
                                                src={`data:${itemToShow?.tipe_file_logo_divisi};base64,${itemToShow?.logo_divisi}`}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            :
                                            null
                                        }
                                        </View>
                                        <View style={{width: "calc(100% - 40px)", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start", marginLeft: "20px"}}>
                                            <Text style={styles.headerTitle}>{itemToShow?.nama_divisi}</Text>
                                            <Text style={styles.header}>{itemToShow?.alamat_divisi}</Text>
                                            <Text style={styles.header}>{itemToShow?.kota_divisi}</Text>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>NPWP</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.npwp_divisi}</Text></Text>
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>Telp</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{!itemToShow?.telp_divisi || itemToShow.telp_divisi === "" ? "-" : itemToShow.telp_divisi}</Text></Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{width: "32%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{...styles.title}}>DELIVERY ORDER</Text>
                                </View>
                                <View style={{width: "32%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingHorizontal: 3}}>
                                    <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 8, textAlign: "right"}}>
                                        <Text style={styles.headerDeliveryHeader}>No. Purchase Order</Text>
                                        <Text style={styles.headerDeliveryDetail}>{itemToShow?.no_purchase_order ?? "-"}</Text>
                                    </View>
                                    <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 8, textAlign: "right"}}>
                                        <Text style={styles.headerDeliveryHeader}>No. Delivery Order</Text>
                                        <Text style={styles.headerDeliveryDetail}>{itemToShow?.no_transaksi ?? "-"}</Text>
                                    </View>
                                    <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 8, textAlign: "right"}}>
                                        <Text style={styles.headerDeliveryHeader}>Tanggal Delivery Order</Text>
                                        <Text style={styles.headerDeliveryDetail}>{itemToShow?.tanggal_transaksi ? moment(itemToShow.tanggal_transaksi).format("DD-MM-YYYY") : "-"}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{...styles.row, marginTop: 10}}>
                                <View style={styles.col}>
                                    <Text style={styles.descriptionTitle}>Pelanggan:</Text>
                                    <Text style={styles.descriptionValue}>{itemToShow?.nama_customer}</Text>
                                </View>
                            </View>
                            <View style={{...styles.row}}>
                                <View style={styles.col}>
                                    <Text style={styles.descriptionTitle}>Alamat:</Text>
                                    <Text style={styles.descriptionValue}>{itemToShow?.alamat_kirim}</Text>
                                </View>
                            </View>
                            <View style={{...styles.row, marginTop: 10, paddingTop: 10, paddingBottom: 10, borderTopWidth: 1, borderBottomWidth: 1,}}>
                                <View style={{...styles.col, marginBottom: 3, borderBottomWidth: 0.5, padding: 5}}>
                                    <Text style={{...styles.col1, ...styles.colTitle}}>No.</Text>
                                    <Text style={{...styles.col2, ...styles.colTitle}}>No. Material</Text>
                                    <Text style={{...styles.col3, ...styles.colTitle}}>Kode Barang</Text>
                                    <Text style={{...styles.col4, ...styles.colTitle}}>Nama Barang</Text>
                                    <Text style={{...styles.col5, ...styles.colTitle}}>Qty</Text>
                                    <Text style={{...styles.col6, ...styles.colTitle}}>No. BPB</Text>
                                    <Text style={{...styles.col7, ...styles.colTitle}}>No. GR</Text>
                                    <Text style={{...styles.col8, ...styles.colTitle}}>Remarks</Text>
                                </View>
                            {
                                currentItemToShow.map((d, idx) => {
                                    return (
                                        // <View key={`row-${idx + (c * maxDataRowInPage)}`} style={{...styles.col, backgroundColor: idx % 2 === 0 ? "#ededed" : "transparent", marginVertical: 2, padding: 5}}>
                                        <View key={`row-${idx + (c * maxDataRowInPage)}`} style={{...styles.col, backgroundColor: "transparent", marginVertical: 2, padding: 5}}>
                                            <Text style={styles.col1}>{(idx + 1) + (c * maxDataRowInPage)}.</Text>
                                            <Text style={styles.col2}>{d.kode_brg_lain ?? "-"}</Text>
                                            <Text style={styles.col3}>{d.kode_brg}</Text>
                                            <Text style={styles.col4}>{d.nama_brg}</Text>
                                            <Text style={styles.col5}>{d.qty_kirim}</Text>
                                            <Text style={styles.col6}>{d.no_bpb}</Text>
                                            <Text style={styles.col7}>{d.no_gr}</Text>
                                            <Text style={styles.col8}>{d.nopol && d.nopol !== "" ? d.nopol : "-"}</Text>
                                        </View>
                                    );
                                })
                            }
                            {
                                c === (pageCountArr?.length ?? 0) - 1 ?
                                <View style={{...styles.col, borderTopWidth: 0.5, padding: 5}}>
                                    <Text style={{...styles.col1, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col2, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col3, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col4, ...styles.colTitle}}>Total</Text>
                                    <Text style={{...styles.col5, ...styles.colTitle}}>{itemToShow?.qty_total}</Text>
                                    <Text style={{...styles.col6, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col7, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col8, ...styles.colTitle}}></Text>
                                </View>
                                :
                                null
                            }
                            </View>
                        {
                            c === (pageCountArr?.length ?? 0) - 1 ?
                            <View style={{...styles.row, marginTop: 5, padding: 10}}>
                                <View style={styles.colFooter}>
                                    <View style={styles.footer}>
                                        <Text style={styles.footerTitle}>Diberikan Oleh,</Text>
                                        <Text style={styles.footerValue}></Text>
                                    </View>
                                    <View style={styles.footer}>
                                        <Text style={styles.footerTitle}>Diterima Oleh,</Text>
                                        <Text style={styles.footerValue}></Text>
                                    </View>
                                </View>
                            </View>
                            :
                            null
                        }
                        </View>
                        <Text style={{textAlign: "right", paddingHorizontal: 20, fontSize: 10, fontStyle: "italic"}} fixed>
                            Dicetak Oleh: {userData?.emp_name}
                        </Text>
                        <Text style={{textAlign: "right", paddingHorizontal: 20, fontSize: 10, fontStyle: "italic"}} fixed>
                            Tanggal Cetak: {moment().format("DD-MM-YYYY HH:mm:ss")}
                        </Text>
                        <Text style={{textAlign: "right", paddingHorizontal: 20, paddingBottom: 10, fontSize: 10, fontStyle: "italic", color: "#666"}} render={() => (`halaman ${c + 1} dari ${pageCount}`)} fixed />
                    </Page>
                );
            })
        }
        </Document>
    );
};

function DeliveryOrderReceiptCustomerPrint(props) {
    const [id, setId] = useState(null);
    const [itemToShow, setItemToShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const { authState, apiCaller, menuState, setMenuState } = useStateContext();
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setId(searchParams.get("id"));
    }, [searchParams]);

    const loadData = useCallback(() => {
        if (!id)
            return;

        setLoading(true);
        apiCaller.get(`salesorders/search/customer/deliveryorders/receipt?id=${id}`)
            .then((response) => {
                // console.log({response});
                const {data: {data: {header, items, transactions}}} = response;
                setItemToShow({
                    ...header,
                    details: [...items.map(i => {
                        return {
                            ...i,
                            no_bpb: transactions.filter(t => t.ucode_brg === i.ucode_brg).map(t => t.no_bukti_penerimaan_barang ?? "-").join(", "),
                            no_gr: transactions.filter(t => t.ucode_brg === i.ucode_brg).map(t => t.no_goods_receipt_sap ?? "-").join(", "),
                            nopol: transactions.filter(t => t.ucode_brg === i.ucode_brg).map(t => t.nopol ?? "-").join(", "),
                        }
                    })],
                    qty_total: items.reduce((d, d_obj) => {
                        return d + d_obj.qty_kirim;
                      }, 0),
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [id, apiCaller]);

    // useEffect(() => console.log({itemToShow}), [itemToShow]);

    useEffect(() => {
        if (!id)
            return;

        loadData();
    }, [id]);

    useEffect(() => {
        if (menuState?.showSidebar)
            setMenuState({type: "toggle", showSidebar: false});
    }, [menuState]);

    if (!itemToShow)
        return <></>;

    const styles = StyleSheet.create({
        viewer: {
            width,
            height: height - 6,
            fontFamily: "open-sans",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
        },
        downloadButton: {
        }
    });

    return (
        <>
        {
            loading && <Loader />
        }
        {
            isMobile ?
            <Container fluid className="vh-100 d-flex flex-wrap justify-content-center align-items-center bg-light">
                <PDFDownloadLink document={<DeliveryOrderReceiptCustomerContent itemToShow={itemToShow} />} fileName={`${itemToShow.no_transaksi}-${moment(new Date()).format("YYYYMMDDHHmmss")}`}>
                {
                    ({ loading }) =>
                    (
                        // <View style={styles.viewer}>
                        // {
                        //     loading ?
                        //     <Text style={{fontStyle: "italic"}}>Loading...</Text>
                        //     :
                        //     <Text style={{fontStyle: "bold", color: "#fff", backgroundColor: "#bd1c1c", padding: 5}}>Download</Text>
                        // }
                        // </View>
                        loading ?
                        "Loading..."
                        :
                        <Button variant="print-download" size="sm-custom"><i className="fas fa-file-pdf me-2" />Download SJ</Button>
                    )
                }
                </PDFDownloadLink>
            </Container>
            :
            <PDFViewer style={styles.viewer}>
                <DeliveryOrderReceiptCustomerContent itemToShow={itemToShow} userData={authState} />
            </PDFViewer>
        }
        </>
    );
}

export default DeliveryOrderReceiptCustomerPrint;
