import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStateContext } from "../../../reducers";
import Loader from "../../../components/Loader";
import moment from "moment";
import { useWindowDimensions } from "../../../utils/Common";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink, Image } from '@react-pdf/renderer';
import OpenSansRegular from '../../../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../../assets/fonts/OpenSans-Bold.ttf';
import OpenSansItalic from '../../../assets/fonts/OpenSans-Italic.ttf';
import CalibriRegular from '../../../assets/fonts/calibri.ttf';
import CalibriBold from '../../../assets/fonts/calibrib.ttf';
import CalibriItalic from '../../../assets/fonts/calibrii.ttf';
import { isMobile } from "react-device-detect";
import { Button, Container } from "react-bootstrap";

Font.register({
    family: 'calibri',
    fonts: [
        {
            src: CalibriRegular,
        },
        {
            src: CalibriBold,
            fontWeight: "bold",
        },
        {
            src: CalibriItalic,
            fontStyle: "italic",
        },
    ]
});

Font.register({
    family: 'open-sans',
    fonts: [
        {
            src: OpenSansRegular,
        },
        {
            src: OpenSansItalic,
            fontWeight: "bold",
        },
        {
            src: OpenSansBold,
            fontStyle: "italic",
        },
    ]
});

const maxDataRowInLastPage = 8;
const maxDataRowInPage = 13;

function DeliveryOrderReceiptContent(props) {
    const {
        itemToShow,
        userData,
    } = props;

    const styles = StyleSheet.create({
        page: {
            fontFamily: "calibri",
        },
        section: {
            marginVertical: 5,
            marginHorizontal: 15,
            padding: 10,
            flexGrow: 1,
        },
        row: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        rowStretch: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-center",
            flexWrap: "wrap",
        },
        col: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        headerTitle: {
            width: "100%",
            fontSize: 10,
        },
        headerSection: {
            width: "50%",
            fontSize: 10,
        },
        headerDescriptionTitle: {
            width: "25%",
            fontSize: 10,
            color: "#000",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        headerDescriptionValue: {
            width: "75%",
            fontSize: 10,
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        header: {
            width: "100%",
            fontSize: 10,
        },
        title: {
            width: "100%",
            fontSize: 10,
            textAlign: "center",
            fontWeight: "bold",
            textDecoration: "underline",
        },
        headerDeliveryHeader: {
            width: "100%",
            fontSize: 10,
            fontWeight: "bold",
        },
        headerDeliveryDetail: {
            width: "100%",
            fontSize: 10,
        },
        descriptionTitle: {
            width: "10%",
            fontSize: 10,
            color: "#000",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        descriptionValue: {
            width: "90%",
            fontSize: 10,
            wordWrap: "break-word",
            wordBreak: "break-word",
            fontWeight: "bold",
        },
        colTitle: {
            fontWeight: "bold",
        },
        col1: {
            width: "5%",
            fontSize: 9,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col2: {
            width: "20%",
            fontSize: 9,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col3: {
            width: "20%",
            fontSize: 9,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col4: {
            width: "35%",
            fontSize: 9,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col5: {
            width: "20%",
            fontSize: 9,
            textAlign: "right",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        colFooter: {
            width: "100%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
        },
        footer: {
            width: "30%",
            fontSize: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
        },
        footerTitle: {
            width: "100%",
            textAlign: "center",
        },
        footerValue: {
            width: "100%",
            textAlign: "center",
            borderTopWidth: 1,
        },
    });

    const [pageCount, setPageCount] = useState(0);
    const [pageCountArr, setPageCountArr] = useState([]);

    useEffect(() => {
        if (!itemToShow?.details?.length)
            return;

        const initialPageCount = Math.ceil(itemToShow.details.length / maxDataRowInPage);
        // console.log({initialPageCount, startIndex: (initialPageCount - 1) * maxDataRowInPage, lastIndex: maxDataRowInPage - 1, splitter: itemToShow.details.slice((initialPageCount - 1) * maxDataRowInPage, maxDataRowInPage).length});
        const currentPageCount = initialPageCount + (itemToShow.details.slice((initialPageCount - 1) * maxDataRowInPage, maxDataRowInPage).length > maxDataRowInLastPage ? 1 : 0);
        const currentPageCountArr = [];

        for(let i = 0; i < currentPageCount; i++)
            currentPageCountArr.push(i);

        setPageCount(currentPageCount);
        setPageCountArr(currentPageCountArr);
    }, [itemToShow]);

    return (
        <Document>
        {
            pageCountArr.map(c => {
                const currentItemToShow = itemToShow?.details?.length ? itemToShow.details.slice(c * maxDataRowInPage, (c * maxDataRowInPage) + maxDataRowInPage) : [];
                // console.log({c, details: itemToShow?.details, currentItemToShow});

                return (
                    <Page key={`do_receipt_${c}`} size="A5" style={styles.page} orientation="landscape">
                        <View style={styles.section}>
                            <View style={styles.rowStretch}>
                                <View style={{width: "100%"}}>
                                    <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start"}}>
                                        <View style={{width: "40px", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                                        {
                                            itemToShow?.logo_divisi ?
                                            <Image
                                                src={`data:${itemToShow?.tipe_file_logo_divisi};base64,${itemToShow?.logo_divisi}`}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            :
                                            null
                                        }
                                        </View>
                                        <View style={{width: "calc(100%-40px)", display: "flex", marginTop: 10, flexDirection: "column", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start", marginLeft: "5px", marginBottom: "5px"}}>
                                            <Text style={styles.headerTitle}>{itemToShow?.nama_divisi}</Text>
                                            <Text style={styles.header}>{itemToShow?.alamat_divisi}</Text>
                                            <Text style={styles.header}>{itemToShow?.kota_divisi}</Text>
                                        </View>
                                    </View>
                                    <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start"}}>
                                        <View style={{...styles.headerSection, display: "flex", flexDirection: "column", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start"}}>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>NPWP</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.npwp_divisi}</Text></Text>
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>Telp</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.telp_divisi ?? "-"}</Text></Text>
                                            </View>
                                            {/* <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>{itemToShow?.telp_divisi}Fax:</Text>
                                                <Text style={styles.headerDescriptionValue}>-</Text>
                                            </View> */}
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>Tanggal Kirim</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.tanggal_transaksi ? moment(itemToShow.tanggal_transaksi).format("DD-MM-YYYY") : "-"}</Text></Text>
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>No. Pengiriman</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.no_transaksi}</Text></Text>
                                            </View>
                                        </View>
                                        <View style={{...styles.headerSection, display: "flex", flexDirection: "column", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start"}}>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>Dicetak Oleh</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{userData?.emp_name}</Text></Text>
                                            </View>
                                            <View style={styles.col}>
                                                <Text style={styles.headerDescriptionTitle}>Tanggal Cetak</Text>
                                                <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{moment().format("DD-MM-YYYY HH:mm:ss")}</Text></Text>
                                            </View>
                                        {
                                            !(itemToShow?.dari_customer ?? false) ?
                                            <>
                                                <View style={styles.col}>
                                                    <Text style={styles.headerDescriptionTitle}>No. PO</Text>
                                                    <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.no_purchase_order ?? "-"}</Text></Text>
                                                </View>
                                                <View style={styles.col}>
                                                    <Text style={styles.headerDescriptionTitle}>No. BPB</Text>
                                                    <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.no_bukti_penerimaan_barang ?? "-"}</Text></Text>
                                                </View>
                                            </>
                                            :
                                            null
                                        }
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start", marginTop: 5}}>
                                <View style={{...styles.headerSection, display: "flex", flexDirection: "column", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start"}}>
                                    <View style={styles.col}>
                                        <Text style={styles.headerDescriptionTitle}>Pelanggan</Text>
                                        <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.nama_customer}</Text></Text>
                                    </View>
                                    <View style={styles.col}>
                                        <Text style={styles.headerDescriptionTitle}>Alamat</Text>
                                        <Text style={styles.headerDescriptionValue}>: <Text style={{fontWeight: "bold"}}>{itemToShow?.alamat_kirim}</Text></Text>
                                    </View>
                                </View>
                                <View style={{...styles.headerSection, display: "flex", flexDirection: "column", flexWrap: "no-wrap", justifyContent: "start", alignItems: "start"}}>
                                </View>
                            </View>
                            <View style={{...styles.row, marginTop: 3, paddingVertical: 3, borderTopWidth: 1}}>
                                <View style={{...styles.col, borderBottomWidth: 0.5, paddingBottom: 3, paddingHorizontal: 5,}}>
                                    <Text style={{...styles.col1, ...styles.colTitle}}>No.</Text>
                                    <Text style={{...styles.col2, ...styles.colTitle}}>No. Material</Text>
                                    <Text style={{...styles.col3, ...styles.colTitle}}>Kode Barang</Text>
                                    <Text style={{...styles.col4, ...styles.colTitle}}>Nama Barang</Text>
                                    <Text style={{...styles.col5, ...styles.colTitle}}>Qty</Text>
                                </View>
                            </View>
                            <View style={{...styles.row, borderBottomWidth: 1}}>
                            {
                                currentItemToShow.map((d, idx) => {
                                    return (
                                        <View key={`row-${idx * c * maxDataRowInPage}`} style={{...styles.col, marginVertical: 2, paddingHorizontal: 5}}>
                                            <Text style={styles.col1}>{(idx + 1) + (c * maxDataRowInPage)}.</Text>
                                            <Text style={styles.col2}>{d.kode_brg_lain ?? "-"}</Text>
                                            <Text style={styles.col3}>{d.kode_brg}</Text>
                                            <Text style={styles.col4}>{d.nama_brg}</Text>
                                            <Text style={styles.col5}>{d.qty_kirim}</Text>
                                        </View>
                                    );
                                })
                            }
                            {
                                c === (pageCountArr?.length ?? 0) - 1 ?
                                <View style={{...styles.col, paddingVertical: 3, borderTopWidth: 0.5, paddingHorizontal: 5}}>
                                    <Text style={{...styles.col1, ...styles.colTitle}}>Total</Text>
                                    <Text style={{...styles.col2, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col3, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col4, ...styles.colTitle}}></Text>
                                    <Text style={{...styles.col5, ...styles.colTitle}}>{itemToShow?.qty_total}</Text>
                                </View>
                                :
                                null
                            }
                            </View>
                        {
                            c === (pageCountArr?.length ?? 0) - 1 ?
                            <>
                                <View style={{...styles.row, marginTop: 2, paddingVertical: 10}} fixed>
                                    <View style={styles.colFooter}>
                                        <View style={styles.footer}>
                                            <Text style={styles.footerTitle}>Diberikan Oleh</Text>
                                        </View>
                                        <View style={styles.footer}>
                                            <Text style={styles.footerTitle}>Diterima Oleh</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{...styles.row, marginTop: 30, paddingVertical: 10}} fixed>
                                    <View style={styles.colFooter}>
                                        <View style={styles.footer}>
                                            <Text style={styles.footerValue}></Text>
                                        </View>
                                        <View style={styles.footer}>
                                            <Text style={styles.footerValue}></Text>
                                        </View>
                                    </View>
                                </View>
                            </>
                            :
                            null
                        }
                        </View>
                        <Text style={{textAlign: "right", paddingHorizontal: 20, paddingBottom: 10, fontSize: 10, fontStyle: "italic", color: "#666"}} render={() => (`halaman ${c + 1} dari ${pageCount}`)} fixed />
                    </Page>
                );
            })
        }
        </Document>
    );
};

function DeliveryOrderReceiptPrint(props) {
    const [id, setId] = useState(null);
    const [itemToShow, setItemToShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const { authState, apiCaller, menuState, setMenuState } = useStateContext();
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setId(searchParams.get("id"));
    }, [searchParams]);

    const loadData = useCallback(() => {
        if (!id)
            return;

        setLoading(true);
        apiCaller.get(`deliveryorders/search/receipt?id=${id}`)
            .then((response) => {
                // console.log({response});
                const {data: {data: {header, detail}}} = response;
                setItemToShow({
                    ...header,
                    details: [...detail],
                    qty_total: detail.reduce((d, d_obj) => {
                        return d + d_obj.qty_kirim;
                      }, 0),
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [id, apiCaller]);

    useEffect(() => {
        if (!id)
            return;

        loadData();
    }, [id]);

    // useEffect(() => {
    //     console.log({itemToShow});
    // }, [itemToShow]);

    useEffect(() => {
        if (menuState?.showSidebar)
            setMenuState({type: "toggle", showSidebar: false});
    }, [menuState]);

    if (!itemToShow)
        return <></>;

    const styles = StyleSheet.create({
        viewer: {
            width,
            height: height - 6,
            fontFamily: "open-sans",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
        },
        downloadButton: {
        }
    });

    return (
        <>
        {
            loading && <Loader />
        }
        {
            isMobile ?
            <Container fluid className="vh-100 d-flex flex-wrap justify-content-center align-items-center bg-light">
                <PDFDownloadLink document={<DeliveryOrderReceiptContent itemToShow={itemToShow} />} fileName={`${itemToShow.no_transaksi}-${moment(new Date()).format("YYYYMMDDHHmmss")}`}>
                {
                    ({ loading }) =>
                    (
                        // <View style={styles.viewer}>
                        // {
                        //     loading ?
                        //     <Text style={{fontStyle: "italic"}}>Loading...</Text>
                        //     :
                        //     <Text style={{fontStyle: "bold", color: "#fff", backgroundColor: "#bd1c1c", padding: 5}}>Download</Text>
                        // }
                        // </View>
                        loading ?
                        "Loading..."
                        :
                        <Button variant="print-download" size="sm-custom"><i className="fas fa-file-pdf me-2" />Download SJ</Button>
                    )
                }
                </PDFDownloadLink>
            </Container>
            :
            <PDFViewer style={styles.viewer}>
                <DeliveryOrderReceiptContent itemToShow={itemToShow} userData={authState} />
            </PDFViewer>
        }
        </>
    );
}

export default DeliveryOrderReceiptPrint;
