import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import TooltipButton from '../../../components/TooltipButton';

function Menu(props) {
    const {accessLevel} = props;
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const { apiCaller } = useStateContext();

    const handleChangeStatus = useCallback((ucode, status) => {
        setLoading(true);
        apiCaller.put(`menus/status/change?id=${ucode}&status=${status}`)
            .then(() => {
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => [
        {
            Header: "Nama",
            accessor: "nama",
        },
        {
            Header: "Status",
            id: "sts",
            accessor: (row) => row.sts ? "Aktif" : "Tidak Aktif",
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title={original.sts ? "Nonaktifkan" : "Aktifkan"}
                            size="xs-custom"
                            variant={original.sts ? "secondary" : "primary"}
                            className="me-1"
                            onClick={() => {
                                handleChangeStatus(original.ucode, original.sts ? 0 : 1);
                            }}
                        >
                            <i className={`fas fa-${original.sts ? "times" : "check"}`} />
                        </TooltipButton>
                    }
                    </InputGroup>
                )
            },
        },
    ], [accessLevel, handleChangeStatus]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="menus"
                reload={reload}
                rowColorFunction={(data) => ({backgroundColor: !data.sts ? "#efefef" : "#fff", color: !data.sts ? "#888" : "default"})}
            />
        );
    }, [reload, columns]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        </>
    );
}

export default Menu;
