import React from 'react';
import axios from 'axios';
import debounce from 'debounce-fn';
import isEqual from 'react-fast-compare';
import api from '../ApiFlexibleTemplate';

class Fetch extends React.Component {
    state = {
        data: undefined,
        loading: false,
        error: false,
    };
    
    cancelToken = null;

    componentDidMount() {
        this.fetchData()
    };

    componentDidUpdate({children: _, ...prevProps}) {
        const {children, ...props} = this.props
        if (!isEqual(prevProps, props)) {
            this.fetchData()
        }
    };

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken()
        }
    };

    makeNetworkRequest = debounce(() => {
        const {url, method = 'get', params, filterName, data, headers, authState} = this.props;
        const defaultFilterName = "filter";

        if (params[filterName ?? defaultFilterName].length < this.props.minLength) return;

        const currentFilter = JSON.parse(JSON.stringify(params));

        if (currentFilter[filterName ?? defaultFilterName].length === 0)
            delete currentFilter[filterName ?? defaultFilterName];

        api({
            config: {url,
                method,
                params: currentFilter,
                data,
                headers,
                cancelToken: new axios.CancelToken(token => {
                    this.cancelToken = token
                }),
            },
            authState,
        })
            .then(res => {
                const parsedResponse = JSON.parse(res.data);
                this.cancelToken = null
                this.setState({
                    data: parsedResponse.data,
                    loading: false,
                    error: false,
                })
            })
            .catch(e => {
                // console.error({e});
                // Early return if request was cancelled
                if (axios.isCancel(e))
                    return;

                this.setState({data: undefined, error: e, loading: false});
            });
    }, {wait: 200})

    fetchData = () => {
        if (this.cancelToken) {
            this.cancelToken()
        }

        this.setState({error: false, loading: true})

        this.makeNetworkRequest()
    }

    render() {
        const {children} = this.props
        const {data, loading, error} = this.state

        return children({
            data,
            loading,
            error,
            refetch: this.fetchData,
        })
    }
}

export default Fetch;
