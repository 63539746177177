import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import { objectWithoutProperties } from "../../../utils/Common";

function GudangEditor(props) {
    const {
        accessLevel,
        onHide,
        data,
        onSave,
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, divisi: {ucode: data?.ucode_divisi, nama: data?.nama_divisi}});
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [showAllDivision, setShowAllDivision] = useState(false);
    const { authState, apiCaller } = useStateContext();
    const [userDivision, setUserDivision] = useState(null);

    useEffect(() => {
        if (!authState?.div_code)
            return;

        setLoading(true);
        apiCaller.get(`divisions/search/code?code=${authState?.div_code}`)
            .then((response) => {
                setUserDivision({
                    ...response.data.data
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [authState?.div_code]);

    useEffect(() => {
        setShowAllDivision(accessLevel?.see_other_div ?? false);
    }, [accessLevel]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama || !itemInEdit.ucode_divisi);
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit || (!showAllDivision && !userDivision))
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "divisi",
        ])};
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`warehouses`, JSON.stringify(dataToSend)) : apiCaller.put(`warehouses/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [showAllDivision, itemInEdit, userDivision, apiCaller, onSave]);

    const onWarehouseDivisionSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_divisi: val?.ucode,
            divisi: val,
        });
    }, [itemInEdit]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <>
            <CustomModal
                show={true}
                onHide={() => onHide()}
                title="Gudang"
                titleDetails={data.ucode ? "Edit" : "Baru"}
                footer={footer}
            >
            {
                loading ? <Loader /> : null
            }
                <Container>
                    <Row className="justify-content-md-start mb-3">
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Kode:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                                name="kode"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Nama: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                                name="nama"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Alamat:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.alamat ? itemInEdit.alamat : ""}
                                name="alamat"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                        {
                            showAllDivision ?
                            <>
                                <Form.Label>
                                    Divisi: <span className="text-danger">*</span>
                                </Form.Label>
                                <ComboBox
                                    endpoint="divisions/search/name"
                                    idField="ucode"
                                    valueField="nama"
                                    isLookup={false}
                                    onChange={(val) => onWarehouseDivisionSelected(val)}
                                    selectedItem={itemInEdit?.divisi}
                                    authState={authState}
                                    minLength={0}
                                />
                            </>
                            :
                            <>
                                <Form.Label>
                                    Divisi:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={userDivision !== null && userDivision.nama ? userDivision.nama : ""}
                                    name="divisi"
                                    autoComplete="off"
                                    disabled={true}
                                />
                            </>
                        }
                        </Col>
                        <Col lg="12" className="mb-3">
                            <Form.Label>
                                Keterangan:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                                name="keterangan"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={5}
                            />
                        </Col>
                    </Row>
                </Container>
            </CustomModal>
        </>
    );
};

export default GudangEditor;
