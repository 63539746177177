export const menuInitialState = {showSidebar: false, showBackground: false};

export default function menuReducer(state, action) {
    // console.log({state, action});
    switch (action.type) {
        case 'toggle':
            return {showSidebar: action.showSidebar, showBackground: action.showBackground ?? false};
        case 'background':
            return {showSidebar: action.showSidebar, showBackground: action.showBackground};
        default:
            throw new Error();
    }
}
