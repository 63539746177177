import React, { useCallback, useMemo, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import { ComboBox } from "../../../components/Dropdowns";
import TooltipButton from "../../../components/TooltipButton";

function BarangGrupEditor(props) {
    const {
        itemName,
        initialGroups,
        onHide,
        authState,
    } = props;

    const [ selectedGroups, setSelectedGroups ] = useState(initialGroups);
    const [ selectedGroup, setSelectedGroup ] = useState(null);
    const [ reload, setReload ] = useState(0);

    const onItemGroupSelected = useCallback((val) => {
        setSelectedGroup(val ? {...val} : null);
    }, []);

    const onSelectClick = useCallback(() => {
        if (selectedGroups?.length >= 1 || !selectedGroup)
            return;

        setSelectedGroups([
            ...selectedGroups,
            {...selectedGroup},
        ]);
        setReload(reload === 1 ? 0 : 1);
    }, [selectedGroups, selectedGroup, reload]);

    const columns = useMemo(() => [
        {
            Header: "Kode",
            accessor: "kode",
        },
        {
            Header: "Nama",
            accessor: "nama",
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="outline-danger"
                            className="me-2"
                            onClick={() => {
                                const filtered = selectedGroups.filter(c => c.ucode !== original.ucode);
                                setSelectedGroups(filtered);
                                setReload(reload === 1 ? 0 : 1);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 80,
            width: 80,
        },
    ], [selectedGroups, reload]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={selectedGroups}
                reload={reload}
                maxRowCount={selectedGroups.length}
                showPager={false}
            />
        );
    }, [columns, selectedGroups, reload]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="3" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide(selectedGroups)}
                        disabled={!selectedGroups.length}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onHide, selectedGroups]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide([], true)}
            title={itemName ?? "Barang"}
            titleDetails="Update Grup Barang"
            dialogClassName="modal-lg-width"
            footer={footer}
        >
            <Container>
                <Row className="justify-content-md-start mb-3">
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Grup Barang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="itemgroups/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onItemGroupSelected(val)}
                            selectedItem={selectedGroup}
                            authState={authState}
                            minLength={0}
                            additionalButtons={[
                                (
                                    <InputGroup.Text onClick={() => onSelectClick()} className={selectedGroups?.length >= 1 ? null : "cursor-pointer bg-primary text-white"}>
                                        <i className="fas fa-arrow-down"></i>
                                    </InputGroup.Text>
                                )
                            ]}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                {
                    grid
                }
                </Row>
            </Container>
        </CustomModal>
    );
}

export default BarangGrupEditor;