import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import { ComboBox } from '../../../components/Dropdowns';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import { getCurrencyDisplay } from '../../../utils/Common';

function UmurPiutangOrderManual(props) {
    const {accessLevel} = props;
    const {authState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(0);
    const [showAllDivision, setShowAllDivision] = useState(false);
    const [userDivision, setUserDivision] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);

    useEffect(() => {
        setShowAllDivision(accessLevel?.see_other_div ?? false);
    }, [accessLevel]);

    useEffect(() => {
        // console.log({divCode: authState?.div_code});
        if (!authState?.div_code)
            return;

        setLoading(true);
        apiCaller.get(`divisions/search/code?code=${authState?.div_code}`)
            .then((response) => {
                // console.log({response});
                setUserDivision({
                    ...response.data.data
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [authState?.div_code]);

    const onDivisionSelected = useCallback((val) => {
        setSelectedDivision(val ? {...val} : null);
    }, []);

    const handleDateChanged = useCallback(({target: {name, value}}) => {
        setDateFilter(dateFilter ? {...dateFilter, [name]: value} : {[name]: value});
    }, [dateFilter]);

    const columns = useMemo(() => [
        {
            Header: "Nama Divisi",
            accessor: "nama_divisi",
        },
        {
            Header: "Nama Customer",
            accessor: "nama_customer",
        },
        {
            Header: "Alamat",
            accessor: "alamat_customer",
        },
        {
            Header: "Telp",
            accessor: "telp_customer",
        },
        {
            Header: "Kode Transaksi",
            accessor: "kode_transaksi",
        },
        {
            Header: "No. Transaksi",
            accessor: "no_transaksi",
        },
        {
            Header: "No. Sales Order",
            accessor: "no_sales_order",
        },
        {
            Header: "No. Purchase Order",
            accessor: "no_purchase_order",
        },
        {
            Header: "Nilai Transaksi",
            id: "nilai_transaksi",
            accessor: (row) => getCurrencyDisplay(row.nilai_transaksi),
            disableFilters: true,
        },
        {
            Header: "Jatuh Tempo",
            id: "tanggal_jatuh_tempo",
            accessor: (row) => moment(row.tanggal_jatuh_tempo).format("DD-MM-YYYY"),
            disableFilters: true,
            sortType: ({values: {tanggal_jatuh_tempo: a}}, {values: {tanggal_jatuh_tempo: b}}) => {
                const dateA = a !== "-" ? moment(a, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                const dateB = b !== "-" ? moment(b, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                // console.log({a, dateA, b, dateB, check: dateA < dateB});
                if(dateA < dateB)
                    return -1;
                
                if(dateA > dateB)
                    return 1;
                
                return 0;
            }
        },
        {
            Header: "<= 30 hari",
            id: "piutang_30",
            accessor: (row) => getCurrencyDisplay(row.piutang_30),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "<= 60 hari",
            id: "piutang_60",
            accessor: (row) => getCurrencyDisplay(row.piutang_60),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "<= 90 hari",
            id: "piutang_90",
            accessor: (row) => getCurrencyDisplay(row.piutang_90),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "<= 120 hari",
            id: "piutang_120",
            accessor: (row) => getCurrencyDisplay(row.piutang_120),
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "> 120 hari",
            id: "piutang_more",
            accessor: (row) => getCurrencyDisplay(row.piutang_more),
            disableFilters: true,
            disableSortBy: true,
        },
    ], []);

    const grid = useMemo(() => {
        // console.log({showAllDivision, userDivision, selectedDivision});
        return (
            <DefaultGrid
                columns={columns}
                url="reports/search/receivables/aging/manual"
                reload={reload}
                additionalParams={(selectedDivision || (!showAllDivision && userDivision)) && dateFilter?.startDate && dateFilter?.endDate ? `div_id=${selectedDivision ? selectedDivision.ucode : userDivision.ucode}&start_date=${moment(dateFilter.startDate).format("YYYY-MM-DD")}&end_date=${moment(dateFilter.endDate).format("YYYY-MM-DD")}` : ""}
                addParamChangedAutoReload={false}
            />
        );
    }, [reload, columns, showAllDivision, selectedDivision, userDivision, dateFilter]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-2 font-sm-custom">
                {
                    showAllDivision ?
                    <Col lg={3} md={12}>
                        <Form.Label>
                            Divisi: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="divisions/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onDivisionSelected(val)}
                            selectedItem={selectedDivision}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    :
                    null
                }
                    <Col lg={6} md={12}>
                        <Form.Label>
                            Periode: <span className="text-danger">*</span>
                        </Form.Label>
                        <Row className="p-0">
                            <Col lg={6} md={12}>
                                <Form.Control
                                    type="datetime-local"
                                    name="startDate"
                                    onChange={(e) => {
                                        handleDateChanged(e);
                                    }}
                                    autoComplete="off"
                                    value={dateFilter?.startDate ?? ""}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Control
                                    type="datetime-local"
                                    name="endDate"
                                    onChange={(e) => {
                                        handleDateChanged(e);
                                    }}
                                    autoComplete="off"
                                    value={dateFilter?.endDate ?? ""}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-search me-1" />Cari</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        </>
    );
}

export default UmurPiutangOrderManual;
