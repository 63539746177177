import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Inject } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, GridComponent, Resize } from "@syncfusion/ej2-react-grids";
import { componentToHex, SO_STATUS_COMPLETED, useWindowDimensions } from "../../../utils/Common";
import { comboboxEditParams, datetimeParams, defaultToolbarOptions, GridImageTemplate, numericFloatEditParams, textareaParams } from "../../../utils/Grid";
import { Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { useStateContext } from "../../../reducers";
import ModalAlert from "../../../components/ModalAlert";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import TerimaBayarPaymentProofEditor from "./TerimaBayarPaymentProofEditor";
import { ComboBox } from "../../../components/Dropdowns";

const readonlyColumns = [
    "kode_transaksi",
    "tanggal_jatuh_tempo",
    "total_piutang",
];

function TerimaBayarEditor(props) {
    const {
        onHide,
        data,
        onSave,
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, ucode_customer: data?.defaultCustomer?.value ?? data?.ucode_customer, tanggal_transaksi: data.tanggal_transaksi ? moment(data.tanggal_transaksi).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")});
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [loadCustomerDetails, setLoadCustomerDetails] = useState(data?.defaultCustomer ? true : false);
    const [selectedCustomerDetails, setSelectedCustomerDetails] = useState([]);
    const [selectedCustomerPaymentProofs, setSelectedCustomerPaymentProofs] = useState([]);
    const [shouldMatchPaymentProofs, setShouldMatchPaymentProofs] = useState([]);
    const [openPaymentEditor, setOpenPaymentEditor] = useState(false);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [errorObj, setErrorObj] = useState(null);
    const {height} = useWindowDimensions();
    const { apiCaller, authState } = useStateContext();
    const gridRef = useRef(null);

    const loadDetail = useCallback(() => {
        if (!data || !data.ucode || !data.ucode_customer)
            return;
        
        setLoading(true);
        const apiCalls = [
            apiCaller.get(`payreceives/detail/all?id=${data.ucode}`),
            apiCaller.get(`customers/search/id?id=${data.ucode_customer}`),
            apiCaller.get(`payreceives/search/custoutstanding?ucode=${data.ucode}&ucode_cust=${data.ucode_customer}`),
            apiCaller.get(`paymentproofs/search/customer?id=${data.ucode_customer}`),
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                const paymentProofs = [...responses[3].data.data];
                const receivables = [...responses[2].data.data];
                setDetails(responses[0].data.data.map(d => {
                    const currentPaid = receivables.filter(r => r.ucode_transaksi === d.ucode_transaksi)[0]?.total_terbayar ?? d.total_piutang;

                    return {
                        ...d,
                        tanggal_jatuh_tempo: moment(d.tanggal_jatuh_tempo).format("DD-MM-YYYY"),
                        tanggal_terima: d.tanggal_terima,
                        old_sub_total: d.sub_total,
                        total_terbayar: currentPaid,
                        sisa_piutang: d.total_piutang + d.sub_total - currentPaid,
                        reload: 0,
                        img: paymentProofs.filter(p => p.ucode === d.ucode_bb)?.length === 1 ? {
                            ucode: paymentProofs.filter(p => p.ucode === d.ucode_bb)[0].ucode,
                            name: paymentProofs.filter(p => p.ucode === d.ucode_bb)[0].file_name,
                            base64: paymentProofs.filter(p => p.ucode === d.ucode_bb)[0].base64,
                            created_at: paymentProofs.filter(p => p.ucode === d.ucode_bb)[0].created_at,
                        } : null,
                    }
                }));

                const { data: { data: selected_cust } } = responses[1];
                setItemInEdit({...itemInEdit, customer: selected_cust ?? null});

                setSelectedCustomerDetails([...responses[2].data.data.map(d => ({
                    ...d,
                }))]);
                setSelectedCustomerPaymentProofs(paymentProofs);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [data, apiCaller, itemInEdit]);

    useEffect(() => {
        if (!data || !data.ucode)
            return;
        
        loadDetail();
    }, [data]);

    useEffect(() => {
        // console.log({details});
        setShouldMatchPaymentProofs(details.filter(d => !d.ucode_bb).length > 0);
    }, [details]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    const autoGenerateDetails = useCallback(() => {
        // console.log({defaultCustomer});
        if (!itemInEdit?.ucode_customer)
            return;
        
        setLoading(true);

        const apiCalls = [
            apiCaller.get(`payreceives/search/custoutstanding?ucode_cust=${itemInEdit.ucode_customer}`),
            apiCaller.get(`paymentproofs/search/customer?id=${itemInEdit.ucode_customer}`),
        ]

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});
                const paymentProofs = [...responses[1].data.data];
                setDetails([...responses[0].data.data.map((d, idx) => {
                    const currentProof = paymentProofs.filter(p => p.ucode_sales_order === d.ucode_transaksi && !p.ucode_pelunasan)?.length === 1 ? paymentProofs.filter(p => p.ucode_sales_order === d.ucode_transaksi && !p.ucode_pelunasan)[0] : null;

                    return {
                        ...d,
                        ucode: idx + 1,
                        sub_total: d.total_piutang - d.total_terbayar,
                        sisa_piutang: d.total_piutang - d.total_terbayar,
                        old_sub_total: 0,
                        tanggal_jatuh_tempo: moment(d.tanggal_jatuh_tempo).format("DD-MM-YYYY"),
                        tanggal_terima: new Date(),
                        reload: 0,
                        ucode_bb: currentProof?.ucode ?? null,
                        img: currentProof ? {
                            ucode: currentProof.ucode,
                            name: currentProof.file_name,
                            base64: currentProof.base64,
                            created_at: currentProof.created_at,
                        } : null,
                    };
                })]);
                setSelectedCustomerDetails([...responses[0].data.data]);
                setSelectedCustomerPaymentProofs(paymentProofs);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [itemInEdit, apiCaller]);

    useEffect(() => {
        // console.log({loadCustomerDetails});
        if (!loadCustomerDetails)
            return;

        setLoadCustomerDetails(false);
        autoGenerateDetails();
    }, [loadCustomerDetails]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        let grand_total = 0;

        const detailToSend = details.map(d => {
            grand_total += d.sub_total;

            return {
                ucode: d.ucode,
                kode_transaksi: d.kode_transaksi,
                ucode_transaksi: d.ucode_transaksi,
                tanggal_terima: d.tanggal_terima,
                sub_total: d.sub_total,
                keterangan: d.keterangan,
                ucode_bb: d.ucode_bb,
            };
        });

        const dataToSend = {
            ucode: itemInEdit.ucode,
            ucode_customer: itemInEdit.ucode_customer,
            tanggal_transaksi: moment(itemInEdit.tanggal_transaksi).format("YYYY-MM-DD"),
            grand_total: grand_total,
            keterangan: itemInEdit.keterangan,
            details: [...detailToSend],
        };

        // console.log({itemInEdit, detailToSend, dataToSend});
        const apiCalls = [
            itemInEdit && !itemInEdit.ucode ? apiCaller.post(`payreceives`, JSON.stringify(dataToSend)) : apiCaller.put(`payreceives/${dataToSend.ucode}`, JSON.stringify(dataToSend))
        ];

        Promise.all(apiCalls)
            .then((responses) => {
                // console.log({responses});

                const apiCalls = detailToSend.map(d => {
                    return apiCaller.post(`salesorders/changestatus?id=${d.ucode_transaksi}&status=${SO_STATUS_COMPLETED}`);
                });

                Promise.all(apiCalls)
                    .then(() => {
                        setLoading(false);
                        onSave();
                    })
                    .catch((error) => {
                        console.error({error});
                        setLoading(false);
                    })
                ;
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, details, apiCaller, onSave]);

    const validate = useCallback(() => {
        if (!gridRef.current)
        {
            if (!disableSave)
                setDisableSave(true);
            
            return;
        }

        const changes = gridRef.current.getBatchChanges();
        // console.log("validate", {itemInEdit, changes, details, filteredDetails: details.filter(d =>
        //     !d.ucode_transaksi
        //     || d.ucode_transaksi === ""
        //     || !d.tanggal_terima
        //     || d.tanggal_terima === ""
        //     || !d.sub_total
        // )});
        setDisableSave(
            !itemInEdit
            || !itemInEdit.tanggal_transaksi
            || !itemInEdit.ucode_customer
            || (
                changes &&
                (
                    (changes.addedRecords && changes.addedRecords.length > 0)
                    || (changes.changedRecords && changes.changedRecords.length > 0)
                    || (changes.deletedRecords && changes.deletedRecords.length > 0)
                )
            )
            || !details.length
            || details.filter(d =>
                !d.ucode_transaksi
                || d.ucode_transaksi === ""
                || !d.tanggal_terima
                || d.tanggal_terima === ""
                || !d.sub_total
            ).length > 0
            ||
            shouldMatchPaymentProofs
        );
    }, [gridRef, itemInEdit, details, disableSave, shouldMatchPaymentProofs]);

    useEffect(() => {
        // console.log({itemInEdit});
        validate();
    }, [itemInEdit, details]);

    // useEffect(() => console.log({disableSave}), [disableSave]);
    // useEffect(() => console.log({selectedCustomerPaymentProofs}), [selectedCustomerPaymentProofs]);
    // useEffect(() => console.log({shouldMatchPaymentProofs}), [shouldMatchPaymentProofs]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Keluar
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    const onCustomerSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_customer: val?.ucode,
            customer: val,
        });
        setLoadCustomerDetails(true);
    }, [itemInEdit]);

    const onTransNoChanged = (selectedData, columnName, rowData, rowIndex) => {
        // console.log({selectedData, rowData, rowIndex});

        const setData = (isClearing = false) => {
            if (!gridRef || !gridRef.current)
                return;

            const currentRows = gridRef.current.getCurrentViewRecords();
            // console.log({currentRows});

            if (!isClearing && currentRows.filter(r => r.ucode_transaksi === selectedData.ucode_transaksi && r.kode_transaksi === selectedData.kode_transaksi && r.ucode !== rowData.ucode).length > 0)
            {
                setData(true);
                gridRef.current.updateCell(rowIndex, "no_transaksi", null);
                setErrorObj({
                    title: "Input Error!",
                    message: "Nomor transaksi tidak boleh kembar.",
                })
                return;
            }

            if (!rowData.ucode || rowData.ucode === "" || rowData.ucode === 0 || rowData.ucode === "0" || (typeof rowData.ucode === 'object'))
            {
                const rowIds = currentRows.filter(r => Number.isInteger(r.ucode))?.map(r => r.ucode) ?? [];
                rowIds.sort().reverse();
                const currentRowId = (rowIds[0] ?? 0) + 1;
                // console.log("new row id: ", {currentRowId});
                gridRef.current.updateCell(rowIndex, "ucode", currentRowId);
            }
            
            gridRef.current.updateCell(rowIndex, "ucode_transaksi", isClearing ? null : selectedData.ucode_transaksi);
            gridRef.current.updateCell(rowIndex, "kode_transaksi", isClearing ? null : selectedData.kode_transaksi);
            gridRef.current.updateCell(rowIndex, "nama_status", isClearing ? null : selectedData.nama_status);
            gridRef.current.updateCell(rowIndex, "tanggal_jatuh_tempo", isClearing ? null : moment(selectedData.tanggal_jatuh_tempo).format("DD-MM-YYYY"));
            gridRef.current.updateCell(rowIndex, "total_piutang", isClearing ? null : selectedData.total_piutang);
            gridRef.current.updateCell(rowIndex, "sub_total", isClearing ? null : selectedData.total_piutang - selectedData.total_terbayar);
        };

        setData(!selectedData || isNullOrUndefined(selectedData[columnName]) || selectedData[columnName] === "");
    }

    const noTransaksiParams = comboboxEditParams(
        gridRef,
        selectedCustomerDetails,
        {text: "no_transaksi", value: "no_transaksi"},
        onTransNoChanged
    );
    
    const tanggalJTParams = datetimeParams();

    const subTotalParams = numericFloatEditParams(gridRef, null, 0, "sisa_piutang");
    const keteranganParams = textareaParams(gridRef);

    const grid = (
        <GridComponent
            dataSource={details}
            allowResizing={true}
            editSettings={{
                mode: "Batch",
                allowAdding: itemInEdit && itemInEdit.ucode_customer,
                allowEditing: itemInEdit && itemInEdit.ucode_customer,
                allowDeleting: itemInEdit && itemInEdit.ucode_customer,
                showConfirmDialog: false,
            }}
            toolbar={defaultToolbarOptions}
            ref={(ref) => gridRef.current = ref}
            height={height >= 960 ? 230 : 150}
            beforeBatchDelete={(args) => {
                if (args.rowData.kode_status === SO_STATUS_COMPLETED)
                    args.cancel = true;
            }}
            actionComplete={(args) => {
                // console.log("action complete", {changes, args});
                // const changes = gridRef.current.getBatchChanges();

                if (args.requestType.toLowerCase() === "batchsave")
                {
                    // console.log("action complete - save", {args});
                    setDetails(args.rows.map(d => ({...d.data, reload: d.reload ? 0 : 1})));
                    return;
                }

                validate();
            }}
            batchCancel={(args) => {
                setDetails(args.rows.map(d => ({...d.data, reload: d.reload ? 0 : 1})));
                validate();
            }}
            dataBound={(params) => {
                const emptyRow = document.querySelector('.e-emptyrow');
                if (emptyRow)
                {
                    emptyRow.cells[0].innerText = "Data tidak ditemukan.";
                    return;
                }
            }}
            cellEdit={(args) => {
                if (args.columnName === 'ucode_bb' || args.columnName === 'kode_transaksi' || args.columnName === 'nama_status' || args.columnName === 'tanggal_jatuh_tempo' || args.columnName === 'total_piutang' || args.rowData.kode_status === SO_STATUS_COMPLETED) {
                    args.cancel = true;
                    return;
                }
            }}
            cellSave={(args) => {
                if (!disableSave)
                    setDisableSave(args.value !== args.previousValue);
            }}
            queryCellInfo={(args) => {
                if (readonlyColumns.includes(args.column.field)) { 
                    const val = ("255,219,219").split(",");
                    const str = "#" + componentToHex(parseInt(val[0])) + componentToHex(parseInt(val[1])) + componentToHex(parseInt(val[2]));
                    args.cell.bgColor = str;
                }
            }}
            beforeBatchAdd={(args) => {
                const gridApi = gridRef?.current;
                const checked = JSON.parse(JSON.stringify(details));

                if (gridApi)
                {
                    const {addedRecords} = gridApi.getBatchChanges();
                    
                    if (addedRecords.length)
                        addedRecords.forEach(r => {
                            checked.push({
                                ...r
                            });
                        });
                }

                const rowIds = checked.filter(r => Number.isInteger(r.ucode))?.map(r => r.ucode) ?? [];
                rowIds.sort().reverse();
                const currentRowId = (rowIds[0] ?? 0) + 1;
                args.defaultData = {
                    ...args.defaultData,
                    ucode: currentRowId,
                }
            }}
        >
            <ColumnsDirective>
                <ColumnDirective field='ucode' headerText='ID' textAlign='Left' width={120} isPrimaryKey={true} isIdentity={true} visible={false} type="string" />
                <ColumnDirective field='ucode_transaksi' headerText='SO_ID' textAlign='Left' width={120} visible={false} type="string" />
                <ColumnDirective field='no_transaksi' headerText='No. Transaksi' textAlign='Left' width={180} edit={noTransaksiParams} type="string" />
                <ColumnDirective field='ucode_bb' headerText='Bukti Pembayaran' textAlign='Left' width={150} template={GridImageTemplate} type="string" allowEditing={false} />
                <ColumnDirective field='kode_transaksi' headerText='Kode Transaksi' textAlign='Left' width={120} type="string" />
                <ColumnDirective field='nama_status' headerText='Status Transaksi' textAlign='Left' width={160} type="string" />
                <ColumnDirective field='tanggal_jatuh_tempo' headerText='Jatuh Tempo' textAlign='Left' width={120} type="string" />
                <ColumnDirective field='tanggal_terima' headerText='Tanggal Terima' textAlign='Left' width={120} edit={tanggalJTParams} type="datetime" format="dd-MM-yyyy" />
                <ColumnDirective field='total_piutang' headerText='Total Tagihan (Rp)' headerTextAlign="Left" textAlign='Right' width={140} format="N2" type="number" />
                <ColumnDirective field='sub_total' headerText='Bayar (Rp)' headerTextAlign="Left" textAlign='Right' width={100} edit={subTotalParams} format="N2" type="number" />
                <ColumnDirective field='keterangan' headerText='Keterangan' textAlign='Left' width={120} edit={keteranganParams} type="string" />
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, Resize]}/>
        </GridComponent>
    );

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Penerimaan Pembayaran"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-80"
            footer={footer}
            notes={[(<>Bayar (Rp) harus lebih dari nol.</>)]}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    No. Penerimaan Pembayaran:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.no_transaksi ? itemInEdit.no_transaksi : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Form.Label>
                            Tanggal Terima: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="date"
                            value={itemInEdit && itemInEdit.tanggal_transaksi ? itemInEdit.tanggal_transaksi : ""}
                            name="tanggal_transaksi"
                            onChange={(e) => {
                                // console.log(e.target.value);
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                    {
                        itemInEdit && itemInEdit.ucode ?
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Nama Customer:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? itemInEdit.customer.nama : null}
                                </span>
                            </Col>
                        </Row>
                        :
                        // <SearchInput
                        //     title="Customer"
                        //     valueField="ucode"
                        //     labelField="nama"
                        //     loadOptions={(val, valueField, labelField, callback) => loadDataCustomers(val, valueField, labelField, callback)}
                        //     onChange={(val) => onCustomerSelected(val)}
                        //     defaultValue={defaultCustomer}
                        //     required={true}
                        // />
                        <>
                            <Form.Label>
                                Customer: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="customers/search/name/division"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onCustomerSelected(val)}
                                selectedItem={itemInEdit?.customer}
                                authState={authState}
                                minLength={0}
                                additionalParam={{
                                    id: authState?.div_id
                                }}
                            />
                        </>
                    }
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    No. Telepon:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null && itemInEdit.telp ? itemInEdit.telp : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            {
                shouldMatchPaymentProofs && selectedCustomerPaymentProofs.filter(p => p.sts && !p.ucode_pelunasan).length > 0 &&
                <Row className="mb-3">
                    <Col lg="3" md="12">
                        <Button
                            variant="danger"
                            onClick={() => setOpenPaymentEditor(true)}
                            size="sm-custom"
                        >
                            <i className="fas fa-random me-2" />Pasangkan Bukti Pembayaran
                        </Button>
                    </Col>
                </Row>
            }
                <Row className="mb-3">
                    <Col lg="12">
                        {grid}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={3}
                        />
                    </Col>
                </Row>
            </Container>
        {
            confirmationObj ?
            <ModalAlert
                onHide={() => setConfirmationObj(null)}
                onSave={() => confirmationObj.onConfirmed()}
                title={confirmationObj.title}
                bodyText={confirmationObj.message}
            />
            :
            null
        }
        {
            errorObj ?
            <ModalAlert
                onHide={() => setErrorObj(null)}
                onSave={() => setErrorObj(null)}
                title={errorObj.title}
                bodyText={errorObj.message}
                showCancelButton={false}
                confirmButtonText="OK"
            />
            :
            null
        }
        {
            openPaymentEditor &&
            <TerimaBayarPaymentProofEditor
                onHide={() => setOpenPaymentEditor(false)}
                onSave={(data) => {
                    setLoading(true);
                    setDetails([...data]);
                    setOpenPaymentEditor(false);
                    setLoading(false);
                }}
                transactions={details.filter(d => !d.ucode_bb) ?? []}
                paymentProofs={selectedCustomerPaymentProofs.filter(p => p.sts && !p.ucode_pelunasan)}
            />
        }
        </CustomModal>
    );
};

export default TerimaBayarEditor;
