import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";

function PosisiEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState(data);
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller } = useStateContext();

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama);
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        const request = itemInEdit && !itemInEdit.ucode ? apiCaller.post(`positions`, JSON.stringify(itemInEdit)) : apiCaller.put(`positions/${itemInEdit.ucode}`, JSON.stringify(itemInEdit));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Posisi"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="justify-content-md-center mb-3">
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Kode:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                            name="kode"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                            name="nama"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={5}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default PosisiEditor;