import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { XLSX_FILE_EXTENSION, XLSX_FILE_TYPE } from '../../../utils/Common';
import HargaBrgUploadModal from './HargaBrgUploadModal';
import { ComboBox } from '../../../components/Dropdowns';
import LoaderProgress from '../../../components/LoaderProgress';

function HargaBrg(props) {
    const {accessLevel} = props;
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showAllDivision, setShowAllDivision] = useState(false);
    const [userDivision, setUserDivision] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const {authState, apiCaller} = useStateContext();
    const [downloadTemplateStatus, setDownloadTemplateStatus] = useState(null);
    const [totalData, setTotalData] = useState(0);

    useEffect(() => {
        setShowAllDivision(accessLevel?.see_other_div ?? false);
    }, [accessLevel]);

    useEffect(() => {
        // console.log({divCode: authState?.div_code});
        if (!authState?.div_code)
            return;

        setLoading(true);
        apiCaller.get(`divisions/search/code?code=${authState?.div_code}`)
            .then((response) => {
                // console.log({response});
                setUserDivision({
                    ...response.data.data
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [authState?.div_code]);

    const onDivisionSelected = useCallback((val) => {
        setSelectedDivision(val ? {...val} : null);
    }, []);

    const handleDownloadTemplate = useCallback((skip = 0, result = []) => {
        if (!totalData)
            return;

        const take = 1000;
        // console.log({
        //     skip,
        //     totalData,
        //     now: Math.round(skip / totalData * 100)
        // });

        setDownloadTemplateStatus({
            now: (Math.round(skip / totalData * 100)) >= 100 ? 99 : Math.round(skip / totalData * 100),
        });

        if (totalData - skip === 0)
        {
            const ws = XLSX.utils.json_to_sheet(result);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
            const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
            FileSaver.saveAs(data, `harga_brg_template_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
            setDownloadTemplateStatus(null);
            return;
        }

        const currentTake = skip + take > totalData ? totalData - skip : take;
        apiCaller.get(`pricelist/search/latest${selectedDivision || (!showAllDivision && userDivision) ? `?skip=${skip}&take=${currentTake}&div_id=${selectedDivision ? selectedDivision.ucode : userDivision.ucode}` : ""}`)
            .then((response) => {
                if (!response.data.data || response.data.data.length === 0)
                {
                    setTimeout(() => handleDownloadTemplate(skip + currentTake, result), 3000);
                    return;
                }

                const currentResult = response.data.data.map(d => ({
                    kode_brg: d.kode_brg,
                    nama_brg: d.nama_brg,
                    kode_satuan: d.kode_satuan,
                    nama_satuan: d.nama_satuan,
                    kode_kategori_brg: d.kode_kategori_brg,
                    nama_kategori_brg: d.nama_kategori_brg,
                    kode_group_brg: d.kode_group_brg,
                    nama_group_brg: d.nama_group_brg,
                    harga: d.harga,
                }));

                result.push(...currentResult);
                setTimeout(() => handleDownloadTemplate(skip + currentTake, result), 3000);
            })
            .catch((error) => {
                console.error({error});
                setTimeout(() => handleDownloadTemplate(skip + currentTake, result), 3000);
            })
        ;
    }, [apiCaller, totalData, loading, selectedDivision, showAllDivision, userDivision]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Nama Barang",
            accessor: "nama_brg",
        },
        {
            Header: "Kode Satuan",
            accessor: "kode_satuan",
        },
        {
            Header: "Nama Satuan",
            accessor: "nama_satuan",
        },
        {
            Header: "Kode Kategori Barang",
            accessor: "kode_kategori_brg",
        },
        {
            Header: "Nama Kategori Barang",
            accessor: "nama_kategori_brg",
        },
        {
            Header: "Kode Grup Barang",
            accessor: "kode_group_brg",
        },
        {
            Header: "Nama Grup Barang",
            accessor: "nama_group_brg",
        },
        {
            Header: "Harga",
            accessor: "harga",
            disableFilters: true,
        },
    ], []);

    const grid = useMemo(() => {
        // console.log({lastHarga});
        return (
            <DefaultGrid
                columns={columns}
                url={`pricelist/search/latest`}
                reload={reload}
                additionalParams={selectedDivision || (!showAllDivision && userDivision) ? `div_id=${selectedDivision ? selectedDivision.ucode : userDivision.ucode}` : ""}
                onTotalDataChanged={(total) => setTotalData(total)}
            />
        );
    }, [reload, columns, showAllDivision, selectedDivision, userDivision]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
        {
            downloadTemplateStatus ? <LoaderProgress now={downloadTemplateStatus.now} /> : null
        }
        {
            showUploadModal ?
            <HargaBrgUploadModal
                div_id={selectedDivision ? selectedDivision.ucode : userDivision.ucode}
                onHide={() => setShowUploadModal(false)}
                onSave={() => {
                    setShowUploadModal(false);
                    setReload(reload ? 0 : 1);
                }}
            />
            :
            null
        }
            <Container fluid>
            {
                showAllDivision ?
                <Row className="mb-2 font-sm-custom">
                    <Col lg={3} md={12}>
                        <Form.Label>
                            Divisi: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="divisions/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onDivisionSelected(val)}
                            selectedItem={selectedDivision}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                </Row>
                :
                null
            }
                <Row className="mb-3 font-sm-custom">
                    <Col xs={12} className="d-flex flex-wrap justify-content-start align-items-center">
                        <InputGroup>
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => handleDownloadTemplate()} disabled={!(selectedDivision || (!showAllDivision && userDivision))}><i className="fas fa-file-download me-1" />Download Template</Button>
                        {
                            (accessLevel.can_add && accessLevel.can_edit > 0) ?
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => setShowUploadModal(true)} disabled={!(selectedDivision || (!showAllDivision && userDivision))}><i className="fas fa-file-upload me-1" />Upload Harga Barang</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="primary" className="me-1" onClick={() => setReload(reload ? 0 : 1)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                </Row>
                {
                    grid
                }
            </Container>
        </>
    );
}

export default HargaBrg;
