import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import Loader from "../../../components/Loader";
import ModalAlert from "../../../components/ModalAlert";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";

const MESSAGE_OBJ_CONFIRM = "Confirmation";
const MESSAGE_OBJ_WARNING = "Warning";

function CustomerUserEditor(props) {
    const {
        customer,
        onHide,
    } = props;

    const [ loading, setLoading ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ messageObj, setMessageObj ] = useState(null);
    const { apiCaller } = useStateContext();
    const [ refs, setRefs ] = useState([]);

    const loadData = useCallback(() => {
        if (!customer?.ucode)
            return;

        setLoading(true);
        apiCaller.get(`customers/search/users/id?id=${customer.ucode}`)
            .then((response) => {
                const {data: {data: users}} = response;
                // console.log({data, response, users});
                setUsers([...users.map(c => ({
                    ...c,
                    edit_mode: false,
                }))]);

                setRefs(users.map(c => ({
                    ucode: c.ucode,
                    ucode_customer: c.ucode_customer,
                    usernameRef: createRef(),
                })));
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, customer]);

    useEffect(() => {
        if (!customer?.ucode)
            return;

        loadData();
    }, [customer]);

    const handleSave = useCallback((user) => {
        setLoading(true);

        const ref = refs.filter(r => r.ucode === user.ucode)[0];
        const dataToSend = {
            ucode_customer: user.ucode_customer,
        }
        dataToSend.username = ref?.usernameRef.current?.value;

        if (!dataToSend?.username || dataToSend.username === "")
        {
            setMessageObj({
                user,
                type: MESSAGE_OBJ_WARNING,
                title: "Penyimpanan Data Gagal",
                message: "Username harus diisi.",
                isConfirmation: false,
            })
            setLoading(false);
            return;
        }

        if (!Number.isInteger(user.ucode))
            dataToSend.ucode = user.ucode;

        // console.log({user, dataToSend, ref, refCurrent: ref?.usernameRef.current});

        apiCaller.post(`customers/user/generate?id=${customer.ucode}`, JSON.stringify(dataToSend))
            .then(() => {
                setMessageObj(null);
                setLoading(false);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [refs, apiCaller, customer, loadData]);

    const handleResetPassword = useCallback((user) => {
        setLoading(true);

        if (!user?.username || user.username === "")
        {
            setMessageObj({
                user,
                type: MESSAGE_OBJ_WARNING,
                title: "Reset Password Gagal",
                message: "Username harus diisi.",
                isConfirmation: false,
            })
            setLoading(false);
            return;
        }

        // console.log({user});

        apiCaller.post(`customers/user/resetpassword?id=${user.ucode}&username=${user.username}`)
            .then(() => {
                setMessageObj(null);
                setLoading(false);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, loadData]);

    const handleDeleteUser = useCallback((user) => {
        setLoading(true);

        if (!user?.ucode || Number.isInteger(user.ucode))
        {
            setMessageObj({
                user,
                type: MESSAGE_OBJ_WARNING,
                title: "Penghapusan User Gagal",
                message: "Data tidak valid.",
                isConfirmation: false,
            })
            setLoading(false);
            return;
        }

        // console.log({user});

        apiCaller.delete(`customers/user/${user.ucode}`)
            .then(() => {
                setMessageObj(null);
                setLoading(false);
                loadData();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, loadData]);

    const columns = useMemo(() => [
        {
            Header: "Username",
            id: "username",
            Cell: ({row: {original}}) => {
                const usernameRef = refs.filter(r => r.ucode === original.ucode)[0]?.usernameRef;

                if (original.edit_mode)
                    return (
                        <Form.Control
                            type="text"
                            id={`kode_${original.ucode}`}
                            name={`kode_${original.ucode}`}
                            autoComplete="off"
                            ref={usernameRef}
                        />
                    );
                
                return (
                    <Form.Control
                        type="text"
                        id={`nama_${original.ucode}`}
                        name={`nama_${original.ucode}`}
                        value={original.username ?? ""}
                        autoComplete="off"
                        ref={usernameRef}
                        disabled={true}
                    />
                );
            },
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Reset Password"
                            size="xs-custom"
                            variant="dark"
                            onClick={() => {
                                setMessageObj({
                                    user: original,
                                    type: MESSAGE_OBJ_CONFIRM,
                                    title: "Konfirmasi Reset Password",
                                    message: `Apakah Anda yakin akan mereset password user ${original.username}?`,
                                    isConfirmation: true,
                                    confirmAction: () => handleResetPassword(original),
                                });
                            }}
                            disabled={original.edit_mode || !original.username || original.username === ""}
                        >
                            <i className="fas fa-sync" />
                        </TooltipButton>
                        <TooltipButton
                            title={original.edit_mode ? "Simpan" : "Edit"}
                            size="xs-custom"
                            variant={original.edit_mode ? "success" : "primary"}
                            className="ms-1"
                            onClick={() => {
                                if (original.edit_mode)
                                {
                                    handleSave(original);
                                    return;
                                }

                                const currentUsers = JSON.parse(JSON.stringify(users));
                                const currentUser = currentUsers.filter(c => c.ucode === original.ucode)[0];
                                currentUser.edit_mode = true;
                                const ref = refs.filter(r => r.ucode === original.ucode)[0];
                                currentUser.username = ref?.usernameRef.current?.value;
                                setUsers([...currentUsers]);
                            }}
                        >
                            <i className={`fas fa-${original.edit_mode ? "save" : "edit"}`} />
                        </TooltipButton>
                    {
                        original.edit_mode ?
                        <TooltipButton
                            title="Batal"
                            size="xs-custom"
                            variant="danger"
                            className="ms-1"
                            onClick={() => {
                                const currentUsers = JSON.parse(JSON.stringify(users));
                                const currentUser = currentUsers.filter(c => c.ucode === original.ucode)[0];
                                currentUser.edit_mode = false;
                                setUsers([...currentUsers]);
                            }}
                        >
                            <i className="fas fa-times" />
                        </TooltipButton>
                        :
                        null
                    }
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="danger"
                            className="ms-1"
                            onClick={() => {
                                if (Number.isInteger(original.ucode))
                                {
                                    const currentUsers = JSON.parse(JSON.stringify(users));
                                    const currentUser = currentUsers.filter(c => c.ucode === original.ucode)[0];
                                    // console.log({currentUser, index: currentUsers.indexOf(currentUser)});
                                    currentUsers.splice(currentUsers.indexOf(currentUser), 1);
                                    // console.log({currentUsers});
                                    setUsers([...currentUsers]);
                                    return;
                                }

                                setMessageObj({
                                    user: original,
                                    type: MESSAGE_OBJ_WARNING,
                                    title: "Konfirmasi Penghapusan Data",
                                    message: `Apakah Anda yakin akan menghapus user ${original.username}?`,
                                    isConfirmation: true,
                                    confirmAction: () => handleDeleteUser(original),
                                });
                            }}
                            disabled={original.edit_mode}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </InputGroup>
                )
            },
            maxWidth: 80,
            width: 80,
        },
    ], [users, refs, handleSave, handleResetPassword, handleDeleteUser]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={users}
                maxRowCount={users.length}
            />
        );
    }, [columns, users]);

    const onAddRowClick = useCallback(() => {
        const newUsers = JSON.parse(JSON.stringify(users));
        const intNodes = newUsers.filter(u => Number.isInteger(u.ucode));

        if (intNodes?.length)
        {
            intNodes.sort((a, b) => a.ucode - b.ucode);
            intNodes.reverse();
        }
        const ucode = !intNodes?.length ? 1 : intNodes[0].ucode + 1;

        newUsers.forEach(u => {
            u.edit_mode = false;
        });

        newUsers.push({
            ucode,
            ucode_customer: customer.ucode,
            username: null,
            edit_mode: true,
        });

        setUsers([...newUsers]);
        setRefs([...newUsers.map(c => ({
            ucode: c.ucode,
            ucode_customer: c.ucode_customer,
            usernameRef: createRef(),
        }))])
    }, [customer, users]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="3" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="dark"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide(users)}
                        size="sm-custom"
                    >
                        Keluar
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onHide, users]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide([], true)}
            title={customer?.nama ?? "Customer"}
            titleDetails="Update Data User"
            dialogClassName="modal-lg-width"
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="justify-content-md-start">
                    <Col lg="6" md="12" className="mb-3">
                        <Button onClick={() => onAddRowClick()}>
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                {
                    grid
                }
                </Row>
            </Container>
        {
            messageObj ?
            <ModalAlert
                onHide={() => setMessageObj(null)}
                onSave={() => {
                    if (!messageObj.isConfirmation)
                    {
                        setMessageObj(null);
                        return;
                    }

                    if (messageObj.confirmAction)
                        messageObj.confirmAction();
                }}
                title={messageObj.title}
                bodyText={messageObj.message}
                showCancelButton={messageObj.isConfirmation}
            />
            :
            null
        }
        </CustomModal>
    );
}

export default CustomerUserEditor;