import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { maxFileSize } from '../utils/Common';

function Dropzone(props) {
    const {
        dropText,
        setBinaryStr,
        accept,
        onError,
        maxFileCount,
    } = props;

    const [ isFileSizeLarger, setIsFileSizeLarger ] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
      
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => onError();
            reader.onload = () => {
                const binaryStr = reader.result;
                setBinaryStr(binaryStr, file.path);
            };
            reader.readAsDataURL(file);
        })
    }, [setBinaryStr, onError]);

    const onDropRejected = useCallback((files) => {
        // console.log(files);
        // console.log(files.length > 0 && files[0].file.size > maxFileSize);
        setIsFileSizeLarger(files.length > 0 && files[0].file.size > maxFileSize);
    }, []);

    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({maxFiles: maxFileCount, maxSize: maxFileSize, accept: accept, onDrop, onDropRejected});
    
    return (
        <Container fluid>
            <Row>
                <Col>
                    <span style={{fontSize: "0.75rem"}}><i>Max ukuran file: {maxFileSize > 1000000 ? `${maxFileSize / (1024 * 1024)} MB` : `${maxFileSize / 1024} KB`}</i></span>
                </Col>
            </Row>
            <Row>
                <Col
                    {...getRootProps()}
                    className="w-100 p-0 m-0 d-flex flex-wrap align-items-center border rounded"
                    style={{cursor: "pointer", height: 100, backgroundColor: "#e9ecef"}}
                >
                    <input {...getInputProps()} className="w-100 h-100" />
                    {
                        !isFileSizeLarger && isDragActive && !isDragReject ?
                        <span className="w-100 text-center"><i>Letakkan file di sini.</i></span> :
                        (
                            !isFileSizeLarger && !isDragActive ?
                            <span className="w-100 text-center"><i>
                            {
                                dropText ?? "Letakkan file di sini."
                            }
                            </i></span>
                            :
                            null
                        )
                    }
                    {
                        !isFileSizeLarger && isDragReject ?
                        <span className="w-100 text-center"><i>Tipe file tidak sesuai.</i></span>
                        :
                        null
                    }
                    {
                        isFileSizeLarger ?
                        <span className="w-100 text-center"><i>Ukuran file terlalu besar.</i></span>
                        :
                        null
                    }
                </Col>
            </Row>
        </Container>
    );
}

Dropzone.defaultProps = {
    maxFileCount: 1
}

export default Dropzone;
