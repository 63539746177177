import React from "react";
import GlobalMasters from "../pages/Global/Masters";
import StockMasters from "../pages/Stock/Masters";
import AdminMasters from "../pages/Admin/Masters";
import Satuan from "../pages/Stock/Masters/Satuan";
import GrupBarang from "../pages/Stock/Masters/GrupBarang";
import Barang from "../pages/Stock/Masters/Barang";
import StockTransactions from "../pages/Stock/Transactions";
import StokOpname from "../pages/Stock/Transactions/StokOpname";
import SalesMasters from "../pages/Sales/Masters";
import GrupCustomer from "../pages/Sales/Masters/GrupCustomer";
import Customer from "../pages/Sales/Masters/Customer";
import SalesTransactions from "../pages/Sales/Transactions";
import SalesOrder from "../pages/Sales/Transactions/SalesOrder";
import SuratJalan from "../pages/Sales/Transactions/SuratJalan";
import TerimaBayar from "../pages/Sales/Transactions/TerimaBayar";
import ReportReceivables from "../pages/Reports/Receivables";
import ReportStock from "../pages/Reports/Stocks";
import UmurPiutang from "../pages/Reports/Receivables/UmurPiutang";
import KategoriBarang from "../pages/Stock/Masters/KategoriBarang";
import Divisi from "../pages/Global/Masters/Divisi";
import Posisi from "../pages/Global/Masters/Posisi";
import Karyawan from "../pages/Global/Masters/Karyawan";
import User from "../pages/Admin/Masters/User";
import MenuTab from "../pages/Admin/Masters/Menu";
import Role from "../pages/Admin/Masters/Role";
import SalesPricelist from "../pages/Sales/Pricelists";
import HargaBrg from "../pages/Sales/Pricelists/HargaBrg";
import Gudang from "../pages/Stock/Masters/Gudang";
import KondisiStok from "../pages/Reports/Stocks/KondisiStok";
import ChangePassword from "../pages/Auth/ChangePassword";
import StokAvailable from "../pages/Reports/Stocks/StokAvailable";
import SysUser from "../pages/Admin/Masters/SysUser";
import UmurPiutangOrderManual from "../pages/Reports/Receivables/UmurPiutangOrderManual";

export const Menu = [
    {
        group: "Profile",
        subgroup: "Ubah Password",
        title: "Ubah Password",
        path: "/change-password",
        component: <ChangePassword />,
        icon: "fas fa-key",
        children: []
    },
    // {
    //     group: "Profile",
    //     subgroup: "Notifikasi",
    //     title: "Notifikasi",
    //     path: "/notification",
    //     component: <></>,
    //     icon: "far fa-bell",
    //     children: []
    // },
    {
        group: "Profile",
        subgroup: "Logout",
        title: "Logout",
        path: "/logout",
        component: <></>,
        icon: "fas fa-sign-out-alt",
        children: []
    },
    {
        group: "Umum",
        subgroup: "Master",
        title: "Umum",
        path: "/global-master",
        component: <GlobalMasters />,
        icon: "fas fa-network-wired",
        children: [
            {
                title: "Divisi",
                component: (props) => <Divisi {...props} />,
                code: "GLOBAL-DIVISI",
            },
            {
                title: "Posisi",
                component: (props) => <Posisi {...props} />,
                code: "GLOBAL-POSISI",
            },
            {
                title: "Karyawan",
                component: (props) => <Karyawan {...props} />,
                code: "GLOBAL-KARYAWAN",
            },
        ]
    },
    {
        group: "Stok",
        subgroup: "Master Barang",
        title: "Master Barang",
        path: "/stok-master",
        component: <StockMasters />,
        icon: "fas fa-box-open",
        children: [
            {
                title: "Barang",
                component: (props) => <Barang {...props} />,
                code: "STOK-BRG",
            },
            {
                title: "Satuan",
                component: (props) => <Satuan {...props} />,
                code: "STOK-SATUAN",
            },
            {
                title: "Grup Barang",
                component: (props) => <GrupBarang {...props} />,
                code: "STOK-GRP-BRG",
            },
            {
                title: "Kategori Barang",
                component: (props) => <KategoriBarang {...props} />,
                code: "STOK-KTG-BRG",
            },
            {
                title: "Gudang",
                component: (props) => <Gudang {...props} />,
                code: "STOK-GUDANG",
            },
        ]
    },
    {
        group: "Stok",
        subgroup: "Stok Sparepart",
        title: "Stok Sparepart",
        path: "/stok-transaksi",
        component: <StockTransactions />,
        icon: "fas fa-layer-group",
        children: [
            {
                title: "Opname",
                component: (props) => <StokOpname {...props} />,
                code: "STOK-OPNAME",
            },
        ]
    },
    {
        group: "Penjualan",
        subgroup: "Master Langganan",
        title: "Master Langganan",
        path: "/sales-master",
        component: <SalesMasters />,
        icon: "fas fa-store",
        children: [
            {
                title: "Customer",
                component: (props) => <Customer {...props} />,
                code: "SALES-CUSTOMER",
            },
            {
                title: "Grup Customer",
                component: (props) => <GrupCustomer {...props} />,
                code: "SALES-GRP-CUST",
            },
        ]
    },
    {
        group: "Penjualan",
        subgroup: "Transaksi Penjualan",
        title: "Transaksi Penjualan",
        path: "/sales-transaksi",
        component: <SalesTransactions />,
        icon: "fas fa-shopping-cart",
        children: [
            {
                title: "Sales Order",
                component: (props) => <SalesOrder {...props} />,
                code: "SALES-SO",
            },
            {
                title: "Surat Jalan",
                component: (props) => <SuratJalan {...props} />,
                code: "SALES-DO",
            },
            {
                title: "Penerimaan Pembayaran",
                component: (props) => <TerimaBayar {...props} />,
                code: "SALES-PP",
            },
        ]
    },
    {
        group: "Penjualan",
        subgroup: "Price List",
        title: "Price List",
        path: "/sales-pricelist",
        component: <SalesPricelist />,
        icon: "fas fa-tags",
        children: [
            {
                title: "Price List",
                component: (props) => <HargaBrg {...props} />,
                code: "SALES-HARGA",
            },
        ]
    },
    {
        group: "Laporan",
        subgroup: "Laporan Piutang",
        title: "Laporan Piutang",
        path: "/laporan-piutang",
        component: <ReportReceivables />,
        icon: "fas fa-file-invoice-dollar",
        children: [
            {
                title: "Laporan Umur Piutang",
                component: (props) => <UmurPiutang {...props} />,
                code: "SALES-LAP-AGING-AR",
            },
            {
                title: "Laporan Umur Piutang DO",
                component: (props) => <UmurPiutangOrderManual {...props} />,
                code: "SALES-LAP-AGING-AR-MANUAL",
            },
        ]
    },
    {
        group: "Laporan",
        subgroup: "Laporan Stok",
        title: "Laporan Stok",
        path: "/laporan-stok",
        component: <ReportStock />,
        icon: "fas fa-boxes",
        children: [
            {
                title: "Laporan Kondisi Stok",
                component: (props) => <KondisiStok {...props} />,
                code: "STOK-LAP-KONDISI",
            },
            {
                title: "Laporan Stok Available",
                component: (props) => <StokAvailable {...props} />,
                code: "STOK-LAP-SALESORDER-AVAILABLE",
            },
        ]
    },
    {
        group: "User",
        subgroup: "Master",
        title: "User",
        path: "/admin-master",
        component: <AdminMasters />,
        icon: "fas fa-users-cog",
        children: [
            {
                title: "User",
                component: (props) => <User {...props} />,
                code: "ADMIN-USER",
            },
            {
                title: "Menu",
                component: (props) => <MenuTab {...props} />,
                code: "ADMIN-MENU",
            },
            {
                title: "Role",
                component: (props) => <Role {...props} />,
                code: "ADMIN-ROLE",
            },
            {
                title: "User Sistem",
                component: (props) => <SysUser {...props} />,
                code: "ADMIN-USER-SISTEM",
            },
        ]
    },
];