import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";

export const SO_STATUS_PROCESS = "SO-PROCESS";
export const SO_STATUS_PROCESS_NAME = "Proses";
export const SO_STATUS_CONFIRMED = "SO-CONFIRMED";
export const SO_STATUS_CANCELED = "SO-CANCELED";
export const SO_STATUS_PACKING = "SO-PACKING";
export const SO_STATUS_SENDING = "SO-SENDING";
export const SO_STATUS_DELIVERED = "SO-DELIVERED";
export const SO_STATUS_WP = "SO-WP";
export const SO_STATUS_COMPLETED = "SO-COMPLETED";
export const SJ_STATUS_NEW = "SJ-NEW";
export const SJ_STATUS_NEW_NAME = "Baru";
export const SJ_STATUS_CONFIRMED = "SJ-CONFIRMED";
export const SJ_STATUS_CANCELED = "SJ-CANCELED";
export const SJ_STATUS_SENDING = "SJ-SENDING";
export const SJ_STATUS_DELIVERED = "SJ-DELIVERED";
export const SHOWN_ERROR_CODE = 599;
export const XLSX_FILE_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const XLSX_FILE_TYPE_DROPZONE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const XLSX_FILE_EXTENSION = ".xlsx";
export const PNG_FILE_TYPE_DROPZONE = "image/png";
export const PNG_FILE_EXTENSION = ".png";
export const JPG_FILE_TYPE_DROPZONE = "image/jpg";
export const JPG_FILE_EXTENSION = ".jpg";
export const JPEG_FILE_TYPE_DROPZONE = "image/jpeg";
export const JPEG_FILE_EXTENSION = ".jpeg";
export const maxFileSize = 5242880;
export const maxRowUpload = 10000;
export const maxRowCountOnSingleProcess = 50;


export function groupBy(items, key) {
    return items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }), 
    {},
    )
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    // console.log({width, height});
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export function getCurrencyDisplay(value) {
    const localeSetting = `${process.env.REACT_APP_LOCALE_LANG}-${process.env.REACT_APP_LOCALE_COUNTRY}`;
    // console.log({localeSetting});
    return Intl.NumberFormat(localeSetting, {style: "currency", currency: process.env.REACT_APP_LOCALE_CURRENCY}).format(value);
}

export function getDecimalDisplay(value) {
    const localeSetting = `${process.env.REACT_APP_LOCALE_LANG}-${process.env.REACT_APP_LOCALE_COUNTRY}`;
    // console.log({localeSetting});
    return Intl.NumberFormat(localeSetting, {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value.toFixed(2));
}

export function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function wordings(value)
{
    value = Math.abs(value);
    const singular = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan", "sepuluh", "sebelas"];

    if (value < 12)
        return `${value === 0 ? "" : " "}${singular[Math.floor(value)]}`;
    
    if (value < 20)
        return `${wordings(value - 10)} belas`;
    
    if (value < 100)
        return `${wordings(value / 10)} puluh${wordings(value % 10)}`;
    
    if (value < 200)
        return ` seratus${wordings(value - 100)}`;
    
    if (value < 1000)
        return `${wordings(value / 100)} ratus${wordings(value % 100)}`;
    
    if (value < 2000)
        return ` seribu${wordings(value - 1000)}`;
    
    if (value < 1000000)
        return `${wordings(value / 1000)} ribu${wordings(value % 1000)}`;
    
    if (value < 1000000000)
        return `${wordings(value / 1000000)} juta${wordings(value % 1000000)}`;
    
    if (value < 1000000000000)
        return `${wordings(value / 1000000000)} milyar${wordings(value % 1000000000)}`;
    
    if (value < 1000000000000000)
        return `${wordings(value / 1000000000000)} trilyun${wordings(value % 1000000000000)}`;
}

export function numberToWords(value)
{
    if (value < 0)
        return `minus ${wordings(value).trim()}`;
    
    return wordings(value).trim();
}

export const exportPdf = (target, gridId, name, orientation, pagesize, debug) => {
    document.querySelector(target).style.width = orientation === "p" ? (pagesize === "a4" ? "210mm" : "184.5mm") : (pagesize === "a4" ? "297mm" : "210mm");
    document.querySelector(target).style.height = orientation === "p" ? (pagesize === "a4" ? "297mm" : "210mm") : (pagesize === "a4" ? "210mm" : "184.5mm");

    const pdf = new jsPDF(orientation, "pt", pagesize);
    pdf.html(document.querySelector(target), {
        callback: () => {
            debug ? window.open(pdf.output("bloburl")) : pdf.save(`${name}.pdf`);
            document.querySelector(gridId).className = "table";
        },
    });
};
  
export function separateBase64String(b64) {
    const splitted = b64.split(",");
    const mimeType = splitted[0].split(";")[0].split(":")[1];
    const base64Str = splitted[1];
    return {
        mimeType: mimeType,
        base64Str: base64Str
    };
}

export function objectWithoutProperties(obj, exceptionKeys) {
    var target = {};
    for (var i in obj) {
        if (exceptionKeys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

export function dateLocalToUtc(date, format) {
    const timezoneOffset = (new Date()).getTimezoneOffset();
    return moment(date).add(timezoneOffset, "m").format(format);
}

export function escapeRegExp(value) {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}