import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import { objectWithoutProperties } from "../../../utils/Common";
import CustomerGudangEditor from "./CustomerGudangEditor";

function CustomerEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, group_cust: {ucode: data?.ucode_group_cust, kode: data?.kode_group_cust, nama: data?.nama_group_cust}});
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [showWarehouseEditor, setShowWarehouseEditor] = useState(false);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const { apiCaller, authState } = useStateContext();

    const loadDetail = useCallback(() => {
        if (!data.ucode)
            return;

        setLoading(true);

        apiCaller.get(`customers/search/detail/id?id=${data.ucode}`)
            .then((response) => {
                const {data: {data: {warehouses}}} = response;
                // console.log({data, response, warehouses});

                setSelectedWarehouses([...warehouses.map(g => ({
                    ucode_gudang: g.ucode,
                    kode_gudang: g.kode,
                    nama_gudang: g.nama,
                }))]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [data, apiCaller]);

    useEffect(() => {
        if (!data?.ucode)
            return;
        
        loadDetail();
    }, [data]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: !value || value.trim() === "" ? null : value});
    }, [itemInEdit]);

    const handleTextNumberChanged = useCallback(({target: {value, name}}) => {
        const parsedValue = parseInt(value);
        
        if (isNaN(parsedValue))
        {
            setItemInEdit({...itemInEdit, [name]: null});
            return;
        }

        setItemInEdit({...itemInEdit, [name]: parsedValue});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama || !itemInEdit.ucode_group_cust || !itemInEdit.npwp || !itemInEdit.jatuh_tempo || !itemInEdit.alamat || !itemInEdit.alamat_kirim);
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "divisi",
            "kode_divisi",
            "nama_divisi",
            "group_cust",
            "group_divisi",
            "group_divisi",
        ])};
        dataToSend.gudang_list = [...selectedWarehouses.map(g => ({ucode_gudang: g.ucode_gudang}))];
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`customers`, JSON.stringify(dataToSend)) : apiCaller.put(`customers/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, selectedWarehouses, apiCaller, onSave]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-between">
                <Col lg={8} md={12} className="d-flex flex-wrap justify-content-md-start m-0 p-0 px-1">
                    <Button
                        variant="primary"
                        type="submit"
                        className="me-1"
                        onClick={() => setShowWarehouseEditor(true)}
                        size="sm-custom"
                    >
                        Gudang
                    </Button>
                </Col>
                <Col lg={4} md={12} className="d-flex flex-wrap justify-content-md-end m-0 p-0 px-1">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="me-1"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                    <Button
                        variant="success"
                        type="submit"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    const onCustomerGroupSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_group_cust: val?.ucode,
            group_cust: val,
        });
    }, [itemInEdit]);

    return (
        <>
        {
            !showWarehouseEditor &&
            <CustomModal
                show={true}
                onHide={() => onHide()}
                title="Customer"
                titleDetails={data.ucode ? "Edit" : "Baru"}
                dialogClassName="modal-w-80"
                footer={footer}
            >
            {
                loading ? <Loader /> : null
            }
                <Container fluid>
                    <Row className="mb-3">
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Kode Toko:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                                name="kode"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                disabled={true}
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Nama Toko: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                                name="nama"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Grup Customer: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="customergroups/search/name"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onCustomerGroupSelected(val)}
                                selectedItem={itemInEdit?.group_cust}
                                authState={authState}
                                minLength={0}
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Telepon:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.telp ? itemInEdit.telp : ""}
                                name="telp"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Email:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.email ? itemInEdit.email : ""}
                                name="email"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                NPWP/KTP: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.npwp ? itemInEdit.npwp : ""}
                                name="npwp"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Jatuh Tempo (hari): <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="number"
                                value={itemInEdit !== null && itemInEdit.jatuh_tempo ? itemInEdit.jatuh_tempo : ""}
                                name="jatuh_tempo"
                                onChange={(e) => {
                                    handleTextNumberChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Punya Kode/Nama Barang Sendiri:
                            </Form.Label>
                            <Form.Check
                                className="cursor-pointer"
                                onChange={({target: {checked}}) => setItemInEdit({...itemInEdit, punya_kode_brg_sendiri: checked})}
                                checked={itemInEdit?.punya_kode_brg_sendiri}
                            />
                        </Col>
                        <Col lg="4" md="12" className="mb-3">
                            <Form.Label>
                                Customer Spesial:
                            </Form.Label>
                            <Form.Check
                                className="cursor-pointer"
                                onChange={({target: {checked}}) => setItemInEdit({...itemInEdit, spesial_cust: checked})}
                                checked={itemInEdit?.spesial_cust}
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Alamat NPWP/KTP: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.alamat ? itemInEdit.alamat : ""}
                                name="alamat"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={5}
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Alamat Kirim: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.alamat_kirim ? itemInEdit.alamat_kirim : ""}
                                name="alamat_kirim"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={5}
                            />
                        </Col>
                        <Col lg="12" className="mb-3">
                            <Form.Label>
                                Keterangan:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                                name="keterangan"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={5}
                            />
                        </Col>
                    </Row>
                </Container>
            </CustomModal>
        }
        {
            showWarehouseEditor &&
            <CustomerGudangEditor
                divisionId={itemInEdit?.ucode_divisi ?? authState?.div_id}
                customerName={itemInEdit?.nama}
                initialWarehouses={selectedWarehouses}
                onHide={(selectedWarehouses, isReset = false) => {
                    setShowWarehouseEditor(false);

                    if (!isReset)
                        setSelectedWarehouses(selectedWarehouses);
                }}
                authState={authState}
            />
        }
        </>
    );
};

export default CustomerEditor;