import Axios from 'axios';

const axiosInstance = Axios.create({
    baseURL: `${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}/`
});

axiosInstance.interceptors.request.use(
    async ({config, authState}) => {
        if (authState && authState.username && authState.username !== "")
            config.headers.sender = authState.username;

        if (authState && authState.token && authState.token !== "")
            config.headers["Authorization"] = `Bearer ${authState.token}`;

        if (authState && authState.div_code && authState.div_code !== "")
            config.headers["div"] = authState.div_code;

        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
);

export default axiosInstance;