import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";

const PRINT_TYPE_NEW = "New";
const PRINT_TYPE_EXISTING = "Existing";

function SalesOrderDeliveryOrderPrintDialog(props) {
    const {
        id: ucode_sales_order,
        onClose,
    } = props;

    const [salesOrder, setSalesOrder] = useState(null);
    const [selectedDeliveryOrder, setSelectedDeliveryOrder] = useState(null);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [isAfterGenerate, setIsAfterGenerate] = useState(null);
    const [transactionDate, setTransactionDate] = useState(null);
    const [selectedPrintType, setSelectedPrintType] = useState(null);
    const [availableSJ, setAvailableSJ] = useState([]);
    const [loading, setLoading] = useState(false);
    const { apiCaller, authState } = useStateContext();
    const [ reload, setReload ] = useState(0);

    const getData = useCallback(() => {
        if (!ucode_sales_order)
            return;

        setLoading(true);

        apiCaller.get(`salesorders/search/id?id=${ucode_sales_order}`)
            .then((response) => {
                const {data: {data}} = response;
                setSalesOrder({...data});
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, ucode_sales_order]);

    useEffect(() => {
        if (!ucode_sales_order)
            return;

        getData();
    }, [ucode_sales_order]);

    const loadSJ = useCallback(() => {
        if (!selectedPrintType || (selectedPrintType === PRINT_TYPE_EXISTING && !selectedDeliveryOrder))
        {
            setAvailableSJ([]);
            return;
        }

        setLoading(true);
        const queryParam = selectedPrintType === PRINT_TYPE_NEW ? `?id=${ucode_sales_order}` : `?delivery_order_id=${selectedDeliveryOrder.ucode}`;

        apiCaller.get(`salesorders/customer/deliveryorders/available${queryParam}`)
            .then((response) => {
                // console.log({response});
                setAvailableSJ([...response.data.data]);
                setReload(reload === 1 ? 0 : 1);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, ucode_sales_order, selectedDeliveryOrder, selectedPrintType, reload]);

    useEffect(() => {
        // console.log({selectedPrintType, selectedDeliveryOrder});
        if (!selectedPrintType || (selectedPrintType === PRINT_TYPE_EXISTING && !selectedDeliveryOrder))
        {
            setAvailableSJ([]);
            return;
        }

        loadSJ();
    }, [selectedDeliveryOrder, selectedPrintType]);

    const columns = useMemo(() => [
        {
            Header: "No. SJ",
            accessor: "no_transaksi",
        },
        {
            Header: "Tanggal",
            id: "tanggal_transaksi",
            accessor: (row) => moment(row.tanggal_transaksi).format("DD-MM-YYYY HH:mm"),
            disableFilters: true,
            sortType: ({values: {tanggal_transaksi: a}}, {values: {tanggal_transaksi: b}}) => {
                const dateA = moment(a, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                const dateB = moment(b, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                // console.log({a, dateA, b, dateB});
                if(dateA < dateB)
                    return -1;
                
                if(dateA > dateB)
                    return 1;
                
                return 0;
            }
        },
        {
            Header: "No. BPB",
            accessor: "no_bukti_penerimaan_barang",
        },
        {
            Header: "No. GR Customer",
            accessor: "no_goods_receipt_sap",
        },
    ], []);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={availableSJ}
                reload={reload}
                maxRowCount={availableSJ.length}
                showPager={false}
            />
        );
    }, [columns, availableSJ, reload]);

    const handlePrint = useCallback(() => {
        if (!selectedPrintType || (selectedPrintType === PRINT_TYPE_EXISTING && !selectedDeliveryOrder) || (selectedPrintType === PRINT_TYPE_NEW && (!transactionDate || !availableSJ.length)))
            return;

        const printInvoice = (id) => window.open(`invoice/do/print?id=${id}`, "Delivery Order", "width=800,height=600");
        
        if (selectedPrintType === PRINT_TYPE_EXISTING)
        {
            printInvoice(selectedDeliveryOrder?.ucode);
            return;
        }

        setLoading(true);
        const dataToSend = {
            ucode_sales_order,
            tanggal_transaksi: transactionDate
        }
        apiCaller.post(`salesorders/customer/deliveryorders`, JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                setLoading(false);

                if (selectedPrintType === PRINT_TYPE_NEW)
                {
                    setIsAfterGenerate(true);
                    setSelectedPrintType(PRINT_TYPE_EXISTING);
                    setSelectedDeliveryOrder(response.data.data);
                }

                printInvoice(response.data.data.ucode);
                // onClose();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, selectedPrintType, ucode_sales_order, transactionDate, selectedDeliveryOrder, availableSJ]);

    const handleSaveChanges = useCallback(() => {
        if (!dataToEdit?.ucode || !dataToEdit?.no_transaksi || !dataToEdit?.tanggal_transaksi)
            return;

        setLoading(true);
        const dataToSend = {
            ucode: dataToEdit.ucode,
            no_transaksi: dataToEdit.no_transaksi,
            tanggal_transaksi: dataToEdit.tanggal_transaksi
        };
        apiCaller.put(`salesorders/customer/deliveryorders`, JSON.stringify(dataToSend))
            .then(() => {
                setSelectedDeliveryOrder({
                    ...dataToSend,
                    no_transaksi_combined: `${dataToSend.no_transaksi} (${moment(dataToSend.tanggal_transaksi).format("DD-MM-YYYY")})`,
                })
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [dataToEdit, apiCaller]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onClose()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handlePrint()}
                        disabled={!selectedPrintType || (selectedPrintType === PRINT_TYPE_EXISTING && !selectedDeliveryOrder) || (selectedPrintType === PRINT_TYPE_NEW && (!transactionDate || !availableSJ.length))}
                        size="sm-custom"
                    >
                        Cetak
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [selectedDeliveryOrder, onClose, handlePrint, selectedPrintType, transactionDate, availableSJ]);

    useEffect(() => {
        if (isAfterGenerate)
        {
            setIsAfterGenerate(false);
            return;
        }

        setSelectedDeliveryOrder(null);
    }, [selectedPrintType]);

    useEffect(() => {
        setDataToEdit(selectedDeliveryOrder ? {...selectedDeliveryOrder} : null);
    }, [selectedDeliveryOrder]);

    return (
        <CustomModal
            show={true}
            onHide={() => onClose()}
            title="Delivery Order"
            titleDetails={salesOrder?.no_transaksi_kombinasi ?? "N/A"}
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="m-0 p-0 justify-content-md-center mb-3">
                    <Col lg="12" className="m-0 p-0">
                        <Form.Label>
                            Generate DO Baru?: <span className="text-danger">*</span>
                        </Form.Label>
                        <br/>
                        <Form.Check
                            type="radio"
                            className="cursor-pointer"
                            onChange={({target: {checked}}) => setSelectedPrintType(checked ? PRINT_TYPE_NEW : PRINT_TYPE_EXISTING)}
                            checked={selectedPrintType === PRINT_TYPE_NEW}
                            inline
                            name="printType"
                            label="Ya"
                        />
                        <Form.Check
                            type="radio"
                            className="cursor-pointer"
                            onChange={({target: {checked}}) => setSelectedPrintType(checked ? PRINT_TYPE_EXISTING : PRINT_TYPE_NEW)}
                            checked={selectedPrintType === PRINT_TYPE_EXISTING}
                            inline
                            name="printType"
                            label="Pilih DO Lama"
                        />
                    </Col>
                </Row>
            {
                selectedPrintType === PRINT_TYPE_NEW ?
                <Row className="m-0 p-0 justify-content-md-center mb-3">
                    <Col lg="12" className="m-0 p-0">
                        <Form.Label>
                            Tanggal Delivery Order: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="datetime-local"
                            value={transactionDate ? transactionDate : ""}
                            name="tanggal_transaksi"
                            onChange={(e) => {
                                setTransactionDate(e.target.value);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
                :
                null
            }
            {
                selectedPrintType === PRINT_TYPE_EXISTING ?
                <>
                    <Row className="m-0 p-0 justify-content-md-center mb-3">
                        <Col lg="12" className="m-0 p-0">
                            <Form.Label>
                                Pilih No. Delivery Order: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="salesorders/search/customer/deliveryorders"
                                idField="ucode"
                                valueField="no_transaksi_combined"
                                isLookup={false}
                                onChange={(val) => setSelectedDeliveryOrder(val)}
                                selectedItem={selectedDeliveryOrder}
                                authState={authState}
                                minLength={0}
                                additionalParam={{
                                    id: ucode_sales_order
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        dataToEdit ?
                        <>
                            <Row className="m-0 p-0 justify-content-md-center mb-2">
                                <Col lg="6" className="m-0 p-0 pe-1">
                                    <Form.Label>
                                        No Delivery Order: <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        value={dataToEdit?.no_transaksi ?? ""}
                                        name="no_transaksi"
                                        onChange={(e) => {
                                            setDataToEdit({...dataToEdit, no_transaksi: e.target.value});
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                                <Col lg="6" className="m-0 p-0 ps-1">
                                    <Form.Label>
                                        Tanggal Delivery Order: <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        value={dataToEdit?.tanggal_transaksi ?? ""}
                                        name="tanggal_transaksi"
                                        onChange={(e) => {
                                            setDataToEdit({...dataToEdit, tanggal_transaksi: e.target.value});
                                        }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row className="m-0 p-0 justify-content-md-end mb-3">
                                <Col xl="2" lg="4" md="12" className="m-0 p-0">
                                    <Button
                                        variant="success"
                                        type="submit"
                                        className="w-100"
                                        onClick={() => handleSaveChanges()}
                                        size="sm-custom"
                                    >
                                        Simpan
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        :
                        null
                    }
                </>
                :
                null
            }
            {
                grid
            }
            </Container>
        </CustomModal>
    );
};

export default SalesOrderDeliveryOrderPrintDialog;
