import React, { useEffect, useState } from 'react';
import { Navbar, Button, Container, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useStateContext } from '../reducers';
import { useWindowDimensions } from '../utils/Common';
const logo = require("../assets/images/dsbm.png");

function MainNavbar(props) {
    const { authState, menuState, setMenuState } = useStateContext();
    const [ showSidebar, setShowSidebar ] = useState(menuState?.showSidebar ?? false);
    const [ showMenu, setShowMenu ] = useState(true);
    const {width} = useWindowDimensions();
    const [title, setTitle] = useState(width < 500 && showSidebar ? "" : "E-SIAP - Web");

    useEffect(() => {
        setShowMenu(authState && authState.username && authState.hasPassword);
    }, [authState]);

    const location = useLocation().pathname.split("/");

    useEffect(() => {
        if (showSidebar !== menuState?.showSidebar)
            setShowSidebar(menuState?.showSidebar ?? false);
    }, [menuState?.showSidebar]);

    useEffect(() => {
        if (showSidebar !== menuState?.showSidebar)
            setMenuState({type: "toggle", showSidebar: showSidebar});
    }, [showSidebar]);

    useEffect(() => {
        if (menuState?.showBackground !== (location[location.length - 1] === ""))
            setMenuState({type: "toggle", showSidebar, showBackground: location[location.length - 1] === ""});
    }, [location]);

    useEffect(() => {
        setTitle(width < 500 && showSidebar ? "" : "PARAPART - Web Admin");
    }, [width, showSidebar]);

    if (location[location.length - 1] === "print")
        return <div className="position-static"></div>;

    return (
        <Container fluid className="m-0 p-0 position-static bg-main">
            <Navbar collapseOnSelect expand="lg" bg="main" variant="dark" className={`px-3 position-static main-container${showSidebar ? " crop" : ""}`}>
            {
                showMenu ?
                <Button onClick={() => setShowSidebar(!showSidebar)} variant="main" className="me-1 d-block" size="sm-custom">
                    <span><i className="fas fa-bars" /></span>
                </Button>
                :
                null
            }
                <Navbar.Brand className="text-nowrap"><Image style={{height: "1.5rem"}} className="me-1 img-fluid" src={logo} alt="logo" />{title}</Navbar.Brand>
            </Navbar>
        </Container>
    )
}

export default MainNavbar;
