import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionContext, Nav, Offcanvas, useAccordionButton } from 'react-bootstrap';
import { groupBy, useWindowDimensions } from '../utils/Common';
import { Menu } from '../utils/Menu';
import { useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from '../reducers';
import Loader from './Loader';

function ContextAwareToggle({ eventKey, callback, title }) {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    return (
        <Accordion.Header className="bg-side-menu p-0" onClick={decoratedOnClick}>
            <div className="w-75">{title}</div>
            <div className="w-25 d-flex flex-wrap justify-content-end pe-2"><i className={`fas fa-chevron-${isCurrentEventKey ? "down" : "right"}`} /></div>
        </Accordion.Header>
    );
  }

function Sidebar(props) {
    const {show} = props;
    const rawLocation = useLocation();
    const { apiCaller, authState, setAuthState, menuState, setMenuState } = useStateContext();
    const {width} = useWindowDimensions();
    const [title, setTitle] = useState(width < 500 ? "E-SIAP" : "Menu");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const groupedMenu = useMemo(() =>
        groupBy(Menu.filter((m) => (authState.menus?.length && authState.menus.filter(am => m.children.filter(c => c.code === am.kode_menu).length > 0).length > 0) || m.group === "Profile"), "group")
    , [authState]);

    const getSelectedGroup = useCallback((currentPath) => {
        let result = -1;
        
        Object.keys(groupedMenu).forEach((g, idx) => {
            if (groupedMenu[g].filter(m => currentPath.toLowerCase().includes(m.path.toLowerCase())).length > 0)
                result = idx;
        });
        // console.log(result);
        return result;
    }, [groupedMenu]);

    const selectedGroup = useMemo(() => getSelectedGroup(rawLocation.pathname), [rawLocation, getSelectedGroup]);

    useEffect(() => {
        setTitle(width < 500 ? "E-SIAP" : "Menu");
    }, [width]);

    const handleLogout = useCallback(() => {
        setLoading(true);

        apiCaller.post(`users/logout`, {...authState})
            .then((response) => {
                // console.log({response});
                setAuthState({type: "logout"});
                setMenuState({type: "toggle", showSidebar: false, showBackground: false});
                setLoading(false);
                navigate('/');
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, setAuthState, authState, setMenuState, navigate]);

    return (
        <>
        {
            loading && <Loader />
        }
            <Offcanvas 
                placement="start"
                show={show}
                scroll={true}
                backdrop={false}
                className="side-menu"
            >
                <Offcanvas.Header className="d-flex flex-wrap justify-content-between">
                    <Offcanvas.Title className="title">{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <Nav className="flex-column me-auto">
                        <Nav.Link href="/" className="bg-side-menu dashboard p-2 mb-2" onClick={() => setMenuState({type: "background", showSidebar: menuState?.showSidebar ?? false, showBackground: true})} active={selectedGroup === -1}>Dashboard</Nav.Link>
                        <Accordion defaultActiveKey={selectedGroup}>
                        {
                            Object.keys(groupedMenu).map((g, idx) => {
                                const subGroupMenu = groupBy(groupedMenu[g], "subgroup");
                                // console.log({subGroupMenu});

                                const subs = Object.keys(subGroupMenu).map((s, s_idx) => {
                                    if (s === "Logout")
                                        return (
                                            <Nav.Link
                                                key={`inner_menu_${idx}_${s_idx}_0`}
                                                onClick={() => handleLogout()}
                                            >
                                                <span className="ps-2"><i className={`${subGroupMenu[s][0].icon} me-2`} />{s}</span>
                                            </Nav.Link>
                                        );

                                    const inner = subGroupMenu[s].map((i, i_idx) => {
                                        const innerPath = i.path.split("/");
                                        // console.log({innerPath, path: innerPath[innerPath.length - 1], active: currentPath === innerPath[innerPath.length - 1]});
                                        return (
                                            <Nav.Link
                                                key={`inner_menu_${idx}_${s_idx}_${i_idx}`}
                                                href={i.path}
                                                active={rawLocation?.pathname.toLowerCase().includes(innerPath[innerPath.length - 1])}
                                                onClick={() => setMenuState({type: "background", showSidebar: menuState?.showSidebar ?? false, showBackground: false})}
                                            >
                                                <span className="ps-2"><i className={`${i.icon ?? "fas fa-info-circle"} me-2`} />{i.subgroup}</span>
                                            </Nav.Link>
                                        )
                                    });
                                    
                                    return inner;
                                });

                                return (
                                    <Accordion.Item key={`menu_${idx}`} eventKey={idx} className="bg-side-menu">
                                        <ContextAwareToggle className="bg-side-menu p-0" eventKey={idx} title={g === "Profile" ? `${authState.username} (${authState.div_code})` : g} />
                                        <Accordion.Body className="bg-side-menu p-0">
                                        {
                                            subs
                                        }
                                        </Accordion.Body>
                                        <hr key={`menu_separator_${idx}`} className="accordion-divider" />
                                    </Accordion.Item>
                                );
                            })
                        }
                        </Accordion>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Sidebar;
