import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import ModalAlert from '../../../components/ModalAlert';
import CustomerEditor from './CustomerEditor';
import { useStateContext } from '../../../reducers';
import TooltipButton from '../../../components/TooltipButton';
import CustomerUserEditor from './CustomerUserEditor';
import CustomerItemsUploadModal from './CustomerItemsUploadModal';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { XLSX_FILE_EXTENSION, XLSX_FILE_TYPE } from '../../../utils/Common';
import moment from 'moment';

function Customer(props) {
    const {accessLevel} = props;
    const [itemInEdit, setItemInEdit] = useState(null);
    const [reload, setReload] = useState(0);
    const [userCustomerObj, setUserCustomerObj] = useState(null);
    const [userItemsObj, setUserItemsObj] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [loading, setLoading] = useState(false);
    const { apiCaller } = useStateContext();

    const handleChangeStatus = useCallback((ucode, status) => {
        setLoading(true);
        apiCaller.put(`customers/status/change?id=${ucode}&status=${status}`)
            .then(() => {
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const handleDelete = useCallback((ucode) => {
        setLoading(true);
        apiCaller.delete(`customers/${ucode}`)
            .then(() => {
                setDeleteConfirmation(null);
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => [
        {
            Header: "Divisi",
            accessor: "nama_divisi",
            sticky: "left",
        },
        {
            Header: "Kode Toko",
            accessor: "kode",
            sticky: "left",
        },
        {
            Header: "Nama Toko",
            accessor: "nama",
            sticky: "left",
        },
        {
            Header: "Grup Customer",
            accessor: "nama_group_cust",
        },
        {
            Header: "NPWP/KTP",
            accessor: "npwp",
        },
        {
            Header: "Alamat NPWP/KTP",
            accessor: "alamat",
        },
        {
            Header: "Alamat Kirim",
            accessor: "alamat_kirim",
        },
        {
            Header: "No Telepon",
            accessor: "telp",
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Jatuh Tempo",
            id: "jatuh_tempo",
            accessor: (row) => row.jatuh_tempo > 0 ? `${row.jatuh_tempo} hari` : "-",
        },
        {
            Header: "Customer Spesial",
            accessor: "spesial_cust",
            id: "spesial_cust",
            Cell: ({row: {original}}) => {
                return (
                    <>
                    {
                        original.spesial_cust ?
                        <i className="fas fa-check-circle text-success" />
                        :
                        <i className="fas fa-times-circle text-danger" />
                    }
                    </>
                );
            },
            disableFilters: true,
            centered: true,
            width: 100,
        },
        {
            Header: "Status",
            id: "sts",
            accessor: (row) => row.sts ? "Aktif" : "Tidak Aktif",
        },
        {
            Header: "Keterangan",
            accessor: "keterangan",
            disableSortBy: true,
        },
        {
            Header: "",
            id: "action",
            sticky: "right",
            Cell: ({row: {original}}) => {
                return (
                    <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title="Edit"
                            size="xs-custom"
                            variant="success"
                            className="me-1"
                            onClick={() => {
                                setItemInEdit(original);
                            }}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title={original.sts ? "Nonaktifkan" : "Aktifkan"}
                            size="xs-custom"
                            variant={original.sts ? "secondary" : "primary"}
                            className="me-1"
                            onClick={() => {
                                handleChangeStatus(original.ucode, original.sts ? 0 : 1);
                            }}
                        >
                            <i className={`fas fa-${original.sts ? "times" : "check"}`} />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title="Generate User"
                            size="xs-custom"
                            variant="dark"
                            className="me-1"
                            onClick={() => {
                                setUserCustomerObj(original);
                            }}
                            disabled={!original.npwp}
                        >
                            <i className="fas fa-user" />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title="Set Barang"
                            size="xs-custom"
                            variant="dark"
                            className="me-1"
                            onClick={() => {
                                setUserItemsObj(original);
                            }}
                        >
                            <i className="fas fa-upload" />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_delete > 0 &&
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="danger"
                            onClick={() => {
                                setDeleteConfirmation(original);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    }
                    </InputGroup>
                )
            },
            width: 200,
        },
    ], [accessLevel, handleChangeStatus]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="customers"
                reload={reload}
                rowColorFunction={(data) => ({backgroundColor: !data.sts ? "#efefef" : "#fff", color: !data.sts ? "#888" : "default"})}
            />
        );
    }, [reload, columns]);

    const handleDownloadTemplateItems = useCallback(() => {
        setLoading(true);
        apiCaller.get("items?ignorePaging=true")
            .then((response) => {
                if (!response.data.data || response.data.data.length === 0)
                    return;

                const result = response.data.data.map(d => ({
                    kode_brg: d.kode,
                    nama_brg: d.nama,
                    kode_lain: null,
                    nama_lain: null,
                }));
                // console.log({response, result});
                const ws = XLSX.utils.json_to_sheet(result);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
                const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
                FileSaver.saveAs(data, `customer_items_template_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                {
                    accessLevel.can_add > 0 ?
                    <Col xs={12} className="mb-2" style={{fontSize: "0.75rem"}}>
                        <i>Syarat generate user customer: <b>NPWP</b> harus diisi dan valid.</i>
                    </Col>
                    :
                    null
                }
                    <Col xs={12}>
                        <InputGroup>
                        {
                            accessLevel.can_add > 0 ?
                            <Button size="xs-custom" variant="dark" className="me-1" onClick={() => setItemInEdit({})}><i className="fas fa-plus me-1" />Tambah Data</Button>
                            :
                            null
                        }
                        {
                            accessLevel.can_edit > 0 ?
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => handleDownloadTemplateItems()}><i className="fas fa-file-download me-1" />Download Template Barang Customer</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        {
            itemInEdit ?
            <CustomerEditor
                onHide={() => setItemInEdit(null)}
                data={itemInEdit}
                onSave={() => {
                    setItemInEdit(null);
                    setReload(reload === 1 ? 0 : 1);
                }}
            />
            :
            null
        }
        {
            userCustomerObj ?
            <CustomerUserEditor
                onHide={() => setUserCustomerObj(null)}
                customer={userCustomerObj}
            />
            :
            null
        }
        {
            userItemsObj ?
            <CustomerItemsUploadModal
                onHide={() => setUserItemsObj(null)}
                onSave={() => setUserItemsObj(null)}
                customer={userItemsObj}
            />
            :
            null
        }
        {
            deleteConfirmation ?
            <ModalAlert
                onHide={() => setDeleteConfirmation(null)}
                onSave={() => handleDelete(deleteConfirmation.ucode)}
                title="Konfirmasi Penghapusan Data"
                bodyText={`Apakah anda yakin akan menghapus data customer ${deleteConfirmation.nama} (${deleteConfirmation.kode})?`}
            />
            :
            null
        }
        </>
    );
}

export default Customer;
