import React, { useEffect, useMemo } from "react";
import { useStateContext } from "../../../reducers";
import { loadSalesNotificationData } from "../../../utils/api/sales";
import TemplateView from "../../TemplateView";
import SalesContext from "./Context";

function View(props) {
    const { apiCaller, setSalesState, authState } = useStateContext();

    useEffect(() => {
        const interval = setInterval(() => {
            loadSalesNotificationData(apiCaller, setSalesState, authState);
        }, process.env.REACT_APP_RELOAD_INTERVAL);
      
        return () => clearInterval(interval);
    }, []);

    const providerState = useMemo(() => {
        return {
            loadNotifCount: () => loadSalesNotificationData(apiCaller, setSalesState, authState),
        }
    }, [apiCaller, setSalesState, authState]);

    return (
        <SalesContext.Provider value={providerState}>
            <TemplateView
                group="Penjualan"
                subgroup="Transaksi Penjualan"
            />
        </SalesContext.Provider>
    );
};

export default View;
