import React from "react";
import TemplateView from "../../TemplateView";

function View(props) {
    return (
        <TemplateView
            group="Stok"
            subgroup="Master Barang"
        />
    );
};

export default View;