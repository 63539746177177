import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";

function SalesOrderItemEditor(props) {
    const { apiCaller, authState } = useStateContext();
    const {
        headerData,
        data,
        items,
        tax,
        onClose,
        onSave,
    } = props;
    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState(data ? {...data, brg: data?.ucode_brg ? {ucode: data.ucode_brg, kode: data.kode_brg, nama: data.nama_brg, kode_material: data.kode_material} : null, group_brg: data?.ucode_group_brg ? {ucode: data.ucode_group_brg, nama: data.nama_group_brg} : null, kategori_brg: data?.ucode_kategori_brg ? {ucode: data.ucode_kategori_brg, nama: data.nama_kategori_brg} : null} : {});
    const [disableSave, setDisableSave] = useState(true);

    // useEffect(() => {
    //     console.log({data});
    // }, [data]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: !value || value.trim() === "" ? null : value});
    }, [itemInEdit]);

    const handleTextNumberChanged = useCallback(({target: {value, name}}) => {
        const parsedValue = parseInt(value);
        
        if (isNaN(parsedValue))
        {
            setItemInEdit({...itemInEdit, [name]: null});
            return;
        }

        setItemInEdit({...itemInEdit, [name]: parsedValue});
    }, [itemInEdit]);

    const handleTextFloatNumberChanged = useCallback(({target: {value, name}}) => {
        const parsedValue = parseFloat(value);
        
        if (isNaN(parsedValue))
        {
            setItemInEdit({...itemInEdit, [name]: null});
            return;
        }

        setItemInEdit({...itemInEdit, [name]: parsedValue});
    }, [itemInEdit]);

    const calculateRow = useCallback((harga, qty, diskon) => {
        const sub_total_bruto = parseFloat(((qty ? qty : 0) * ((harga ? harga : 0) - (diskon ? (diskon * harga / 100) : 0))).toFixed(2));
        const total_ppn = parseFloat((sub_total_bruto * (tax ? tax.pajak : 0) / 100).toFixed(2));
        const sub_total_netto = sub_total_bruto + total_ppn;

        // console.log({harga, qty, diskon, sub_total_bruto, total_ppn, sub_total_netto});

        return {
            sub_total_bruto,
            total_ppn,
            sub_total_netto
        };
    }, [tax]);

    const loadHargaBrg = (ucode_customer, ucode_brg, ucode_satuan, ucode_kategori_brg, ucode_group_brg) => {
        if (!ucode_brg || !ucode_satuan || !ucode_kategori_brg || !ucode_group_brg || (typeof ucode_brg === "object") || (typeof ucode_satuan === "object") || (typeof ucode_kategori_brg === "object") || (typeof ucode_group_brg === "object"))
            return 0;

        setLoading(true);
        return apiCaller.get(`pricelist/search/item?cust_id=${ucode_customer}&item_id=${ucode_brg}&unit_id=${ucode_satuan}&category_id=${ucode_kategori_brg}&group_id=${ucode_group_brg}`)
            .then((response) => {
                return response.data?.data?.harga ?? 0;
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    };

    const getHargaBrg = async (param) => {
        // console.log({param});
        if (headerData?.dari_customer)
            return items.filter(i => i.ucode_brg === param.ucode_brg && i.ucode_satuan === param.ucode_satuan && i.ucode_kategori_brg === param.ucode_kategori_brg && i.ucode_group_brg === param.ucode_group_brg)[0]?.harga ?? 0;
        
        const result = await loadHargaBrg(headerData.ucode_customer, param.ucode_brg, param.ucode_satuan, param.ucode_kategori_brg, param.ucode_group_brg);
        return result;
    };

    const onItemSelected = useCallback((val) => {
        getHargaBrg({ ucode_brg: val?.ucode, ucode_satuan: val?.ucode_satuan, ucode_kategori_brg: itemInEdit?.ucode_kategori_brg, ucode_group_brg: itemInEdit?.ucode_group_brg ?? val?.ucode_group_brg })
            .then(response => {
                setItemInEdit({
                    ...itemInEdit,
                    ucode_brg: val?.ucode,
                    kode_brg: val?.kode,
                    nama_brg: val?.nama,
                    kode_material: val?.kode_material,
                    nama_material: val?.nama_material,
                    ucode_group_brg: val?.ucode_group_brg,
                    nama_group_brg: val?.nama_group_brg,
                    group_brg: val?.ucode_group_brg ? {
                        ucode: val?.ucode_group_brg,
                        kode: val?.kode_group_brg,
                        nama: val?.nama_group_brg,
                    } : null,
                    ucode_satuan: val?.ucode_satuan,
                    nama_satuan: val?.nama_satuan,
                    satuan: val?.ucode_satuan ? {
                        ucode: val?.ucode_satuan,
                        kode: val?.kode_satuan,
                        nama: val?.nama_satuan,
                    } : null,
                    brg: val,
                    harga: response ? Number(response) : null,
                });
            });
    }, [itemInEdit]);

    const onItemGroupSelected = useCallback((val) => {
        getHargaBrg({ ucode_brg: itemInEdit?.ucode_brg, ucode_satuan: itemInEdit?.ucode_satuan, ucode_kategori_brg: itemInEdit?.ucode_kategori_brg, ucode_group_brg: val?.ucode })
            .then(response => {
                setItemInEdit({
                    ...itemInEdit,
                    ucode_group_brg: val?.ucode,
                    nama_group_brg: val?.nama,
                    group_brg: val,
                    harga: response ? Number(response) : null,
                });
            });
    }, [itemInEdit]);

    const onItemCategorySelected = useCallback((val) => {
        getHargaBrg({ ucode_brg: itemInEdit?.ucode_brg, ucode_satuan: itemInEdit?.ucode_satuan, ucode_kategori_brg: val?.ucode, ucode_group_brg: itemInEdit?.ucode_group_brg })
            .then(response => {
                setItemInEdit({
                    ...itemInEdit,
                    ucode_kategori_brg: val?.ucode,
                    nama_kategori_brg: val?.nama,
                    kategori_brg: val,
                    harga: response ? Number(response) : null,
                });
            });
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(
            itemInEdit?.ucode_brg && itemInEdit?.ucode_satuan && itemInEdit?.ucode_kategori_brg && itemInEdit?.ucode_group_brg && itemInEdit?.qty_tersedia && itemInEdit?.harga !== null && itemInEdit?.harga !== undefined ?
            false
            :
            true
        );
    }, [itemInEdit]);

    const footer = useMemo(() => {
        return (
            <Container fluid>
                <Row className="justify-content-md-end">
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="secondary"
                            type="submit"
                            className="w-100"
                            onClick={() => onClose()}
                            size="sm-custom"
                        >
                            Keluar
                        </Button>
                    </Col>
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="success"
                            type="submit"
                            className="w-100"
                            onClick={() => onSave({...itemInEdit, ...calculateRow(itemInEdit?.harga, itemInEdit?.qty_tersedia, itemInEdit?.diskon)})}
                            disabled={disableSave}
                            size="sm-custom"
                        >
                            Simpan
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }, [disableSave, onClose, onSave, itemInEdit, calculateRow]);

    return (
        <CustomModal
            show={true}
            onHide={() => onClose()}
            title="Sales Order Detail"
            titleDetails={`${headerData?.ucode ? "Edit" : "Baru"}${headerData?.dari_customer ? " (request customer)" : ""}`}
            useDefaultNotes={true}
            footer={footer}
        >
        {
            loading && <Loader />
        }
            <Container fluid>
                <Row className="mb-3">
                {
                    headerData?.customer?.punya_kode_brg_sendiri ?
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Kode Material:
                        </Form.Label>
                        <ComboBox
                            endpoint="items/search/customer"
                            idField="ucode"
                            valueField="kode_material"
                            isLookup={false}
                            onChange={(val) => onItemSelected(val)}
                            selectedItem={itemInEdit?.brg}
                            authState={authState}
                            minLength={2}
                            filterName="custcode"
                            additionalParam={{
                                id: headerData?.ucode_customer
                            }}
                        />
                    </Col>
                    :
                    null
                }
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Kode Barang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="items/search/customer"
                            idField="ucode"
                            valueField="kode"
                            isLookup={false}
                            onChange={(val) => onItemSelected(val)}
                            selectedItem={itemInEdit?.brg}
                            authState={authState}
                            minLength={2}
                            filterName="code"
                            additionalParam={{
                                id: headerData?.ucode_customer
                            }}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama Barang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="items/search/customer"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onItemSelected(val)}
                            selectedItem={itemInEdit?.brg}
                            authState={authState}
                            minLength={2}
                            filterName="name"
                            additionalParam={{
                                id: headerData?.ucode_customer
                            }}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama Satuan: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            disabled
                            value={itemInEdit?.nama_satuan ?? ""}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama Grup Barang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="itemgroups/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onItemGroupSelected(val)}
                            selectedItem={itemInEdit?.group_brg}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Nama Kategori Barang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="itemcategories/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onItemCategorySelected(val)}
                            selectedItem={itemInEdit?.kategori_brg}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                {
                    headerData?.dari_customer ?
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Qty Request:
                        </Form.Label>
                        <Form.Control
                            disabled
                            value={itemInEdit?.qty_request ?? ""}
                        />
                    </Col>
                    :
                    null
                }
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                        {
                            headerData?.dari_customer ?
                            "Qty Tersedia"
                            :
                            "Qty"
                        }
                            : <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={itemInEdit?.qty_tersedia ?? ""}
                            name="qty_tersedia"
                            onChange={(e) => {
                                handleTextNumberChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Harga (Rp): <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={itemInEdit?.harga ?? ""}
                            name="harga"
                            onChange={(e) => {
                                handleTextFloatNumberChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Diskon (%):
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={itemInEdit?.diskon ?? ""}
                            name="diskon"
                            onChange={(e) => {
                                handleTextFloatNumberChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit?.keterangan ?? ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={5}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
}

export default SalesOrderItemEditor;
