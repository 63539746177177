import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Image, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import CustomModal from '../../../components/CustomModal';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import ModalAlert from '../../../components/ModalAlert';
import TooltipButton from '../../../components/TooltipButton';
import { useStateContext } from '../../../reducers';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function PaymentProofList(props) {
    const {
        onHide,
        onCreatePR,
        accessLevel,
    } = props;

    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deactivateConfirmation, setDeactivateConfirmation] = useState(null);
    const { apiCaller } = useStateContext();

    useEffect(() => {
        const interval = setInterval(() => {
            setReload(reload === -1 ? -2 : -1);
        }, process.env.REACT_APP_RELOAD_INTERVAL);
      
        return () => clearInterval(interval);
    }, [reload]);

    const handleChangeStatus = useCallback((ucode, status) => {
        setLoading(true);
        apiCaller.put(`paymentproofs/status/change?id=${ucode}&status=${status}`)
            .then(() => {
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
                setDeactivateConfirmation(false);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => [
        {
            Header: "Nama Customer",
            accessor: "nama_customer",
        },
        {
            Header: "No. Sales Order",
            accessor: "no_sales_order",
        },
        {
            Header: "Bukti Bayar",
            id: "bb_img",
            Cell: ({row: {original}}) => {
                const popover = (
                    !original.base64 || !original.file_name ?
                    <></>
                    :
                    <Popover id={`img-${original.ucode}`} className="img-tooltip">
                        <Popover.Body className="img-tooltip p-0">
                            <TransformWrapper>
                                <TransformComponent>
                                    <Image alt={`${original.file_name}`} src={original.base64} className="img-fluid img-tooltip" />
                                </TransformComponent>
                            </TransformWrapper>
                        </Popover.Body>
                    </Popover>
                );
            
                return (
                    <div
                        className={`w-100 h-100 text-center align-middle ${!original.base64 || !original.file_name ? "text-danger" : "cursor-pointer"}`}
                    >
                        <OverlayTrigger placement="right" overlay={popover} trigger="click">
                            <i className="fas fa-image"></i>
                        </OverlayTrigger>
                    </div>
                );
            },
            disableFilters: true,
        },
        {
            Header: "Tanggal Upload",
            id: "created_at",
            accessor: (row) => moment(row.created_at).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                // console.log({ucode_customer: original.ucode_customer});
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title="Buat Penerimaan Pembayaran"
                            size="xs-custom"
                            variant="outline-primary"
                            className="me-2"
                            onClick={() => {
                                onCreatePR({
                                    value: original.ucode_customer,
                                    label: original.nama_customer,
                                    nama_customer: original.nama_customer,
                                    alamat_kirim: original.alamat_kirim,
                                    telp: original.telp,
                                });
                            }}
                        >
                            <i className="fas fa-chevron-right" />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title={original.sts ? "Nonaktifkan" : "Aktifkan"}
                            size="xs-custom"
                            variant="outline-warning"
                            className="me-2"
                            onClick={() => {
                                setDeactivateConfirmation(original);
                            }}
                        >
                            <i className={`fas fa-${original.sts ? "times" : "check"}`} />
                        </TooltipButton>
                    }
                    </div>
                )
            },
        },
    ], [accessLevel, onCreatePR]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="paymentproofs/search/unhandled"
                reload={reload}
                hidePageSizeSelector={true}
                defaultPageSize={10}
            />
        );
    }, [reload, columns]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Bukti Pembayaran"
            titleDetails="Bukti Pembayaran Baru"
            dialogClassName="modal-w-80"
            footer={null}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="mb-3">
                    <Col xs={12}>
                        <Button size="xs-custom" variant="outline-primary" className="ms-1" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt" /></Button>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        {
            deactivateConfirmation ?
            <ModalAlert
                onHide={() => setDeactivateConfirmation(null)}
                onSave={() => handleChangeStatus(deactivateConfirmation.ucode, 0)}
                title="Konfirmasi Penonaktifan Data"
                bodyText={<span>Apakah anda yakin akan menonaktifkan status bukti bayar untuk SO <b>{deactivateConfirmation.no_sales_order}</b> (Tanggal Upload: {moment(deactivateConfirmation.created_at).format("DD-MM-YYYY HH:mm:ss")})? Bukti bayar tidak akan muncul untuk seterusnya.</span>}
            />
            :
            null
        }
        </CustomModal>
    );
}

export default PaymentProofList;
