import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStateContext } from "../../../reducers";
import Loader from "../../../components/Loader";
import moment from "moment";
import { useWindowDimensions } from "../../../utils/Common";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink, Image } from '@react-pdf/renderer';
import OpenSansRegular from '../../../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../../assets/fonts/OpenSans-Bold.ttf';
import OpenSansItalic from '../../../assets/fonts/OpenSans-Italic.ttf';
import { isMobile } from "react-device-detect";
import { Button, Container } from "react-bootstrap";

Font.register({
    family: 'open-sans',
    fonts: [
        {
            src: OpenSansRegular,
        },
        {
            src: OpenSansBold,
            fontWeight: "bold",
        },
        {
            src: OpenSansItalic,
            fontStyle: "italic",
        },
    ]
});

function DeliveryOrderContent(props) {
    const {
        itemToShow,
        userData,
    } = props;

    const styles = StyleSheet.create({
        page: {
            fontFamily: "open-sans",
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        row: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        rowStretch: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-center",
            flexWrap: "wrap",
        },
        col: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        headerTitle: {
            width: "100%",
            fontSize: 14,
            fontWeight: "bold",
            marginVertical: 5,
        },
        header: {
            width: "100%",
            fontSize: 8,
            marginVertical: 2,
        },
        title: {
            width: "100%",
            fontSize: 20,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
            textDecoration: "underline",
        },
        headerDeliveryHeader: {
            width: "100%",
            fontSize: 9,
            fontWeight: "bold",
        },
        headerDeliveryDetail: {
            width: "100%",
            fontSize: 8,
            marginTop: 3,
        },
        descriptionTitle: {
            width: "12%",
            fontSize: 10,
            marginVertical: 2,
            color: "#777",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        descriptionValue: {
            width: "28%",
            fontSize: 10,
            marginVertical: 2,
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        descriptionCenter: {
            width: "20%",
            fontSize: 12,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
        },
        colTitle: {
            fontWeight: "bold",
        },
        col1: {
            width: "10%",
            fontSize: 9,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col2: {
            width: "25%",
            fontSize: 9,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col3: {
            width: "55%",
            fontSize: 9,
            marginVertical: 2,
            textAlign: "left",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        col4: {
            width: "10%",
            fontSize: 9,
            marginVertical: 2,
            textAlign: "right",
            wordWrap: "break-word",
            wordBreak: "break-word",
        },
        colFooter: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
        },
        footer: {
            width: "30%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: 5,
        },
        footerTitle: {
            width: "100%",
            textAlign: "center",
        },
        footerValue: {
            width: "100%",
            textAlign: "center",
            borderTopWidth: 1,
            marginTop: 180,
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.rowStretch}>
                        <View style={{width: "32%"}}>
                            <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-center"}}>
                                <View style={{width: "30%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                                {
                                    itemToShow?.logo_divisi ?
                                    <Image
                                        src={`data:${itemToShow?.tipe_file_logo_divisi};base64,${itemToShow?.logo_divisi}`}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            objectFit: "contain",
                                        }}
                                    />
                                    :
                                    null
                                }
                                </View>
                                <View style={{width: "70%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start", marginLeft: "5px"}}>
                                    <Text style={styles.headerTitle}>{itemToShow?.nama_divisi}</Text>
                                    <Text style={styles.header}>{itemToShow?.alamat_divisi}</Text>
                                    <Text style={styles.header}>{itemToShow?.kota_divisi}</Text>
                                    <Text style={styles.header}>NPWP : {itemToShow?.npwp_divisi}</Text>
                                    <Text style={styles.header}>Telp : {itemToShow?.telp_divisi}, Fax : -</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{width: "32%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            <Text style={{...styles.title}}>SURAT JALAN</Text>
                        </View>
                        <View style={{width: "32%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingHorizontal: 3}}>
                            <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "right"}}>
                                <Text style={styles.headerDeliveryHeader}>Tanggal Pengiriman</Text>
                                <Text style={styles.headerDeliveryDetail}>{itemToShow?.tanggal_transaksi ? moment(itemToShow.tanggal_transaksi).format("DD-MM-YYYY") : "-"}</Text>
                            </View>
                            <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 8, textAlign: "right"}}>
                                <Text style={styles.headerDeliveryHeader}>No. Pengiriman</Text>
                                <Text style={styles.headerDeliveryDetail}>{itemToShow?.no_transaksi}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{...styles.row, marginTop: 10}}>
                        <View style={styles.col}>
                            <Text style={styles.descriptionTitle}>Pelanggan:</Text>
                            <Text style={styles.descriptionValue}>{itemToShow?.nama_customer}</Text>
                        </View>
                    </View>
                    <View style={{...styles.row}}>
                        <View style={styles.col}>
                            <Text style={styles.descriptionTitle}>Alamat:</Text>
                            <Text style={styles.descriptionValue}>{itemToShow?.alamat_kirim}</Text>
                        </View>
                    </View>
                    <View style={{...styles.row, marginTop: 10, padding: 10, borderTopWidth: 1, borderBottomWidth: 1,}}>
                        <View style={{...styles.col, marginBottom: 3, borderBottomWidth: 0.5, padding: 5}}>
                            <Text style={{...styles.col1, ...styles.colTitle}}>No.</Text>
                            <Text style={{...styles.col2, ...styles.colTitle}}>Kode Barang</Text>
                            <Text style={{...styles.col3, ...styles.colTitle}}>Nama Barang</Text>
                            <Text style={{...styles.col4, ...styles.colTitle}}>Qty</Text>
                        </View>
                    {
                        itemToShow?.details.map((d, idx) => {
                            return (
                                // <View key={`row-${idx}`} style={{...styles.col, backgroundColor: idx % 2 === 0 ? "#ededed" : "transparent", marginVertical: 2, padding: 5}}>
                                <View key={`row-${idx}`} style={{...styles.col, backgroundColor: "transparent", marginVertical: 2, padding: 5}}>
                                    <Text style={styles.col1}>{idx+1}</Text>
                                    <Text style={styles.col2}>{d.kode_brg}</Text>
                                    <Text style={styles.col3}>{d.nama_brg}</Text>
                                    <Text style={styles.col4}>{d.qty_kirim} {d.kode_satuan}</Text>
                                </View>
                            );
                        })
                    }
                    </View>
                    <View style={{...styles.row, marginTop: 10, padding: 10}}>
                        <View style={styles.colFooter}>
                            <View style={styles.footer}>
                                <Text style={styles.footerTitle}>Disiapkan Oleh,</Text>
                                <Text style={styles.footerValue}></Text>
                            </View>
                            <View style={styles.footer}>
                                <Text style={styles.footerTitle}>Dikirim Oleh,</Text>
                                <Text style={styles.footerValue}></Text>
                            </View>
                            <View style={styles.footer}>
                                <Text style={styles.footerTitle}>Diterima Oleh,</Text>
                                <Text style={styles.footerValue}></Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={{textAlign: "right", paddingHorizontal: 20, fontSize: 10, fontStyle: "italic"}} fixed>
                    Dicetak Oleh: {userData?.emp_name}
                </Text>
                <Text style={{textAlign: "right", paddingHorizontal: 20, paddingTop: 0, paddingBottom: 30, fontSize: 10, fontStyle: "italic", color: "#666"}} render={({ pageNumber, totalPages }) => (
                    `page ${pageNumber} of ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
};

function DeliveryOrderPrint(props) {
    const [id, setId] = useState(null);
    const [itemToShow, setItemToShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const { authState, apiCaller, menuState, setMenuState } = useStateContext();
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setId(searchParams.get("id"));
    }, [searchParams]);

    const loadData = useCallback(() => {
        if (!id)
            return;

        setLoading(true);
        apiCaller.get(`deliveryorders/search/receipt?id=${id}`)
            .then((response) => {
                // console.log({response});
                const {data: {data: {header, detail}}} = response;
                setItemToShow({
                    ...header,
                    details: [...detail]
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [id, apiCaller]);

    useEffect(() => {
        if (!id)
            return;

        loadData();
    }, [id]);

    useEffect(() => {
        if (menuState?.showSidebar)
            setMenuState({type: "toggle", showSidebar: false});
    }, [menuState]);

    if (!itemToShow)
        return <></>;

    const styles = StyleSheet.create({
        viewer: {
            width,
            height: height - 6,
            fontFamily: "open-sans",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
        },
        downloadButton: {
        }
    });

    return (
        <>
        {
            loading && <Loader />
        }
        {
            isMobile ?
            <Container fluid className="vh-100 d-flex flex-wrap justify-content-center align-items-center bg-light">
                <PDFDownloadLink document={<DeliveryOrderContent itemToShow={itemToShow} />} fileName={`${itemToShow.no_transaksi}-${moment(new Date()).format("YYYYMMDDHHmmss")}`}>
                {
                    ({ loading }) =>
                    (
                        // <View style={styles.viewer}>
                        // {
                        //     loading ?
                        //     <Text style={{fontStyle: "italic"}}>Loading...</Text>
                        //     :
                        //     <Text style={{fontStyle: "bold", color: "#fff", backgroundColor: "#bd1c1c", padding: 5}}>Download</Text>
                        // }
                        // </View>
                        loading ?
                        "Loading..."
                        :
                        <Button variant="print-download" size="sm-custom"><i className="fas fa-file-pdf me-2" />Download SJ</Button>
                    )
                }
                </PDFDownloadLink>
            </Container>
            :
            <PDFViewer style={styles.viewer}>
                <DeliveryOrderContent itemToShow={itemToShow} userData={authState} />
            </PDFViewer>
        }
        </>
    );
}

export default DeliveryOrderPrint;
