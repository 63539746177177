import React from 'react';
import { Modal, ProgressBar, Spinner } from 'react-bootstrap';

function LoaderProgress(props) {
    return (
        <Modal
            show={true}
            size="lg"
            className="d-flex justify-content-center align-items-center loader w-100 h-100"
            contentClassName="d-flex justify-content-center align-items-center bg-transparent border-0 loader w-100 h-100"
        >
            <Modal.Body className="d-flex flex-column justify-content-center align-items.center">
                <Spinner animation="border" role="status" className="mb-3">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <ProgressBar now={props.now} label={`${props.now}%`} style={{width: "30vw"}} />
            </Modal.Body>
        </Modal>
    );
}

export default LoaderProgress;
