import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import ModalAlert from '../../../components/ModalAlert';
import { useStateContext } from '../../../reducers';
import TooltipButton from '../../../components/TooltipButton';
import RoleEditor from './RoleEditor';

function Role(props) {
    const {accessLevel} = props;
    const [itemInEdit, setItemInEdit] = useState(null);
    const [reload, setReload] = useState(0);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [loading, setLoading] = useState(false);
    const { apiCaller } = useStateContext();

    const handleChangeStatus = useCallback((ucode, status) => {
        setLoading(true);
        apiCaller.put(`roles/status/change?id=${ucode}&status=${status}`)
            .then(() => {
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const handleDelete = useCallback((ucode) => {
        setLoading(true);
        apiCaller.delete(`roles/${ucode}`)
            .then(() => {
                setDeleteConfirmation(null);
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => [
        {
            Header: "Kode",
            accessor: "kode",
        },
        {
            Header: "Nama",
            accessor: "nama",
        },
        {
            Header: "Level",
            accessor: "level",
        },
        {
            Header: "Status",
            id: "sts",
            accessor: (row) => row.sts ? "Aktif" : "Tidak Aktif",
        },
        {
            Header: "Spesifik Gudang",
            accessor: "spesifik_gudang",
            id: "spesifik_gudang",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({row: {original}}) => {
                return (
                    <>
                    {
                        original.spesifik_gudang > 0 ?
                        <i className="fas fa-check-circle text-success" />
                        :
                        <i className="fas fa-times-circle text-danger" />
                    }
                    </>
                );
            },
            centered: true,
            width: 120,
        },
        {
            Header: "Override Status",
            accessor: "override_status",
            id: "override_status",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({row: {original}}) => {
                return (
                    <>
                    {
                        original.override_status ?
                        <i className="fas fa-check-circle text-success" />
                        :
                        <i className="fas fa-times-circle text-danger" />
                    }
                    </>
                );
            },
            centered: true,
            width: 120,
        },
        {
            Header: "Keterangan",
            accessor: "keterangan",
            disableSortBy: true,
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title="Edit"
                            size="xs-custom"
                            variant="success"
                            className="me-1"
                            onClick={() => {
                                setItemInEdit(original);
                            }}
                        >
                            <i className="fas fa-edit" />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_edit > 0 &&
                        <TooltipButton
                            title={original.sts ? "Nonaktifkan" : "Aktifkan"}
                            size="xs-custom"
                            variant={original.sts ? "secondary" : "primary"}
                            className="me-1"
                            onClick={() => {
                                handleChangeStatus(original.ucode, original.sts ? 0 : 1);
                            }}
                        >
                            <i className={`fas fa-${original.sts ? "times" : "check"}`} />
                        </TooltipButton>
                    }
                    {
                        accessLevel.can_delete > 0 &&
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="danger"
                            onClick={() => {
                                setDeleteConfirmation(original);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    }
                    </InputGroup>
                )
            },
            sticky: "right",
        },
    ], [accessLevel, handleChangeStatus]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="roles"
                reload={reload}
                rowColorFunction={(data) => ({backgroundColor: !data.sts ? "#efefef" : "#fff", color: !data.sts ? "#888" : "default"})}
            />
        );
    }, [reload, columns]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                        {
                            accessLevel.can_add > 0 ?
                            <Button size="xs-custom" variant="dark" className="me-1" onClick={() => setItemInEdit({})}><i className="fas fa-plus me-1" />Tambah Data</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        {
            itemInEdit ?
            <RoleEditor
                onHide={() => setItemInEdit(null)}
                data={itemInEdit}
                onSave={() => {
                    setItemInEdit(null);
                    setReload(reload === 1 ? 0 : 1);
                }}
            />
            :
            null
        }
        {
            deleteConfirmation ?
            <ModalAlert
                onHide={() => setDeleteConfirmation(null)}
                onSave={() => handleDelete(deleteConfirmation.ucode)}
                title="Konfirmasi Penghapusan Data"
                bodyText={`Apakah anda yakin akan menghapus data role ${deleteConfirmation.nama} (${deleteConfirmation.kode})?`}
            />
            :
            null
        }
        </>
    );
}

export default Role;
