import Axios from 'axios';

export default class ApiCaller {
    constructor(props) {
        this.headers = {};

        if (props.authState && props.authState.username && props.authState.username !== "")
            this.headers.sender = props.authState.username;

        if (props.authState && props.authState.token && props.authState.token !== "")
            this.headers["Authorization"] = `Bearer ${props.authState.token}`;

        if (props.authState && props.authState.div_code && props.authState.div_code !== "")
            this.headers["div"] = props.authState.div_code;
    }

    getUrlSetup(url, customHeaders) {
        const result = {url: `${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`};

        if (this.headers.sender || this.headers["Authorization"] || this.headers["div"])
            result.headers = this.headers;

        if (customHeaders)
            result.headers = {...result.headers, ...customHeaders};
        
        return result;
    }

    get(url, customHeaders) {
        if (this.headers.sender || this.headers["Authorization"] || this.headers["div"])
            return Axios.get(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.get(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ?? null});
    }

    post(url, data, customHeaders) {
        if (this.headers.sender || this.headers["Authorization"] || this.headers["div"])
            return Axios.post(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.post(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ?? null});
    }

    put(url, data, customHeaders) {
        if (this.headers.sender || this.headers["Authorization"] || this.headers["div"])
            return Axios.put(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});

        return Axios.put(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ?? null});
    }

    delete(url, customHeaders) {
        if (this.headers.sender || this.headers["Authorization"] || this.headers["div"])
            return Axios.delete(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.delete(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ?? null});
    }
};
