import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../reducers';
import ErrorAlert from '../../components/ErrorAlert';
import { useWindowDimensions } from '../../utils/Common';

export default function Login(props) {
    const [ loading, setLoading ] = useState(false);
    const [ username, setUsername ] = useState(null);
    const [ password, setPassword ] = useState(null);
    const [ errorObj, setErrorObj ] = useState(null);
    const { authState, setAuthState, apiCaller } = useStateContext();
    const navigate = useNavigate();
    const {height} = useWindowDimensions();

    const handleSubmit = useCallback(() => {
        if (!username)
            return;

        setLoading(true);
        // console.log({username, password});
        apiCaller.post("users/login", {
            username,
            password
        })
            .then((response) => {
                // console.log({response});
                setAuthState({type: "login", ...response.data.data});
                setLoading(false);
            })
            .catch(error => {
                console.error({error});
                setErrorObj({
                    message: "Username/password tidak sesuai.",
                });
                setLoading(false);
            })
        ;
    }, [username, password, setAuthState, apiCaller]);

    useEffect(() => {
        if (authState && authState.username && !authState.hasPassword)
        {
            // console.log({authState});
            navigate("/change-password");
            return;
        }
        
        if (authState && authState.username && authState.hasPassword)
        {
            // console.log({authState});
            navigate("/");
            return;
        }
    }, [authState]);

    return (
        <Container fluid style={{height: height >= 800 ? "85vh" : height >= 540 ? "76vh" : "72vh"}} className="py-3 main-container d-flex flex-column justify-content-center align-items-center">
        {
            loading ?
            <Loader />
            :
            null
        }
        {
            errorObj ?
            <ErrorAlert
                bodyText={errorObj.message}
                onHide={() => setErrorObj(null)}
            />
            :
            null
        }
            <Card className="border-0 shadow">
                <Card.Header className="border-0 bg-main text-white">Login</Card.Header>
                <Card.Body className="d-flex flex-wrap justify-content-center align-items-center font-sm-custom">
                    <Row className="justify-content-md-center mb-3 w-100">
                        <Col lg="12">
                            <Form.Label>
                                Nama User
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={username ? username : ""}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="off"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter')
                                        handleSubmit();
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mb-3 w-100">
                        <Col lg="12">
                            <Form.Label>
                                Password
                            </Form.Label>
                            <Form.Control
                                type="password"
                                value={password ? password : ""}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="off"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter')
                                        handleSubmit();
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center g-3 w-100">
                        <Col lg="12">
                            <Button
                                variant="main"
                                type="submit"
                                className="w-100"
                                onClick={() => handleSubmit()}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter')
                                        handleSubmit();
                                }}
                                size="sm-custom"
                            >
                                Masuk
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};