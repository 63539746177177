import React, { useMemo } from "react";
import { TextBox } from '@syncfusion/ej2-inputs';
import { ComboBox } from '@syncfusion/ej2-dropdowns';
import { DatePicker } from '@syncfusion/ej2-calendars';
import { Image, OverlayTrigger, Popover } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter, Header },
}) {
    // const count = preFilteredRows.length;

    // console.log({Header});
  
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Cari ${Header.toLowerCase()}...`}
            className="w-100 border-0 round grid-filter"
        />
    )
};

export const updateCell = (ref, rowIndex, dest, value) => {
    // console.log({ref, rowIndex, value, dest});
    ref.updateCell(rowIndex, dest, value);
    // ref.saveCell();
};

export const defaultToolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
export const editOnlyToolbarOptions = ['Edit', 'Update', 'Cancel'];

export const textareaParams = (gridRef, onChange = null) => {
    let textElem;
    let textObj;

    return {
        create: () => {
            textElem = document.createElement('input');
            return textElem;
        },
        read: () => {
            return textObj ? textObj.value : null;
        },
        // destroy: () => {
        //     if (textObj)
        //         textObj.destroy();
        // },
        write: (args) => {
            // console.log({value: args.rowData[args.column.field], rowData: args.rowData, field: args.column.field})
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            textObj = new TextBox({
                // multiline: true,
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
                change: (editArgs) => {
                    // console.log({editArgs, args});
                    if (onChange)
                        onChange(editArgs, rowData, rowIndex);
                },
            });
            textObj.appendTo(textElem);
            textElem.addEventListener("input", function(e) {
                textObj.value = textElem.value;
            }.bind(this));
        }
    }
};

export const datetimeParams = () => {
    let dateElem;
    let dateObj;

    return {
        create: () => {
            dateElem = document.createElement('input');
            return dateElem;
        },
        read: (args) => {
            // console.log({args});
            return dateObj ? dateObj.value : null;
        },
        destroy: () => {
            if (dateObj)
                dateObj.destroy();
        },
        write: (args) => {
            // console.log({args});
            dateObj = new DatePicker({
                value: args.rowData[args.column.field],
                format: "dd-MM-yyyy",
                maskPlaceholder: "DD-MM-YYYY",
                allowEdit: false,
            });
            dateObj.appendTo(dateElem);
        }
    }
};

export const numericEditParams = (gridRef, onChange, min = 0) => {
    let numberElem;
    let numberObj;

    return {
        create: () => {
            numberElem = document.createElement('input');
            return numberElem;
        },
        read: (args) => {
            return numberObj ? Number(numberObj.value) : (!args.value || args.value === "" ? null : Number(args.value));
        },
        // destroy: () => {
        //     if (numberObj)
        //         numberObj.destroy();
        // },
        write: (args) => {
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            numberObj = new TextBox({
                type: "number",
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
                change: (editArgs) => {
                    // console.log({editArgs, args});
                    if (onChange)
                        onChange(editArgs, rowData, rowIndex);
                },
                min,
            });
            numberObj.appendTo(numberElem);
            numberElem.addEventListener("input", function(e) {
                numberObj.value = ((e.target.value ?? "") !== "") ? (isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)) : null;
            }.bind(this));
        }
    }
};

export const numericFloatEditParams = (gridRef, onChange, min = 0, maxValueField = null) => {
    let numberElem;
    let numberObj;

    return {
        create: () => {
            numberElem = document.createElement('input');
            return numberElem;
        },
        read: (args) => {
            return numberObj ? Number(numberObj.value) : (!args.value || args.value === "" ? null : Number(args.value));
        },
        // destroy: () => {
        //     if (numberObj)
        //         numberObj.destroy();
        // },
        write: (args) => {
            const rowData = args.rowData;
            const rowIndex = gridRef.current.getRowInfo(args.row).rowIndex;
            numberObj = new TextBox({
                type: "text",
                value: args.rowData[args.column.field],
                floatLabelType: 'Auto',
                change: (editArgs) => {
                    // console.log({editArgs, args});
                    if (onChange)
                        onChange(editArgs, rowData, rowIndex);
                },
                
            });
            numberObj.appendTo(numberElem);
            numberElem.addEventListener("input", function(e) {
                // console.log({value: e.target.value});
                const valueStr = e.target.value ? e.target.value.replace(/[^0-9 \.]/, '') : null;
                const value = valueStr ? parseFloat(valueStr) : null;
                // console.log({value, min, rowData, maxValueField, max: rowData[maxValueField]});

                if (value < min)
                {
                    // console.log("min");
                    const currentValue = parseFloat(min).toFixed(2);
                    numberObj.value = currentValue;
                    return;
                }

                if (value > rowData[maxValueField])
                {
                    const currentValue = parseFloat(rowData[maxValueField]).toFixed(2);
                    // console.log({value, min, rowData, maxValueField, max: rowData[maxValueField], currentValue});
                    numberObj.value = currentValue;
                    return;
                }

                numberObj.value = valueStr;
            }.bind(this));
            numberElem.addEventListener("blur", function(e) {
                // console.log({value: e.target.value, numberObj});
                const value = numberObj.previousValue ? parseFloat(numberObj.previousValue ?? 0) : null;
                numberObj.value = value ? parseFloat(value).toFixed(2) : null;
            }.bind(this));
        }
    }
};

export const comboboxEditParams = (gridRef, dataSource, fields, onChange, onFilterChange = null, compRef = null, createDataManager = null) => {
    let comboboxElem;
    let comboboxObj;
    let lastSelectedItemData;

    return {
        create: () => {
            comboboxElem = document.createElement('input');
            // console.log("create", {fields, dataSource});
            // gridRef.current.appendChild(comboboxElem);
            return comboboxElem;
        },
        read: (args) => {
            return comboboxObj ? comboboxObj.value : null;
        },
        destroy: () => {
            if (comboboxObj)
                comboboxObj.destroy();
        },
        write: (args) => {
            const {rowData, column, row} = args;
            const rowIndex = gridRef.current.getRowInfo(row).rowIndex;

            if (createDataManager)
                dataSource = createDataManager(rowData[column.field]);

            comboboxObj = new ComboBox({
                id: `${column.field}_${rowIndex}`,
                dataSource: dataSource,
                value: rowData[column.field],
                allowFiltering: true,
                change: (comboArgs) => {
                    // console.log("changed", {comboArgs, comboboxObj});

                    if (!comboArgs.event)
                    {
                        gridRef.current.updateCell(rowIndex, column.field, lastSelectedItemData ? lastSelectedItemData[column.field] : null); 
                        return;
                    }

                    onChange(comboArgs.itemData, column.field, rowData, rowIndex, comboArgs.item);
                    lastSelectedItemData = comboArgs.itemData;
                },
                fields: fields,
                filtering: (filterArgs) => {
                    // console.log("write - filtering", {filterArgs, dataSource, value: rowData[args.column.field], field: args.column.field, compRef});
                    if (!onFilterChange)
                        return;

                    filterArgs.preventDefaultAction = true;
                    onFilterChange(filterArgs?.text ?? "");
                },
            });
            // console.log("write", {dataSource, rowIndex, rowData, value: rowData[column.field], field: column.field, id: `${column.field}_${rowIndex}`, compRef, comboboxObj});

            if (compRef)
                compRef.current = comboboxObj;
            
            comboboxObj.appendTo(comboboxElem);
        }
    }
};

export const GridImageTemplate = (props) => {
    const {
        img,
    } = props;

    const popover = useMemo(() => (
        !img ?
        <></>
        :
        <Popover id={`img-${img.name}`} className="img-tooltip">
            <Popover.Body className="img-tooltip p-0">
                <TransformWrapper>
                    <TransformComponent>
                        <Image alt={`${img.name}`} src={img.base64} className="img-fluid img-tooltip" />
                    </TransformComponent>
                </TransformWrapper>
            </Popover.Body>
        </Popover>
    ), [img]);

    return (
        <div
            className={`w-100 h-100 text-center align-middle ${!img ? "text-danger" : "cursor-pointer"}`}
        >
            <OverlayTrigger placement="right" overlay={popover} trigger="click">
                <i className="fas fa-image"></i>
            </OverlayTrigger>
        </div>
    );
};
