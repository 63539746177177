import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Dropzone from '../../../components/Dropzone';
import { dateLocalToUtc, maxRowCountOnSingleProcess, maxRowUpload, XLSX_FILE_EXTENSION, XLSX_FILE_TYPE, XLSX_FILE_TYPE_DROPZONE } from '../../../utils/Common';
import * as XLSX from "xlsx";
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import { v4 as uuidv4 } from 'uuid';
import LoaderProgress from '../../../components/LoaderProgress';
import FileSaver from 'file-saver';
import ModalAlert from '../../../components/ModalAlert';

const timezoneOffset = (new Date()).getTimezoneOffset();

function StokOpnameUploadModal(props) {
    const {
        division,
        warehouse,
        onHide,
        onSave,
    } = props;

    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({tanggal_transaksi: moment(new Date()).format("YYYY-MM-DDTHH:mm")});
    const [filePath, setFilePath] = useState(null);
    const [rows, setRows] = useState([]);
    const [details, setDetails] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [reload, setReload] = useState(0);
    const [errorRows, setErrorRows] = useState([]);
    const [warningObj, setWarningObj] = useState(null);
    const { apiCaller } = useStateContext();

    // useEffect(() => {
    //     console.log({itemInEdit});
    //     console.log({timezoneOffset});
    // }, [itemInEdit]);

    const handleFileUploaded = useCallback((str, path) => {
        setLoading(true);
        const file = str.split(",");
        const data = XLSX.read(file[1]);
        const sheet = data.Sheets[data.SheetNames[0]];
        const {e: {r: rowCount}} = XLSX.utils.decode_range(sheet['!ref']);
        // console.log({data, sheet, rowCount});
        const iterationCount = rowCount > maxRowCountOnSingleProcess ? parseInt(Math.ceil(rowCount / maxRowCountOnSingleProcess)) : 1;
        const rowObject = [];

        for (let i = 1; i <= iterationCount; i++)
        {
            const range = `A${((i - 1) * maxRowCountOnSingleProcess) + (i === 1 ? 2 : 1)}:E${i * maxRowCountOnSingleProcess}`;
            const header = ["kode_brg", "nama_brg", "kode_satuan", "nama_satuan", "qty_opname"];
            const currentRows = XLSX.utils.sheet_to_json(data.Sheets.data, {range, header});
            // console.log({currentRows, range});
            rowObject.push(...currentRows.map(r => ({
                ...r,
                qty_opname: parseFloat(r.qty_opname ?? "0"),
            })));
        }

        // console.log({path, rowObject});

        if (rowObject.length > maxRowUpload)
        setWarningObj({
            title: "Upload Warning",
            message: "Data melebihi kapasitas maksimal upload. Data akan otomatis dikurangi.",
        });

        setRows([...rowObject.slice(0, (rowObject.length > maxRowUpload ? maxRowUpload : rowObject.length))]);
        setFilePath(path);
        setLoading(false);
        setReload(reload === 1 ? 0 : 1);
    }, [reload]);

    const apiGenerator = useCallback((uuid_import, i, errors = []) => {
        const rowCount = rows.length;
        const maxDataUpload = 100;
        const iterationCount = rowCount > maxDataUpload ? parseInt(Math.ceil(rowCount / maxDataUpload)) : 1;

        if (i >= iterationCount)
        {
            setUploadLoading(null);
            setErrorRows(errors);
            onSave();
            return;
        }

        const partialData = rows.slice(i * maxDataUpload, (i + 1) * maxDataUpload);
        const dataToSend = {
            ...itemInEdit,
            tanggal_transaksi: `${dateLocalToUtc(itemInEdit.tanggal_transaksi, "YYYY-MM-DDTHH:mm")}:00.000000Z`,
            details: partialData,
            uuid_import,
            ucode_divisi: division?.ucode,
            ucode_gudang: warehouse?.ucode,
        }
        setUploadLoading({
            now: (Math.ceil(i + 0.5) * 100 / iterationCount) >= 100 ? 99 : Math.round(Math.ceil(i + 0.5) * 100 / iterationCount),
        })
        // console.log({firstData: partialData[0], i});

        const continueProcess = () => {
            setUploadLoading({
                now: Math.floor((i + 1) * 100 / iterationCount),
            })
            apiGenerator(uuid_import, i + 1, errors);
        };

        apiCaller.post("stocktakings/data/import", JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                // setUploadLoading({
                //     now: Math.floor((i + 1) * 100 / iterationCount),
                // })
                // apiGenerator(uuid_import, i + 1);
                continueProcess();
            })
            .catch((error) => {
                console.error({error});
                // setUploadLoading(null);
                errors.push(...partialData);
                continueProcess();
            })
        ;
    }, [itemInEdit, rows, onSave, apiCaller, division, warehouse]);

    const handleSave = useCallback(() => {
        setUploadLoading({
            now: 0,
        });
        // console.log("start-----");
        const uuid = uuidv4();
        const errors = [];
        apiGenerator(uuid, 0, errors);
    }, [apiGenerator]);

    useEffect(() => {
        if (!errorRows?.length)
            return;
        
        const ws = XLSX.utils.json_to_sheet(errorRows);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
        FileSaver.saveAs(data, `data_brg_error_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
    }, [errorRows]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Nama Barang",
            accessor: "nama_brg",
        },
        {
            Header: "Kode Satuan",
            accessor: "kode_satuan",
        },
        {
            Header: "Nama Satuan",
            accessor: "nama_satuan",
        },
        {
            Header: "Qty",
            accessor: "qty_opname",
        },
    ], []);

    useEffect(() => {
        if (!rows.length)
        {
            setDetails([]);
            return;
        }

        const parsedRows = JSON.parse(JSON.stringify(rows));
        const partialData = parsedRows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        setDetails([...partialData]);
    }, [rows, pageNumber, pageSize]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={details}
                reload={reload}
                onSetPageNumber={(number) => setPageNumber(number)}
                onSetPageSize={(size) => setPageSize(size)}
                maxRowCount={rows.length}
            />
        );
    }, [details, columns, reload, rows]);

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
        {
            loading ? <Loader /> : null
        }
        {
            uploadLoading ? <LoaderProgress now={uploadLoading.now} /> : null
        }
            <Modal.Header closeButton>
                <Modal.Title>Upload Stok Opname <i>({division?.nama} - {warehouse?.nama})</i></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid className="p-0 m-0">
                    <Row className="m-0 p-0">
                        <Col lg="4" md="12" className="m-0 p-0">
                            <Form.Label>
                                Tanggal Opname:
                            </Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={itemInEdit.tanggal_transaksi ? itemInEdit.tanggal_transaksi : ""}
                                name="tanggal_transaksi"
                                onChange={(e) => {
                                    setItemInEdit({
                                        tanggal_transaksi: e.target.value
                                    });
                                }}
                                autoComplete="off"
                            />
                        </Col>
                    </Row>
                    <Row className="m-0 p-0 mt-2">
                        <Col lg="12" className="m-0 p-0">
                            <Dropzone
                                dropText={(
                                    <span>
                                    {
                                        filePath ?
                                        <b>{filePath}</b>
                                        :
                                        <>
                                            Pilih file excel Stok Opname.<br/>Tekan area ini, atau tarik file ke sini.
                                        </>
                                    }
                                    </span>
                                )}
                                setBinaryStr={(str, path) => handleFileUploaded(str, path)}
                                accept={{
                                    [XLSX_FILE_TYPE_DROPZONE]: [".xlsx"],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="m-0 p-0 mt-4">
                        <Col lg="12" className="m-0 p-0">
                        {
                            grid
                        }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} size="sm-custom">
                    Keluar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!division?.ucode || !warehouse?.ucode || !itemInEdit?.tanggal_transaksi || !details?.length}
                    size="sm-custom"
                >
                    Upload
                </Button>
            </Modal.Footer>
        {
            warningObj ?
            <ModalAlert
                bodyText={warningObj.message}
                onHide={() => setWarningObj(null)}
                onSave={() => setWarningObj(null)}
                title={warningObj.title}
                showCancelButton={false}
                confirmButtonText="Ok"
                headerClassname="bg-warning"
                centered={true}
            />
            :
            null
        }
        </Modal>
    );
}

export default StokOpnameUploadModal;
