import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import Dropzone from '../../../components/Dropzone';
import { escapeRegExp, maxRowCountOnSingleProcess, maxRowUpload, XLSX_FILE_EXTENSION, XLSX_FILE_TYPE, XLSX_FILE_TYPE_DROPZONE } from '../../../utils/Common';
import * as XLSX from "xlsx";
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import LoaderProgress from '../../../components/LoaderProgress';
import moment from 'moment';
import FileSaver from 'file-saver';
import ModalAlert from '../../../components/ModalAlert';

function CustomerItemsUploadModal(props) {
    const {
        customer,
        onHide,
        onSave,
    } = props;

    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [filePath, setFilePath] = useState(null);
    const [selectedTab, setSelectedTab] = useState("Upload");
    const [rows, setRows] = useState([]);
    const [details, setDetails] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [reload, setReload] = useState(0);
    const [errorRows, setErrorRows] = useState([]);
    const [warningObj, setWarningObj] = useState(null);
    const [dataReload, setDataReload] = useState(0);
    const { apiCaller } = useStateContext();

    const handleFileUploaded = useCallback((str, path) => {
        setLoading(true);
        const file = str.split(",");
        const data = XLSX.read(file[1]);
        const sheet = data.Sheets[data.SheetNames[0]];
        const {e: {r: rowCount}} = XLSX.utils.decode_range(sheet['!ref']);
        // console.log({data, sheet, rowCount});
        const iterationCount = rowCount > maxRowCountOnSingleProcess ? parseInt(Math.ceil(rowCount / maxRowCountOnSingleProcess)) : 1;
        const rowObject = [];

        for (let i = 1; i <= iterationCount; i++)
        {
            const range = `A${((i - 1) * maxRowCountOnSingleProcess) + 2}:D${(i * maxRowCountOnSingleProcess) + 1}`;
            const header = ["kode_brg", "nama_brg", "kode_lain", "nama_lain"];
            const currentRows = XLSX.utils.sheet_to_json(data.Sheets.data, {range, header});
            // console.log({currentRows, range});
            rowObject.push(...currentRows);
        }

        // console.log({path, rowObject});

        if (rowObject.length > maxRowUpload)
            setWarningObj({
                title: "Upload Warning",
                message: "Data melebihi kapasitas maksimal upload. Data akan otomatis dikurangi.",
            });

        setRows([...rowObject.slice(0, (rowObject.length > maxRowUpload ? maxRowUpload : rowObject.length))]);
        setFilePath(path);
        setLoading(false);
        setReload(reload === 1 ? 0 : 1);
    }, [reload]);

    const apiGenerator = useCallback((i, syncId, errors = []) => {
        const header = ["ucode_customer", "kode_brg", "nama_brg", "kode_lain", "nama_lain"];
        const headerStr = header.join(",");
        const rowCount = rows.length;
        const maxDataUpload = 100;
        const iterationCount = rowCount > maxDataUpload ? parseInt(Math.ceil(rowCount / maxDataUpload)) : 1;
        // console.log({rows, i});

        if (i >= iterationCount)
        {
            setUploadLoading(null);
            setErrorRows(errors);
            onSave();
            return;
        }

        const partialData = rows
            .slice(i * maxDataUpload, (i + 1) * maxDataUpload)
            .map(d => {
                // console.log({d});
                const mapped = [
                    `"${customer.ucode}"`,
                    `"${d.kode_brg ? d.kode_brg.toString().replace(new RegExp(escapeRegExp('"'), 'g'), '""') : ""}"`,
                    `"${d.nama_brg ? d.nama_brg.toString().replace(new RegExp(escapeRegExp('"'), 'g'), '""') : ""}"`,
                    `"${d.kode_lain ? d.kode_lain.toString().replace(new RegExp(escapeRegExp('"'), 'g'), '""') : ""}"`,
                    `"${d.nama_lain ? d.nama_lain.toString().replace(new RegExp(escapeRegExp('"'), 'g'), '""') : ""}"`,
                ];

                return mapped.join(",");
            });
        partialData.splice(0, 0, headerStr);
        const partialDataStr = partialData.join("\n");
        setUploadLoading({
            now: (Math.ceil(i + 0.5) * 100 / iterationCount) >= 100 ? 99 : Math.round(Math.ceil(i + 0.5) * 100 / iterationCount),
        })
        // console.log({firstData: partialData[0], i});

        const continueProcess = () => {
            setUploadLoading({
                now: Math.floor((i + 1) * 100 / iterationCount),
            })
            apiGenerator(i + 1, syncId, errors);
        };

        const textBlob = new Blob([partialDataStr], {type: "application/csv"});
        const filename = `import_data_${moment().format("YYYY_MM_DD_HH_mm_ss")}.csv`;
        // const csvFile = new File([textBlob], filename, {contentType: "application/csv"});

        const formData = new FormData();
        formData.append(
            "file",
            textBlob,
            filename
        );

        // console.log({formData});

        apiCaller.post(`customers/items/import/file?id=${syncId}&type=BrgCustomer`, formData, {contentType: 'multipart/form-data'})
            .then((response) => {
                // console.log({response});
                // setTimeout(() => continueProcess(), 3000);

                apiCaller.post(`customers/items/import/notify?id=${syncId}&type=BrgCustomer&cust_code=${customer.kode}`)
                    .then((response) => {
                        // console.log({response});
                        setTimeout(() => continueProcess(), 3000);
                    })
                    .catch((error) => {
                        console.error({error});
                        errors.push(...partialData);
                        setTimeout(() => continueProcess(), 3000);
                    })
                ;
            })
            .catch((error) => {
                console.error({error});
                errors.push(...partialData);
                setTimeout(() => continueProcess(), 3000);
            })
        ;
    }, [rows, customer, onSave, apiCaller]);

    const handleSave = useCallback(() => {
        setLoading(true);

        apiCaller.get("customers/items/import/getid")
            .then(response => {
                setLoading(false);
                const {data: {data: {syncId}}} = response;
                // console.log({response, syncId});

                if (!syncId)
                    return;

                setUploadLoading({
                    now: 0,
                });
                // console.log("start-----");
                const errors = [];
                apiGenerator(0, syncId, errors);
            })
            .catch(error => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, apiGenerator]);

    useEffect(() => {
        if (!errorRows?.length)
            return;
        
        const ws = XLSX.utils.json_to_sheet(errorRows);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
        FileSaver.saveAs(data, `data_brg_error_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
    }, [errorRows]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Nama Barang",
            accessor: "nama_brg",
        },
        {
            Header: "Kode Material",
            accessor: "kode_lain",
        },
        {
            Header: "Nama Material",
            accessor: "nama_lain",
        },
    ], []);

    useEffect(() => {
        if (!rows.length)
        {
            setDetails([]);
            return;
        }

        const parsedRows = JSON.parse(JSON.stringify(rows));
        const partialData = parsedRows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        setDetails([...partialData]);
    }, [rows, pageNumber, pageSize]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={details}
                reload={reload}
                onSetPageNumber={(number) => setPageNumber(number)}
                onSetPageSize={(size) => setPageSize(size)}
                maxRowCount={rows.length}
            />
        );
    }, [details, columns, reload, rows]);

    const dataGrid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="customers/items/getall"
                reload={dataReload}
                additionalParams={`id=${customer.ucode}`}
            />
        );
    }, [columns, customer, dataReload]);

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
        {
            loading ? <Loader /> : null
        }
        {
            uploadLoading ? <LoaderProgress now={uploadLoading.now} /> : null
        }
            <Modal.Header closeButton>
                <Modal.Title>Upload Barang Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey={selectedTab}
                    id="justify-tab"
                    className="mb-3"
                    onSelect={(key) => setSelectedTab(key)}
                >
                    <Tab
                        key="Upload"
                        eventKey="Upload"
                        title="Upload"
                    >
                        <Container fluid className="p-0 m-0">
                            <Row className="m-0 p-0 mt-2">
                                <Col lg="12" className="m-0 p-0">
                                    <Dropzone
                                        dropText={(
                                            <span>
                                            {
                                                filePath ?
                                                <b>{filePath}</b>
                                                :
                                                <>
                                                    Pilih file excel Barang Customer.<br/>Tekan area ini, atau tarik file ke sini.
                                                </>
                                            }
                                            </span>
                                        )}
                                        setBinaryStr={(str, path) => handleFileUploaded(str, path)}
                                        accept={{
                                            [XLSX_FILE_TYPE_DROPZONE]: [".xlsx"],
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="m-0 p-0 mt-4">
                                <Col lg="12" className="m-0 p-0">
                                {
                                    grid
                                }
                                </Col>
                            </Row>
                        </Container>
                    </Tab>
                    <Tab
                        key="Data"
                        eventKey="Data"
                        title="Data"
                    >
                        <Container fluid className="p-0 m-0">
                            <Row className="m-0 p-0 mt-2">
                                <InputGroup className="m-0 p-0">
                                    <Button size="xs-custom" variant="primary" onClick={() => setDataReload(dataReload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                                </InputGroup>
                            </Row>
                            <Row className="m-0 p-0 mt-2">
                                <Col lg="12" className="m-0 p-0">
                                {
                                    dataGrid
                                }
                                </Col>
                            </Row>
                        </Container>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} size="sm-custom">
                    Keluar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!details?.length}
                    size="sm-custom"
                >
                    Upload
                </Button>
            </Modal.Footer>
        {
            warningObj ?
            <ModalAlert
                bodyText={warningObj.message}
                onHide={() => setWarningObj(null)}
                onSave={() => setWarningObj(null)}
                title={warningObj.title}
                showCancelButton={false}
                confirmButtonText="Ok"
                headerClassname="bg-warning"
                centered={true}
            />
            :
            null
        }
        </Modal>
    );
}

export default CustomerItemsUploadModal;
