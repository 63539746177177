import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { useStateContext } from '../reducers';
import { Menu } from '../utils/Menu';

function DefaultBreadcrumb() {
    const location = useLocation().pathname.split("/");
    const { menuState: {showSidebar} } = useStateContext();

    if (location[location.length - 1] === "" || location[location.length - 1] === "print")
        return <></>;

    return (
        <Container fluid className={`mt-3 p-0 main-container w-80`}>
            <Breadcrumb className="px-3 py-2 rounded-top">
            {
                location.map((l, idx) => {
                    const title = l === "" ?
                        ""
                        :
                        Menu.filter(m => {
                            const menuPath = m.path.split("/");
                            return menuPath[menuPath.length - 1] === l;
                        })[0] ? Menu.filter(m => {
                            const menuPath = m.path.split("/");
                            return menuPath[menuPath.length - 1] === l;
                        })[0].title
                        :
                        "";

                    return (
                        <Breadcrumb.Item key={`breadcrumb_${idx}`} href="/" active={idx === location.length - 1}>
                        {
                            l === "" ?
                            <i className="fas fa-home text-dark" />
                            :
                            <span style={{textTransform: "capitalize"}} className={`text-gray ${idx === location.length - 1 ? "fw-bold fw-bolder" : ""}`}>{title}</span>
                        }
                            
                        </Breadcrumb.Item>
                    );
                })
            }
            </Breadcrumb>
        </Container>
    );
}

export default DefaultBreadcrumb;