import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import Dropzone from '../../../components/Dropzone';
import { escapeRegExp, maxRowCountOnSingleProcess, maxRowUpload, XLSX_FILE_TYPE_DROPZONE } from '../../../utils/Common';
import * as XLSX from "xlsx";
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import LoaderProgress from '../../../components/LoaderProgress';
import ModalAlert from '../../../components/ModalAlert';

function SalesOrderEditorUploadBrgModal(props) {
    const {
        headerData,
        tax,
        onHide,
        onSave,
    } = props;

    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [rows, setRows] = useState([]);
    const [detailedRows, setDetailedRows] = useState([]);
    const [details, setDetails] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [reload, setReload] = useState(0);
    const [errorRows, setErrorRows] = useState([]);
    const [warningObj, setWarningObj] = useState(null);
    const [readyToSend, setReadyToSend] = useState(false);
    const { apiCaller } = useStateContext();

    const handleFileUploaded = useCallback((str, path) => {
        setLoading(true);
        const file = str.split(",");
        const data = XLSX.read(file[1]);
        const sheet = data.Sheets[data.SheetNames[0]];
        const {e: {r: rowCount}} = XLSX.utils.decode_range(sheet['!ref']);
        // console.log({data, sheet, rowCount});
        const iterationCount = rowCount > maxRowCountOnSingleProcess ? parseInt(Math.ceil(rowCount / maxRowCountOnSingleProcess)) : 1;
        const rowObject = [];

        for (let i = 1; i <= iterationCount; i++)
        {
            const range = `A${((i - 1) * maxRowCountOnSingleProcess) + 2}:I${(i * maxRowCountOnSingleProcess) + 1}`;
            const header = ["kode_brg", "kode_kategori_brg", "qty_tersedia", "diskon"];
            const currentRows = XLSX.utils.sheet_to_json(data.Sheets.data, {range, header});
            rowObject.push(...currentRows.map(r => ({
                kode_brg: r.kode_brg ? r.kode_brg.replace(new RegExp(escapeRegExp('"'), 'g'), '""') : "",
                kode_kategori_brg: r.kode_kategori_brg ? r.kode_kategori_brg.replace(new RegExp(escapeRegExp('"'), 'g'), '""') : "",
                qty_tersedia: typeof r.qty_tersedia === "number" ? r.qty_tersedia : parseFloat(r.qty_tersedia ?? "0"),
                diskon: typeof r.diskon === "number" ? r.diskon : parseFloat(r.diskon ?? "0"),
            })));
        }

        if (rowObject.length > maxRowUpload)
            setWarningObj({
                title: "Upload Warning",
                message: "Data melebihi kapasitas maksimal upload. Data akan otomatis dikurangi.",
            });

        setRows([...rowObject.slice(0, (rowObject.length > maxRowUpload ? maxRowUpload : rowObject.length))]);
        setFilePath(path);
        setLoading(false);
        setReload(reload === 1 ? 0 : 1);
    }, [reload]);

    const loadHargaBrg = (ucode_customer, ucode_brg, ucode_satuan, ucode_kategori_brg, ucode_group_brg) => {
        if (!ucode_brg || !ucode_satuan || !ucode_kategori_brg || !ucode_group_brg || (typeof ucode_brg === "object") || (typeof ucode_satuan === "object") || (typeof ucode_kategori_brg === "object") || (typeof ucode_group_brg === "object"))
            return 0;

        return apiCaller.get(`pricelist/search/item?cust_id=${ucode_customer}&item_id=${ucode_brg}&unit_id=${ucode_satuan}&category_id=${ucode_kategori_brg}&group_id=${ucode_group_brg}`)
            .then((response) => {
                return response.data?.data?.harga ?? 0;
            })
            .catch((error) => console.error({error}))
        ;
    };

    const getHargaBrg = async (param) => {
        const result = await loadHargaBrg(headerData.ucode_customer, param.ucode_brg, param.ucode_satuan, param.ucode_kategori_brg, param.ucode_group_brg);
        return result;
    };

    const calculateRow = useCallback((harga, qty, diskon) => {
        const sub_total_bruto = parseFloat(((qty ? qty : 0) * ((harga ? harga : 0) - (diskon ? (diskon * harga / 100) : 0))).toFixed(2));
        const total_ppn = parseFloat((sub_total_bruto * (tax ? tax.pajak : 0) / 100).toFixed(2));
        const sub_total_netto = sub_total_bruto + total_ppn;

        return {
            sub_total_bruto,
            total_ppn,
            sub_total_netto
        };
    }, [tax]);

    const getDetailedRow = useCallback((index = 0, newDetailedRows = []) => {
        const currentRow = rows[index];
        // console.log({currentRow, index, newDetailedRows});

        if (!currentRow)
        {
            setDetailedRows([...newDetailedRows]);
            setReadyToSend(true);
            return;
        }

        const apiCallers = [
            apiCaller.get(`items/search/customer?id=${headerData.ucode_customer}&code=${currentRow.kode_brg}`),
            apiCaller.get(`itemcategories/search/code?filter=${currentRow.kode_kategori_brg}`),
        ];

        Promise.all(apiCallers)
            .then((responses) => {
                const {data: {data: brgDataList}} = responses[0];
                const {data: {data: kategoriBrgDataList}} = responses[1];

                if (brgDataList.length !== 1 || kategoriBrgDataList.length !== 1)
                {
                    setErrorRows([...errorRows, {...currentRow}]);
                    setUploadLoading({
                        now: Math.floor((index + 1) * 100 / rows.length),
                    });
                    getDetailedRow(index + 1, newDetailedRows);
                    return;
                }

                const brgData = brgDataList[0];

                if (newDetailedRows.map(d => d.ucode_brg).includes(brgData?.ucode ?? ""))
                {
                    setErrorRows([...errorRows, {...currentRow}]);
                    // console.log("duplicated");
                    setUploadLoading({
                        now: Math.floor((index + 1) * 100 / rows.length),
                    });
                    getDetailedRow(index + 1, newDetailedRows);
                    return;
                }

                const kategoriBrgData = kategoriBrgDataList[0];
                const currentDetailedRow = {
                    ucode_brg: brgData?.ucode,
                    kode_brg: brgData?.kode,
                    nama_brg: brgData?.nama,
                    kode_material: brgData?.kode_material,
                    nama_material: brgData?.nama_material,
                    ucode_group_brg: brgData?.ucode_group_brg,
                    nama_group_brg: brgData?.nama_group_brg,
                    group_brg: brgData?.ucode_group_brg ? {
                        ucode: brgData?.ucode_group_brg,
                        kode: brgData?.kode_group_brg,
                        nama: brgData?.nama_group_brg,
                    } : null,
                    ucode_satuan: brgData?.ucode_satuan,
                    nama_satuan: brgData?.nama_satuan,
                    satuan: brgData?.ucode_satuan ? {
                        ucode: brgData?.ucode_satuan,
                        kode: brgData?.kode_satuan,
                        nama: brgData?.nama_satuan,
                    } : null,
                    brg: brgData,
                    ucode_kategori_brg: kategoriBrgData.ucode,
                    kode_kategori_brg: kategoriBrgData.kode,
                    nama_kategori_brg: kategoriBrgData.nama,
                    kategori_brg: kategoriBrgData,
                    qty_tersedia: currentRow.qty_tersedia,
                    diskon: currentRow.diskon,
                };

                if (!brgData.ucode_satuan || !brgData.ucode_group_brg)
                {
                    newDetailedRows.push(currentDetailedRow);
                    setUploadLoading({
                        now: Math.floor((index + 1) * 100 / rows.length),
                    });
                    getDetailedRow(index + 1, newDetailedRows);
                    return;
                }

                getHargaBrg({ ucode_brg: brgData.ucode, ucode_satuan: brgData.ucode_satuan, ucode_kategori_brg: kategoriBrgData.ucode, ucode_group_brg: brgData.ucode_group_brg })
                    .then((response) => {
                        currentDetailedRow.harga = response ? Number(response) : null;
                        const { sub_total_bruto, total_ppn, sub_total_netto } = calculateRow(currentDetailedRow.harga, currentDetailedRow.qty_tersedia, currentDetailedRow.diskon);
                        currentDetailedRow.sub_total_bruto = sub_total_bruto;
                        currentDetailedRow.total_ppn = total_ppn;
                        currentDetailedRow.sub_total_netto = sub_total_netto;
                        newDetailedRows.push(currentDetailedRow);
                        setUploadLoading({
                            now: Math.floor((index + 1) * 100 / rows.length),
                        });
                        getDetailedRow(index + 1, newDetailedRows);
                    })
                    .catch((error) => {
                        console.error({error});
                        getDetailedRow(index + 1, newDetailedRows);
                    })
                ;
            })
            .catch((error) => {
                console.error({error});
                getDetailedRow(index + 1, newDetailedRows);
            })
        ;
    }, [rows, apiCaller, headerData, errorRows, calculateRow]);

    const handleSave = useCallback(() => {
        setUploadLoading({now: 0});
        getDetailedRow();
    }, [getDetailedRow]);

    useEffect(() => {
        if (!readyToSend)
            return;

        setReadyToSend(false);
        // console.log({errorRows, detailedRows});

        setUploadLoading(null);

        if (errorRows.length)
            return;

        onSave(detailedRows);
    }, [readyToSend]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Kategori Barang",
            accessor: "kode_kategori_brg",
        },
        {
            Header: "Jumlah",
            accessor: "qty_tersedia",
        },
        {
            Header: "Diskon",
            accessor: "diskon",
        },
    ], []);

    useEffect(() => {
        if (!rows.length)
        {
            setDetails([]);
            return;
        }

        const parsedRows = JSON.parse(JSON.stringify(rows));
        const partialData = parsedRows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        setDetails([...partialData]);
    }, [rows, pageNumber, pageSize]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                initData={details}
                reload={reload}
                onSetPageNumber={(number) => setPageNumber(number)}
                onSetPageSize={(size) => setPageSize(size)}
                maxRowCount={rows.length}
            />
        );
    }, [details, columns, reload, rows]);

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
        {
            loading ? <Loader /> : null
        }
        {
            uploadLoading ? <LoaderProgress now={uploadLoading.now} /> : null
        }
            <Modal.Header closeButton>
                <Modal.Title>Upload Data Barang</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid className="p-0 m-0">
                    <Row className="m-0 p-0 mt-2">
                        <Col lg="12" className="m-0 p-0">
                            <Dropzone
                                dropText={(
                                    <span>
                                    {
                                        filePath ?
                                        <b>{filePath}</b>
                                        :
                                        <>
                                            Pilih file excel Data Barang.<br/>Tekan area ini, atau tarik file ke sini.
                                        </>
                                    }
                                    </span>
                                )}
                                setBinaryStr={(str, path) => handleFileUploaded(str, path)}
                                accept={{
                                    [XLSX_FILE_TYPE_DROPZONE]: [".xlsx"],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="m-0 p-0 mt-4">
                        <Col lg="12" className="m-0 p-0">
                        {
                            grid
                        }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} size="sm-custom">
                    Keluar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!details?.length}
                    size="sm-custom"
                >
                    Upload
                </Button>
            </Modal.Footer>
        {
            warningObj ?
            <ModalAlert
                bodyText={warningObj.message}
                onHide={() => setWarningObj(null)}
                onSave={() => setWarningObj(null)}
                title={warningObj.title}
                showCancelButton={false}
                confirmButtonText="Ok"
                headerClassname="bg-warning"
                centered={true}
            />
            :
            null
        }
        {
            errorRows?.length ?
            <ModalAlert
                bodyText={(
                    <>
                        <Row className="mb-2">
                            <span class>Kode barang untuk data di bawah ini kembar:</span>
                        </Row>
                        <Row>
                        {
                            errorRows.map((e, idx) => {
                                return (
                                    <Col xs={6} key={`error_${idx}`} className="font-sm-custom">
                                        <b>
                                        {
                                            e.kode_brg
                                        }
                                        </b>
                                    </Col>
                                )
                            })
                        }
                        </Row>
                    </>
                )}
                onHide={() => setErrorRows([])}
                onSave={() => setErrorRows([])}
                title="Upload Gagal!"
                showCancelButton={false}
                confirmButtonText="Ok"
                headerClassname="bg-warning"
                centered={true}
            />
            :
            null
        }
        </Modal>
    );
}

export default SalesOrderEditorUploadBrgModal;
