import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function TerimaBayarPaymentProofEditor(props) {
    const {
        onHide,
        onSave,
        transactions,
        paymentProofs,
    } = props;

    const [ transactionProofs, setTransactionProofs ] = useState([]);
    const [ selectedTransaction, setSelectedTransaction ] = useState(null);
    const [ updatedTransactions, setUpdatedTransactions ] = useState([...transactions]);
    const [ disableSave, setDisableSave ] = useState(true);

    useEffect(() => {
        if (!selectedTransaction?.ucode_transaksi)
        {
            setTransactionProofs([]);
            return;
        }

        setTransactionProofs([...paymentProofs.filter(p => p.ucode_sales_order === selectedTransaction.ucode_transaksi)]);
    }, [selectedTransaction?.ucode_transaksi]);

    useEffect(() => {
        setDisableSave(
            updatedTransactions.filter(t => !t.ucode_bb).length > 0
        );
    }, [updatedTransactions]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Keluar
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        disabled={disableSave}
                        onClick={() => onSave(updatedTransactions)}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onHide, disableSave, onSave, updatedTransactions]);

    const updateProof = useCallback((id) => {
        // console.log({id, selectedTransaction, updatedTransactions});
        const newTrans = JSON.parse(JSON.stringify(updatedTransactions));
        const updated = newTrans.filter(t => t.ucode === selectedTransaction.ucode)[0];

        if (!updated)
            return;

        const updateIndex = newTrans.indexOf(updated);
        updated.ucode_bb = id;
        updated.tanggal_terima = new Date();
        updated.img = {
            ucode: id,
            name: paymentProofs.filter(p => p.ucode === id)[0].file_name,
            base64: paymentProofs.filter(p => p.ucode === id)[0].base64,
            created_at: paymentProofs.filter(p => p.ucode === id)[0].created_at,
        };
        newTrans.splice(updateIndex, 1, updated);
        setUpdatedTransactions([...newTrans]);
        setSelectedTransaction({...selectedTransaction, ucode_bb: id});
    }, [selectedTransaction, updatedTransactions, paymentProofs]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Bukti Pembayaran"
            titleDetails="Pencocokan dengan Sales Order"
            dialogClassName="modal-w-70"
            additionalContentClassName="shadow-lg bg-light"
            centered
            footer={footer}
        >
            <Container fluid>
                <Row className="mb-3">
                    <Col lg="6" md="12" className="p-2">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="border border-dark p-2" style={{height: "300px", overflowY: "auto"}}>
                            {
                                updatedTransactions.map((t, idx) => {
                                    return (
                                        <Row
                                            className={`m-0 p-2 cursor-pointer${selectedTransaction?.ucode_transaksi === t.ucode_transaksi ? " bg-info text-white rounded shadow" : ""}${idx > 0 ? " mt-2" : ""}`}
                                            key={`trans-${idx}`}
                                            onClick={() => setSelectedTransaction({...t})}
                                        >
                                            <Col lg="12" className="m-0 p-0">
                                            {
                                                t.no_transaksi
                                            }
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="12" className="p-2">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="border border-dark py-2 d-flex flex-wrap justify-content-center align-items-center" style={{height: "300px", overflowY: "auto"}}>
                            {
                                transactionProofs.map((d, idx) => {
                                    return (
                                        <Row className={`m-0 p-2 w-100 cursor-pointer${idx > 0 ? " mt-2" : ""}${d.ucode === selectedTransaction.ucode_bb ? " bg-info rounded shadow" : ""}`} key={`trans-${idx}`}>
                                            <Col
                                                lg="12"
                                                onClick={() => updateProof(d.ucode)}
                                                className="d-flex flex-wrap justify-content-center align-items-center"
                                            >
                                                <Col lg="4">
                                                    <TransformWrapper>
                                                        <TransformComponent>
                                                            <Image alt={`${d.name}`} src={d.base64} className="img-fluid img-thumbnail" />
                                                        </TransformComponent>
                                                    </TransformWrapper>
                                                </Col>
                                                <Col lg="7" className="ms-2">
                                                    <span
                                                        className={`${selectedTransaction?.ucode_bb === d.ucode ? " text-white" : ""}`}
                                                    >
                                                        Tanggal Upload: {moment(d.created_at).format("DD-MM-YYYY HH:mm:ss")}
                                                    </span>
                                                </Col>
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default TerimaBayarPaymentProofEditor;
