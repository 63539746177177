import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useStateContext } from '../../reducers';
import { useWindowDimensions } from '../../utils/Common';

const strongPasswordCategories = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
}

export default function ChangePassword(props) {
    const [ loading, setLoading ] = useState(false);
    const [ password, setPassword ] = useState(null);
    const [ passwordConfirmation, setPasswordConfirmation ] = useState(null);
    const [ canSubmit, setCanSubmit ] = useState(false);
    const { authState, setAuthState, apiCaller } = useStateContext();
    const {height} = useWindowDimensions();
    const navigate = useNavigate();

    const handleSubmit = useCallback(() => {
        if (!authState || !authState.username || !password || password !== passwordConfirmation || password === "")
            return;

        setLoading(true);
        // console.log({password});
        apiCaller.post("users/change-password", {
            username: authState.username,
            password
        })
        .then((response) => {
            // console.log({response});
            setAuthState({type: "login", ...response.data.data});
            setLoading(false);
        })
        .catch(error => {
            console.error({error});
            setLoading(false);
        })
    }, [authState, password, passwordConfirmation, setAuthState, apiCaller]);

    useEffect(() => {
        // console.log({authState});

        if (!authState || !authState.username)
        {
            navigate("/login");
            return;
        }
    }, [authState]);

    useEffect(() => {
        setCanSubmit(password && passwordConfirmation && password === passwordConfirmation && validator.isStrongPassword(password, strongPasswordCategories));
    }, [password, passwordConfirmation]);

    return (
        <Container
            fluid
            className={`font-sm-custom ${authState && authState.username && !authState.hasPassword ? "py-3 mt-5 d-flex flex-column justify-content-center align-items-center bg-white rounded w-50" : `p-3 rounded bg-white main-container w-50`}`}
        >
        {
            loading ?
            <Loader />
            :
            null
        }
            <Row className="d-flex flex-wrap justify-content-center align-items-center m-0 p-0 mb-3 w-100">
                <Col lg="12">
                    {!authState.hasPassword ? <>Selamat datang <b>{authState.username}</b>! Silakan tentukan password Anda.<br /></> : null}
                    <span className="font-sm-custom text-danger">Password minimal sepanjang 8 karakter dan harus berisi huruf besar, huruf kecil, dan angka.</span>
                </Col>
            </Row>
            <Row className="d-flex flex-wrap justify-content-center align-items-center m-0 p-0 mb-3 w-100">
                <Col lg="12" className="mb-3">
                    <Form.Label>
                        Password: <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        value={password ? password : ""}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="off"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter')
                                handleSubmit();
                        }}
                    />
                </Col>
                <Col lg="12">
                    <Form.Label>
                        Konfirmasi Password: <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                        type="password"
                        value={passwordConfirmation ? passwordConfirmation : ""}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        autoComplete="off"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter')
                                handleSubmit();
                        }}
                    />
                {
                    password && password !== "" && passwordConfirmation && passwordConfirmation !== "" && passwordConfirmation !== password ?
                    <Form.Label className="font-sm-custom">
                        <i>Konfirmasi password tidak sama</i>
                    </Form.Label>
                    :
                    null
                }
                </Col>
            </Row>
            <Row className="d-flex flex-wrap justify-content-center align-items-center m-0 p-0 w-100">
                <Col lg="12">
                    <Button
                        variant="main"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSubmit()}
                        disabled={!canSubmit}
                        size="sm-custom"
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};