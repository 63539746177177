export const authInitialState = {username: null, emp_name: null, div_code: null, token: null, hasPassword: false, level: 0, override_status: false, menus: [], [process.env.REACT_APP_BUILD_ID]: 1};

export default function authReducer(state, action) {
    switch (action.type) {
        case 'login':
            // console.log({action});
            return {username: action.username, emp_name: action.emp_name, div_id: action.div_id, div_code: action.div_code, token: action.token, hasPassword: action.hasPassword, level: parseInt(action.level), override_status: action.override_status, menus: action.menus, [process.env.REACT_APP_BUILD_ID]: 1};
        case 'refresh':
            return {username: action.username, emp_name: action.emp_name, div_id: action.div_id, div_code: action.div_code, token: action.token, hasPassword: action.hasPassword, level: parseInt(action.level), override_status: action.override_status, menus: action.menus, [process.env.REACT_APP_BUILD_ID]: 1};
        case 'logout':
            if('caches' in window)
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    });
                });
            
            localStorage.clear();
            return {...authInitialState};
        default:
            throw new Error();
    }
}
