import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Inject } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, GridComponent, Resize } from "@syncfusion/ej2-react-grids";
import { componentToHex, dateLocalToUtc, getCurrencyDisplay, JPEG_FILE_EXTENSION, JPEG_FILE_TYPE_DROPZONE, JPG_FILE_EXTENSION, JPG_FILE_TYPE_DROPZONE, objectWithoutProperties, PNG_FILE_EXTENSION, PNG_FILE_TYPE_DROPZONE, separateBase64String, SO_STATUS_CANCELED, SO_STATUS_CONFIRMED, SO_STATUS_PROCESS, useWindowDimensions, XLSX_FILE_EXTENSION, XLSX_FILE_TYPE } from "../../../utils/Common";
import { Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { useStateContext } from "../../../reducers";
import { handleChangeSOStatus } from "../../../utils/api/sales";
import ModalAlert from "../../../components/ModalAlert";
import Dropzone from '../../../components/Dropzone';
import { ComboBox } from "../../../components/Dropdowns";
import SalesOrderItemEditor from "./SalesOrderItemEditor";
import FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import SalesOrderEditorUploadBrgModal from "./SalesOrderEditorUploadBrgModal";

const readonlyColumns = [
    "nama_brg",
    "nama_satuan",
    "qty_stok",
    "qty_request",
    "sub_total_bruto",
    "total_ppn",
    "sub_total_netto",
];

function SalesOrderEditor(props) {
    const {
        onHide,
        data,
        statusList,
        onSave
    } = props;

    const { apiCaller, authState } = useStateContext();
    const gridRef = useRef(null);
    const [itemInEdit, setItemInEdit] = useState({...data, tanggal_transaksi: !data.ucode ? moment().format("YYYY-MM-DDTHH:mm") : moment.utc(data.tanggal_transaksi).local().format("YYYY-MM-DDTHH:mm")});
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [items, setItems] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [disableCancel, setDisableCancel] = useState(true);
    const [errorObj, setErrorObj] = useState(null);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [autoSummarize, setAutoSummarize] = useState(false);
    const [tax, setTax] = useState(null);
    const {height} = useWindowDimensions();
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [detailAddMode, setDetailAddMode] = useState(false);
    const [detailEditMode, setDetailEditMode] = useState(false);
    const [detailEditDoubleClickMode, setDetailEditDoubleClickMode] = useState(null);
    const [lastNewDetailUcode, setLastNewDetailUcode] = useState(1);
    const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const loadData = useCallback(() => {
        setLoading(true);
        const apiCallers = [
            apiCaller.get(`taxes/search/code?code=PPN`),
        ]

        if (data?.ucode)
            apiCallers.push(apiCaller.get(`salesorders/detail/all?id=${data.ucode}`));

        if (data.ucode_customer)
            apiCallers.push(apiCaller.get(`customers/search/id?id=${data.ucode_customer}`));

        if (data.ucode_gudang)
            apiCallers.push(apiCaller.get(`warehouses/search/id?id=${data.ucode_gudang}`));
        
        Promise.all(apiCallers)
            .then((responses) => {
                // console.log({responses});
                setTax(responses[0].data.data.length ? responses[0].data.data[0] : null);
                let newItemInEdit = {...itemInEdit};

                if (data?.ucode)
                {
                    newItemInEdit = {...itemInEdit, bukti_pesan: responses[1].data.data.image.bukti_pesan, tipe_file_bukti_pesan: responses[1].data.data.image.tipe_file_bukti_pesan};
                    setDetails(responses[1].data.data.details);
                    setItems(responses[1].data.data.items);
                }

                if (data.ucode_customer)
                {
                    const { data: {data: selected_customer} } = responses[2];
                    newItemInEdit.customer = selected_customer;
                }

                if (data.ucode_gudang)
                {
                    const { data: {data: selected_gudang} } = responses[3];
                    newItemInEdit.gudang = selected_gudang;
                }
                // console.log({newItemInEdit});
                setItemInEdit({...newItemInEdit});
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [data, apiCaller, itemInEdit]);

    useEffect(() => loadData(), [data.ucode]);
    
    useEffect(() => {
        const detailIds = details.filter(d => Number.isInteger(d.ucode)).map(d => d.ucode);

        if (!detailIds?.length)
            return;

        detailIds.sort((a, b) => {
            if (parseInt(a) < parseInt(b))
                return -1;

            if (parseInt(a) > parseInt(b))
                return 1;

            return 0;
        });
        detailIds.reverse();
        // console.log({lastId: detailIds[0], currentId: detailIds[0] + 1, detailIds});
        setLastNewDetailUcode(detailIds[0] + 1);
    }, [details]);

    // useEffect(() => console.log({itemInEdit}), [itemInEdit]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        // console.log({value, name});
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    const summarize = useCallback(() => {
        const currentDetails = JSON.parse(JSON.stringify(details));
        let grand_total_bruto = 0;
        let total_ppn = 0;
        let grand_total_netto = 0;
        
        currentDetails.forEach(d => {
            grand_total_bruto += d.sub_total_bruto;
            total_ppn += d.total_ppn;
            grand_total_netto += d.sub_total_netto;
        });

        return {
            grand_total_bruto,
            total_ppn,
            grand_total_netto,
        }
    }, [details]);

    const handleSummarize = useCallback(() => {
        const {
            grand_total_bruto,
            total_ppn,
            grand_total_netto
        } = summarize();

        setItemInEdit({...itemInEdit, grand_total_bruto, total_ppn, grand_total_netto});
    }, [itemInEdit, summarize]);

    useEffect(() => {
        if (!autoSummarize)
            return;

        handleSummarize();
        setAutoSummarize(false);
    }, [autoSummarize]);

    const onCustomerSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_customer: val?.ucode,
            customer: val,
            alamat_kirim: val?.alamat_kirim,
        });
    }, [itemInEdit]);

    const onWarehouseSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_gudang: val?.ucode,
            gudang: val,
        });
    }, [itemInEdit]);

    const validate = useCallback(() => {
        // console.log({itemInEdit, details, check: details.filter(d => !d.qty_tersedia).length});
        let currentDisableSave = !itemInEdit || !itemInEdit.tanggal_transaksi || !itemInEdit.ucode_customer || !itemInEdit.grand_total_netto || (itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)) || !details.length || details.filter(d => !d.ucode_group_brg || !d.ucode_kategori_brg).length > 0;
        // console.log({currentDisableSave});

        if (!currentDisableSave)
        {
            const qty_list = [];
    
            details.forEach(d => {
                let qty_item = qty_list.filter(r => r.ucode_brg === d.ucode_brg)[0];

                if (!qty_item)
                {
                    qty_list.push({
                        ucode_brg: d.ucode_brg,
                        qty_request: d.qty_request,
                        qty_tersedia: d.qty_tersedia,
                        qty_stok: d.qty_stok,
                    });
                    return;
                }

                qty_item.qty_tersedia += d.qty_tersedia ?? 0;
            });
            
            // console.log({qty_list});
            currentDisableSave = qty_list.filter(d => !d.qty_tersedia || (data.dari_customer && d.qty_tersedia > d.qty_request)).length > 0;
        }

        const currentDisableCancel = itemInEdit && itemInEdit.kode_status !== SO_STATUS_PROCESS;
        return {
            disableSave: currentDisableSave,
            disableCancel: currentDisableCancel,
        };
    }, [data, itemInEdit, details, authState]);

    const handleSave = useCallback((approve = false) => {
        if (!itemInEdit)
            return;

        const {
            disableSave: currentDisableSave,
        } = validate();

        if (currentDisableSave)
            return;
        
        setLoading(true);
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "customer",
            "gudang",
            "has_complete_sj",
        ])};

        if (!data?.dari_customer)
            dataToSend.tanggal_transaksi = dateLocalToUtc(dataToSend.tanggal_transaksi, 'YYYY-MM-DDTHH:mm:ss');

        dataToSend.details = [
            ...details.map(d => ({
                ...objectWithoutProperties(d, [
                    "kode_material",
                    "nama_material",
                    "kode_brg",
                    "nama_brg",
                    "kode_satuan",
                    "nama_satuan",
                    "kode_group_brg",
                    "nama_group_brg",
                    "kode_kategori_brg",
                    "nama_kategori_brg",
                    "brg",
                    "satuan",
                    "group_brg",
                    "kategori_brg",
                ]),
                qty_request: data?.dari_customer ? d.qty_request : 0,
                ucode: Number.isInteger(d.ucode) ? null : d.ucode,
                diskon: d.diskon ?? null,
            })
        )];
        // console.log({dataToSend});
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`salesorders`, JSON.stringify(dataToSend)) : apiCaller.put(`salesorders/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                if (!approve)
                {
                    setLoading(false);
                    onSave();
                    return;
                }

                handleChangeSOStatus(
                    dataToSend.ucode ? dataToSend.ucode : response.data.data.ucode,
                    SO_STATUS_CONFIRMED,
                    apiCaller,
                    () => {
                        setLoading(false);
                        onSave();
                    },
                    (error) => {
                        console.error({error});
                        setLoading(false);
                    },
                );
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [data, itemInEdit, details, apiCaller, validate, onSave]);

    useEffect(() => {
        // console.log({itemInEdit, details});
        const {
            disableSave: currentDisableSave,
            disableCancel: currentDisableCancel,
        } = validate();

        // console.log({details});

        setDisableSave(currentDisableSave);
        setDisableCancel(currentDisableCancel);
    }, [itemInEdit, details]);

    const handleDownloadTemplate = useCallback(() => {
        const result = [{
            kode_brg: "",
            kode_kategori_brg: "",
            qty_tersedia: 0,
            diskon: 0,
        }];
        const ws = XLSX.utils.json_to_sheet(result);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
        FileSaver.saveAs(data, `sales_order_detail_template_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
    }, []);

    const handleStockChecking = useCallback(() => {
        if (!itemInEdit?.ucode_customer || !details.filter(d => d.ucode_brg).length)
            return;

        setLoading(true);
        const dataToSend = {item_ids: details.map(d => d.ucode_brg)};
        // console.log({dataToSend});

        apiCaller.post(`salesorders/stock/check?cust_id=${itemInEdit.ucode_customer}${itemInEdit.ucode_gudang ? `&wh_id=${itemInEdit.ucode_gudang}` : ""}`, JSON.stringify(dataToSend))
            .then((response) => {
                // console.log({response});
                const {data: {data: stockData}} = response;
                const newDetails = JSON.parse(JSON.stringify(details));
                newDetails.forEach(d => {
                    const currentItem = stockData.filter(s => s.ucode_brg === d.ucode_brg)[0];

                    if (!currentItem)
                        return;

                    d.qty_stok = (currentItem.qty_masuk ?? 0) - (currentItem.qty_keluar ?? 0);
                });
                setDetails([...newDetails]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, itemInEdit, details]);

    useEffect(() => {
        if (!detailEditDoubleClickMode)
            return;

        const newSelectedDetail = JSON.parse(JSON.stringify(detailEditDoubleClickMode));
        setSelectedDetail(newSelectedDetail);
        setDetailEditMode(true);
        setDetailEditDoubleClickMode(null);
    }, [detailEditDoubleClickMode]);

    const grid = (
        <>
            <div className="e-control e-toolbar e-lib e-keyboard d-flex flex-wrap justify-content-between align-items-center">
                <div className="e-toolbar-items">
                    <div className="e-toolbar-item">
                        <Button
                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                            disabled={!itemInEdit.ucode_customer || (itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order))}
                            onClick={() => setDetailAddMode(true)}
                        >
                            <i className="fas fa-plus" /><span className="e-tbar-btn-text">Add</span>
                        </Button>
                    </div>
                    <div className="e-toolbar-item">
                        <Button
                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                            disabled={!itemInEdit.ucode_customer || (itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)) || !selectedDetail}
                            onClick={() => setDetailEditMode(true)}
                        >
                            <i className="fas fa-edit" /><span className="e-tbar-btn-text">Edit</span>
                        </Button>
                    </div>
                    <div className="e-toolbar-item">
                        <Button
                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                            disabled={!itemInEdit.ucode_customer || (itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)) || data?.dari_customer || !selectedDetail}
                            onClick={() => {
                                const newDetails = JSON.parse(JSON.stringify(details));
                                const currentDetailIndex = newDetails.indexOf(newDetails.filter(d => d.ucode === selectedDetail.ucode)[0]);
                                
                                if (currentDetailIndex < 0)
                                    return;

                                newDetails.splice(currentDetailIndex, 1);
                                setDetails(newDetails);
                                setAutoSummarize(true);
                            }}
                        >
                            <i className="fas fa-eraser me-1" /><span className="e-tbar-btn-text">Delete</span>
                        </Button>
                    </div>
                    <div className="e-toolbar-item">
                        <Button className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" disabled={!details?.length} onClick={() => handleStockChecking()}>
                            <i className="fas fa-sync-alt me-1" /><span className="e-tbar-btn-text">Cek Stok</span>
                        </Button>
                    </div>
                {
                    !data?.dari_customer ?
                    <>
                        <div className="e-toolbar-item">
                            <Button className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" onClick={() => handleDownloadTemplate()}>
                                <i className="fas fa-file-download me-1" /><span className="e-tbar-btn-text">Download Template</span>
                            </Button>
                        </div>
                        <div className="e-toolbar-item">
                            <Button className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib" disabled={(itemInEdit?.ucode_customer) ? false : true} onClick={() => details?.length ? setShowUploadConfirmation(true) : setShowUploadModal(true)}>
                                <i className="fas fa-file-upload me-1" /><span className="e-tbar-btn-text">Upload Detail</span>
                            </Button>
                        </div>
                    </>
                    :
                    null
                }
                </div>
                <div className="e-toolbar-items">
                    <div className="e-toolbar-item">
                        <Form.Label className="m-0 p-0 me-2">({details?.length ?? 0})</Form.Label>
                    </div>
                </div>
            </div>
            <GridComponent
                dataSource={details}
                allowResizing={true}
                ref={(ref) => gridRef.current = ref}
                height={height >= 960 ? 230 : 150}
                dataBound={(params) => {
                    const emptyRow = document.querySelector('.e-emptyrow');
                    if (emptyRow)
                    {
                        emptyRow.cells[0].innerText = "Data tidak ditemukan.";
                        return;
                    }
                }}
                queryCellInfo={(args) => {
                    if (readonlyColumns.includes(args.column.field)) { 
                        const val = ("255,219,219").split(",");
                        const str = "#" + componentToHex(parseInt(val[0])) + componentToHex(parseInt(val[1])) + componentToHex(parseInt(val[2]));
                        args.cell.bgColor = str;
                    }
                }}
                rowSelected={(args) => {
                    setSelectedDetail(args.data);
                }}
                rowDeselected={() => {
                    setSelectedDetail(null);
                }}
                recordDoubleClick={(args) => {
                    setDetailEditDoubleClickMode(args.rowData);
                }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='ucode' headerText='ID' textAlign='Left' width={120} isPrimaryKey={true} isIdentity={true} visible={false} />
                    <ColumnDirective field='ucode_brg' headerText='ID BRG' textAlign='Left' width={120} visible={false} />

                {
                    itemInEdit?.customer?.punya_kode_brg_sendiri ?
                    <ColumnDirective field='kode_material' headerText='No. Material' textAlign='Left' width={160} type="string" />
                    :
                    null
                }
                    <ColumnDirective field='kode_brg' headerText='Kode Barang' textAlign='Left' width={160} type="string" />
                    <ColumnDirective field='nama_brg' headerText='Nama Barang' textAlign='Left' width={200} type="string" />
                    <ColumnDirective field='ucode_satuan' headerText='ID SATUAN' textAlign='Left' width={120} visible={false} />
                    <ColumnDirective field='nama_satuan' headerText='Satuan' textAlign='Left' width={80} type="string" />
                    <ColumnDirective field='ucode_kategori_brg' headerText='ID KATEGORI' textAlign='Left' width={120} visible={false} />
                    <ColumnDirective field='nama_kategori_brg' headerText='Kategori Barang' textAlign='Left' width={120} type="string" />
                    <ColumnDirective field='ucode_group_brg' headerText='ID GROUP' textAlign='Left' width={120} visible={false} />
                    <ColumnDirective field='nama_group_brg' headerText='Grup Barang' textAlign='Left' width={120} type="string" />
                    <ColumnDirective field='harga' headerText='Harga' headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    <ColumnDirective field="qty_stok" headerText="Qty Stok" headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                
                {
                    data?.dari_customer ?
                    <ColumnDirective field='qty_request' headerText='Qty Request' headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    :
                    null
                }
                    <ColumnDirective field='qty_tersedia' headerText={data?.dari_customer ? 'Qty Tersedia' : "Qty"} headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    <ColumnDirective field='diskon' headerText='Diskon (%)' headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    <ColumnDirective field='sub_total_bruto' headerText='Sub Total' headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    <ColumnDirective field='total_ppn' headerText='PPN (Rp)' headerTextAlign="Left" textAlign='Right' width={80} format="N2" />
                    <ColumnDirective field='sub_total_netto' headerText='Sub Total Net' headerTextAlign="Left" textAlign='Right' width={100} format="N2" />
                    <ColumnDirective field='keterangan' headerText='Keterangan' textAlign='Left' width={120} />
                </ColumnsDirective>
                <Inject services={[Edit, Toolbar, Resize]}/>
            </GridComponent>
        </>
    );

    const handleImageSelected = useCallback((base64Str, fileStrKey, fileTypeKey) => {
        const separationResult = separateBase64String(base64Str);
        setItemInEdit({...itemInEdit, [fileStrKey]: separationResult.base64Str, [fileTypeKey]: separationResult.mimeType});
    }, [itemInEdit]);

    const footer = useMemo(() => {
        return (
            <Container fluid>
                <Row className="justify-content-md-end">
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="secondary"
                            type="submit"
                            className="w-100"
                            onClick={() => onHide()}
                            size="sm-custom"
                        >
                            Keluar
                        </Button>
                    </Col>
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="success"
                            type="submit"
                            className="w-100"
                            onClick={() => handleSave()}
                            disabled={disableSave}
                            size="sm-custom"
                        >
                            Simpan
                        </Button>
                    </Col>
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100"
                            onClick={() => {
                                setConfirmationObj({
                                    title: "Konfirmasi Auto Approve",
                                    message: <span>Data akan disimpan, status Sales Order akan diubah ke <b className="text-success">{statusList && statusList.length ? statusList.filter(s => s.kode === SO_STATUS_CONFIRMED)[0].nama : ""}</b> dan Sales Order tidak akan bisa diubah. Lanjutkan?</span>,
                                    onConfirmed: () => {
                                        setConfirmationObj(null);
                                        handleSave(true);
                                    },
                                });
                            }}
                            disabled={disableSave || (!data?.dari_customer && (!itemInEdit?.no_purchase_order))}
                            size="sm-custom"
                        >
                            Approve
                        </Button>
                    </Col>
                    <Col xl="2" lg="3" md="12" className="m-0 p-0 px-1 mb-2">
                        <Button
                            variant="danger"
                            type="submit"
                            className="w-100"
                            onClick={() => {
                                setConfirmationObj({
                                    title: "Konfirmasi Pembatalan",
                                    message: <span>Status Sales Order akan diubah ke <b className="text-danger">{statusList && statusList.length ? statusList.filter(s => s.kode === SO_STATUS_CANCELED)[0].nama : ""}</b> dan Sales Order tidak akan bisa diubah. Lanjutkan?</span>,
                                    onConfirmed: () => {
                                        setConfirmationObj(null);
                                        setLoading(true);
                                        handleChangeSOStatus(
                                            itemInEdit.ucode,
                                            SO_STATUS_CANCELED,
                                            apiCaller,
                                            () => {
                                                setLoading(false);
                                                onSave();
                                            },
                                            (error) => {
                                                console.error({error});
                                                setLoading(false);
                                            },
                                        );
                                    },
                                });
                            }}
                            disabled={disableCancel}
                            size="sm-custom"
                        >
                            Batal
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }, [disableSave, disableCancel, onHide, handleSave, apiCaller, itemInEdit, onSave, statusList, data]);

    const notes = useMemo(() => {
        const result = itemInEdit?.updated_by ?
            [
                <>Status: <u>{`${itemInEdit?.nama_status ?? "N/A"}`}</u> oleh <u>{itemInEdit?.updated_by}</u> pada <u>{itemInEdit?.updated_at ? moment(itemInEdit?.updated_at).format("DD-MM-YYYY HH:mm") : "N/A"}</u></>,
                <>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</>,
            ]
            :
            [
                <>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</>,
            ];

        if (itemInEdit?.dari_customer)
            result.push(<>Maksimal jumlah Qty Tersedia adalah sama dengan Qty Request</>);

        result.push(<>Data hanya bisa disimpan setelah proses cek stok dilakukan untuk setiap barang.</>);
        return result;
    }, [itemInEdit]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Sales Order"
            titleDetails={`${data.ucode ? "Edit" : "Baru"}${data?.dari_customer ? " (request customer)" : ""}`}
            dialogClassName={`modal-w-80${detailAddMode || detailEditMode || showUploadConfirmation || showUploadModal ? " fade" : ""}`}
            footer={footer}
            notes={notes}
            useDefaultNotes={false}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col lg="3" md="12" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    No. Sales Order:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? itemInEdit.no_transaksi : null}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                    {
                        data?.dari_customer ?
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Tanggal:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? moment.utc(itemInEdit.tanggal_transaksi).local().format("DD-MM-YYYY HH:mm") : null}
                                </span>
                            </Col>
                        </Row>
                        :
                        <>
                            <Form.Label>
                                Tanggal: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={itemInEdit && itemInEdit.tanggal_transaksi ? itemInEdit.tanggal_transaksi : ""}
                                name="tanggal_transaksi"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                            />
                        </>
                    }
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                    {
                        data?.dari_customer ?
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Customer:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? itemInEdit.nama_customer : null}
                                </span>
                            </Col>
                        </Row>
                        :
                        <>
                            <Form.Label>
                                Customer: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="customers/search/name/division"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onCustomerSelected(val)}
                                selectedItem={itemInEdit?.customer}
                                authState={authState}
                                minLength={0}
                                disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                            />
                        </>
                    }
                    </Col>
                    <Col lg="3" md="12" className="mb-3">
                        <Form.Label>
                            Gudang:
                        </Form.Label>
                        <ComboBox
                            endpoint="warehouses/search/division"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onWarehouseSelected(val)}
                            selectedItem={itemInEdit?.gudang}
                            authState={authState}
                            minLength={0}
                            disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                            additionalParam={{
                                id: authState?.div_id
                            }}
                        />
                    </Col>
                {
                    !data?.dari_customer ?
                    <>
                        <Col lg="3" md="12" className="mb-3">
                            <Row className="m-0 p-0">
                                <Col lg="12" className="m-0 p-0">
                                    <Form.Label>
                                        No. Purchase Order: <span className="text-danger">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg="12" className="m-0 p-0">
                                    <Form.Control
                                        value={itemInEdit !== null && itemInEdit.no_purchase_order ? itemInEdit.no_purchase_order : ""}
                                        name="no_purchase_order"
                                        onChange={(e) => {
                                            handleTextChanged(e);
                                        }}
                                        autoComplete="off"
                                        disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12" className="mb-3">
                            <Row className="m-0 p-0">
                                <Col lg="12" className="m-0 p-0">
                                    <Form.Label>
                                        Bukti Pemesanan: {
                                            itemInEdit?.bukti_pesan ?
                                            <>
                                                <br />
                                                <Button
                                                    variant="danger"
                                                    type="submit"
                                                    onClick={() => {
                                                        setItemInEdit({...itemInEdit, bukti_pesan: null, tipe_file_bukti_pesan: null});
                                                    }}
                                                    size="sm-custom"
                                                >
                                                    <i className="fas fa-eraser" />
                                                </Button>
                                            </>
                                            :
                                            null
                                        }
                                    </Form.Label>
                                </Col>
                            </Row>
                            <Row className="m-0 p-0">
                                <Col lg={itemInEdit?.bukti_pesan ? 6 : 12} className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                    <Dropzone
                                        dropText={(
                                            <span>
                                                Pilih bukti pemesanan.<br/>Tekan area ini, atau tarik foto ke sini.
                                            </span>
                                        )}
                                        setBinaryStr={(str) => handleImageSelected(str, "bukti_pesan", "tipe_file_bukti_pesan")}
                                        accept={{
                                            [PNG_FILE_TYPE_DROPZONE]: [PNG_FILE_EXTENSION],
                                            [JPG_FILE_TYPE_DROPZONE]: [JPG_FILE_EXTENSION],
                                            [JPEG_FILE_TYPE_DROPZONE]: [JPEG_FILE_EXTENSION],
                                        }}
                                    />
                                </Col>
                            {
                                itemInEdit?.bukti_pesan ?
                                <Col lg="6" className="m-0 p-0 d-flex flex-wrap justify-content-center align-items-center">
                                    <Image src={`data:${itemInEdit.tipe_file_bukti_pesan};base64,${itemInEdit.bukti_pesan}`} className="img-preview" />
                                </Col>
                                :
                                null
                            }
                            </Row>
                        </Col>
                    </>
                    :
                    null
                }
                    <Col lg="12" className="mb-3">
                    {
                        data?.dari_customer ?
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Alamat Kirim:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? itemInEdit.alamat_kirim : null}
                                </span>
                            </Col>
                        </Row>
                        :
                        <>
                            <Form.Label>
                                Alamat Kirim:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.alamat_kirim ? itemInEdit.alamat_kirim : ""}
                                name="alamat_kirim"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={3}
                                disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                            />
                        </>
                    }
                    </Col>
                </Row>
                <Row className="mb-3">
                    {/* <Col lg="12" className="mb-2">
                        <Button size="sm-custom" onClick={() => handleSummarize()} disabled={(itemInEdit && itemInEdit.jumlah_sj > 0) || details.filter(d => !d.qty_tersedia || !d.sub_total_netto).length > 0}><i className="fas fa-calculator me-2" />Hitung</Button>
                    </Col> */}
                    <Col lg="12">
                        {grid}
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg="9"/>
                    <Col lg="3" className="mb-3">
                        <Row className="m-0 p-0">
                            <Col lg="12" className="m-0 p-0">
                                <Form.Label>
                                    Grand Total Nett:
                                </Form.Label>
                            </Col>
                            <Col lg="12" className="m-0 p-0">
                                <span className="fw-bold w-100">
                                    {itemInEdit !== null ? getCurrencyDisplay(itemInEdit?.grand_total_netto ?? 0) : null}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={3}
                            disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                        />
                    </Col>
                    <Col lg="6" md="12" className="mb-3">
                        <Form.Label>
                            Keterangan Operator:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan_operator ? itemInEdit.keterangan_operator : ""}
                            name="keterangan_operator"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={3}
                            disabled={itemInEdit && itemInEdit.kode_status && itemInEdit.kode_status !== SO_STATUS_PROCESS && (!authState.override_status || itemInEdit.has_delivery_order)}
                        />
                    </Col>
                </Row>
            </Container>
        {
            detailAddMode || detailEditMode ?
            <SalesOrderItemEditor
                headerData={itemInEdit}
                data={detailAddMode ? {ucode: lastNewDetailUcode} : selectedDetail}
                items={items}
                tax={tax}
                onClose={() => {
                    if (detailAddMode)
                        setDetailAddMode(false);

                    if (detailEditMode)
                        setDetailEditMode(false);
                }}
                onSave={(item) => {
                    const newDetails = JSON.parse(JSON.stringify(details));
                    const currentDetailIndex = newDetails.indexOf(newDetails.filter(d => d.ucode === item.ucode)[0]);
                    // console.log({newDetails, item, currentDetailIndex});
                    
                    if (currentDetailIndex >= 0)
                        newDetails.splice(currentDetailIndex, 1, item);
                    
                    if (currentDetailIndex < 0)
                        newDetails.push(item);

                    setDetails(newDetails);
                    if (detailAddMode)
                        setDetailAddMode(false);

                    if (detailEditMode)
                        setDetailEditMode(false);

                    setAutoSummarize(true);
                }}
            />
            :
            null
        }
        {
            errorObj ?
            <ModalAlert
                onHide={() => setErrorObj(null)}
                onSave={() => setErrorObj(null)}
                title={errorObj.title}
                bodyText={errorObj.message}
                showCancelButton={false}
                confirmButtonText="OK"
            />
            :
            null
        }
        {
            confirmationObj ?
            <ModalAlert
                onHide={() => setConfirmationObj(null)}
                onSave={() => confirmationObj.onConfirmed()}
                title={confirmationObj.title}
                bodyText={confirmationObj.message}
            />
            :
            null
        }
        {
            showUploadConfirmation ?
            <ModalAlert
                onHide={() => setShowUploadConfirmation(false)}
                onSave={() => {
                    setShowUploadConfirmation(false);
                    setShowUploadModal(true);
                }}
                title="Konfirmasi Upload Data Barang"
                bodyText="Data barang yang ada akan diganti dengan data barang yang akan di-upload. Lanjutkan?"
            />
            :
            null
        }
        {
            showUploadModal ?
            <SalesOrderEditorUploadBrgModal
                headerData={itemInEdit}
                items={items}
                tax={tax}
                onHide={() => setShowUploadModal(false)}
                onSave={(data) => {
                    setDetails([...data]);
                    setAutoSummarize(true);
                    setShowUploadModal(false);
                }}
            />
            :
            null
        }
        </CustomModal>
    );
};

export default SalesOrderEditor;
