import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CustomModal(props) {
    const {
        show,
        onHide,
        title,
        titleDetails,
        notes,
        useDefaultNotes,
        dialogClassName,
        additionalContentClassName,
        centered,
        children,
        footer,
    } = props;

    return (
        <Modal
            show={show}
            onHide={() => onHide(false)}
            size="lg"
            contentClassName={`d-flex justify-content-center align-items-center border-0 modal-h-md ${additionalContentClassName ?? ""}`}
            backdrop="static"
            dialogClassName={dialogClassName}
            centered={centered}
            className="d-flex flex-wrap justify-content-center"
            keyboard={false}
        >
            <Modal.Header className="w-100" closeButton>
                <Container fluid>
                    <Row>
                        <Col lg="12" className="font-sm-custom">
                            <h5>{title}</h5>{titleDetails}
                        </Col>
                    {
                        notes || useDefaultNotes ?
                        <Col lg="12" className="font-xs-custom">
                            <b>
                            {
                                useDefaultNotes ? (<>Isian dengan tanda <span className="text-danger">*</span> harus diisi.</>) : null
                            }
                            {
                                notes.map((n, idx) => (<>{useDefaultNotes || idx > 0 ? <br /> : null}{n}</>))
                            }
                            </b>
                        </Col>
                        :
                        null
                    }
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body className="w-100 h-100 font-sm-custom" style={{maxHeight: "72vh", overflowY: "scroll"}}>
                {children}
            </Modal.Body>
        {
            footer ?
            <Modal.Footer className="w-100">
                {footer}
            </Modal.Footer>
            :
            null
        }
        </Modal>
    );
}

CustomModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    titleDetails: PropTypes.string,
    dialogClassName: PropTypes.string,
    additionalContentClassName: PropTypes.string,
    centered: PropTypes.bool,
    notes: PropTypes.array,
    useDefaultNotes: PropTypes.bool.isRequired,
};

CustomModal.defaultProps = {
    dialogClassName: "modal-lg-width",
    centered: false,
    useDefaultNotes: true,
    notes: [],
};

export default CustomModal;
