import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import { ComboBox } from '../../../components/Dropdowns';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';

function KondisiStok(props) {
    const {accessLevel} = props;
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userDivision, setUserDivision] = useState(null);
    const [showAllDivision, setShowAllDivision] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [reloadWarehouse, setReloadWarehouse] = useState(false);
    const [dateFilter, setDateFilter] = useState(null);
    const { authState, apiCaller } = useStateContext();

    useEffect(() => {
        setShowAllDivision(accessLevel?.see_other_div ?? false);
    }, [accessLevel]);

    useEffect(() => {
        if (!authState?.div_code)
            return;

        setLoading(true);
        apiCaller.get(`divisions/search/code?code=${authState?.div_code}`)
            .then((response) => {
                // console.log({response});
                setUserDivision({
                    ...response.data.data
                });
                setReloadWarehouse(true);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [authState?.div_code]);

    const onDivisionSelected = useCallback((val) => {
        // console.log({val});
        if (selectedDivision?.ucode !== val?.ucode && selectedWarehouse)
        {
            setSelectedWarehouse(null);
            setReloadWarehouse(true);
        }

        setSelectedDivision(val ? {...val} : null);
    }, [selectedDivision, selectedWarehouse]);

    useEffect(() => {
        if (!reloadWarehouse)
            return;
        
        setSelectedWarehouse(null);
        setReloadWarehouse(false);
    }, [reloadWarehouse]);

    const onWarehouseSelected = useCallback((val) => {
        // console.log({val});
        setSelectedWarehouse(val ? {...val} : null);
    }, []);

    const handleDateChanged = useCallback(({target: {name, value}}) => {
        setDateFilter(dateFilter ? {...dateFilter, [name]: value} : {[name]: value});
    }, [dateFilter]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Nama Barang",
            accessor: "nama_brg",
        },
        {
            Header: "Nama Lain",
            accessor: "nama_lain_brg",
        },
        {
            Header: "Kode Satuan",
            accessor: "kode_satuan",
        },
        {
            Header: "Nama Satuan",
            accessor: "nama_satuan",
        },
        // {
        //     Header: "Qty Masuk",
        //     accessor: "qty_masuk",
        // },
        {
            Header: "Qty Keluar",
            accessor: "qty_keluar",
        },
        {
            Header: "Saldo Akhir",
            id: "total",
            accessor: (row) => row.qty_masuk - row.qty_keluar,
        },
    ], []);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="reports/search/stock/condition"
                reload={reload}
                additionalParams={(selectedDivision || userDivision) && selectedWarehouse && dateFilter?.startDate && dateFilter?.endDate ? `div_id=${selectedDivision ? selectedDivision?.ucode : (!showAllDivision ? userDivision?.ucode : null)}&wh_id=${selectedWarehouse?.ucode}&start_date=${moment(dateFilter.startDate).format("YYYY-MM-DD")}&end_date=${moment(dateFilter.endDate).format("YYYY-MM-DD")}` : null}
                addParamChangedAutoReload={false}
            />
        );
    }, [reload, columns, selectedDivision, showAllDivision, userDivision, selectedWarehouse, dateFilter]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-2 font-sm-custom">
                    {
                        showAllDivision ?
                        <Col lg={3} md={12}>
                            <Form.Label>
                                Divisi: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="divisions/search/name"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onDivisionSelected(val)}
                                selectedItem={selectedDivision}
                                authState={authState}
                                minLength={0}
                            />
                        </Col>
                        :
                        null
                    }
                    <Col lg={3} md={12}>
                        <Form.Label>
                            Gudang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="warehouses/search/division"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onWarehouseSelected(val)}
                            selectedItem={selectedWarehouse}
                            authState={authState}
                            minLength={0}
                            additionalParam={{
                                id: selectedDivision?.ucode
                            }}
                            disabled={showAllDivision && !selectedDivision}
                        />
                    </Col>
                    <Col lg={6} md={12}>
                        <Form.Label>
                            Periode: <span className="text-danger">*</span>
                        </Form.Label>
                        <Row className="p-0">
                            <Col lg={6} md={12}>
                                <Form.Control
                                    type="date"
                                    name="startDate"
                                    onChange={(e) => {
                                        handleDateChanged(e);
                                    }}
                                    autoComplete="off"
                                    value={dateFilter?.startDate ?? ""}
                                />
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Control
                                    type="date"
                                    name="endDate"
                                    onChange={(e) => {
                                        handleDateChanged(e);
                                    }}
                                    autoComplete="off"
                                    value={dateFilter?.endDate ?? ""}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-search me-1" />Cari</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            </Container>
        </>
    );
}

export default KondisiStok;
