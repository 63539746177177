export const loadSalesNotificationData = (apiCaller, setSalesState, authState) => {
    const {menus} = authState;
    const so_can_view = menus?.filter(m => m.kode_menu === "SALES-SO").length > 0;
    const do_can_view = menus?.filter(m => m.kode_menu === "SALES-DO").length > 0;
    const pp_can_view = menus?.filter(m => m.kode_menu === "SALES-PP").length > 0;

    if (so_can_view)
        apiCaller.get(`salesorders/unhandled/count`)
            .then((response) => {
                setSalesState({type: "refresh-so", unhandledSOCount: response.data});
            })
            .catch((error) => {
                console.error({error});
            });

    if (do_can_view)
        apiCaller.get(`deliveryorders/unhandled/count`)
            .then((response) => {
                setSalesState({type: "refresh-do", unhandledDOCount: response.data});
            })
            .catch((error) => {
                console.error({error});
            });

    if (pp_can_view)
        apiCaller.get(`paymentproofs/unhandled/count`)
            .then((response) => {
                setSalesState({type: "refresh-pr", unhandledPRCount: response.data});
            })
            .catch((error) => {
                console.error({error});
            });
};

export const handleChangeSOStatus = (ucode, status, apiCaller, successCallback, errorCallback) => {
    apiCaller.post(`salesorders/changestatus?id=${ucode}&status=${status}`)
        .then((response) => {
            // console.log({response});
            successCallback(response);
        })
        .catch((error) => {
            console.error({error});
            errorCallback(error);
        })
    ;
};

export const handleChangeDOStatus = (ucode, status, apiCaller, successCallback, errorCallback) => {
    apiCaller.post(`deliveryorders/changestatus?id=${ucode}&status=${status}`)
        .then((response) => {
            // console.log({response});
            successCallback(response);
        })
        .catch((error) => {
            console.error({error});
            errorCallback(error);
        })
    ;
};

export const loadSalesOrderData = (apiCaller, callback) => {
    const apiCalls = [
        apiCaller.get(`salesorders`),
    ]

    Promise.all(apiCalls)
        .then((responses) => {
            // console.log("refresh counter", {responses});
            callback(responses[0].data.data);
        })
        .catch((error) => {
            console.error({error});
        })
    ;
};