import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import PropTypes from 'prop-types';
import { Menu } from "../utils/Menu";
import { useWindowDimensions } from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../reducers";

function TemplateView(props) {
    const {group, subgroup} = props;
    const { authState, salesState } = useStateContext();
    const menu = Menu.filter(m => m.group === group && m.subgroup === subgroup)[0];
    const [selectedTab, setSelectedTab] = useState(menu.children.filter(c => authState.menus?.filter(m => m.kode_menu === c.code).length > 0)[0]?.title);
    const {height} = useWindowDimensions();
    const navigate = useNavigate();

    useEffect(() => {
        const newAuthState = JSON.parse(localStorage.getItem(process.env.REACT_APP_AUTH_PERSIST_STORAGE_KEY));

        if (!newAuthState || !newAuthState.username || newAuthState.username === "" || !newAuthState.hasPassword)
        {
            navigate("/login");
            return;
        }
    }, []);

    // useEffect(() => console.log({menu}), [menu]);

    return (
        <Container fluid style={{height: height >= 800 ? "78vh" : height >= 540 ? "69vh" : "65vh"}} className={`p-3 rounded bg-white main-container w-80`}>
            <Tabs
                defaultActiveKey={selectedTab}
                id="justify-tab"
                className="mb-3"
                onSelect={(key) => setSelectedTab(key)}
            >
            {
                menu.children.filter(c => authState.menus?.filter(m => m.kode_menu === c.code).length > 0).map((c, idx) => {
                    const menuAuth = authState.menus?.filter(m => m.kode_menu === c.code)[0] ?? {can_add: "0", can_edit: "0", can_delete: "0", see_other_div: "0"};

                    return (
                        <Tab
                            key={`${group}_${subgroup}_tab_${idx}`}
                            eventKey={c.title}
                            title={(
                                <div className="position-relative">
                                {c.title}
                                {
                                    (c.title === "Sales Order" && salesState.unhandledSOCount) || (c.title === "Surat Jalan" && salesState.unhandledDOCount) || (c.title === "Penerimaan Pembayaran" && salesState.unhandledPRCount) ?
                                    <span className="position-absolute top--10 start-100 translate-middle badge rounded-pill bg-warning text-dark">
                                        {c.title === "Sales Order" ? salesState.unhandledSOCount : (c.title === "Surat Jalan" ? salesState.unhandledDOCount : (c.title === "Penerimaan Pembayaran" ? salesState.unhandledPRCount : ""))}
                                    </span>
                                    :
                                    null
                                }
                                </div>
                            )}
                        >
                        {
                            selectedTab === c.title ?
                            <c.component accessLevel={{can_add: parseInt(menuAuth.can_add), can_edit: parseInt(menuAuth.can_edit), can_delete: parseInt(menuAuth.can_delete), see_other_div: parseInt(menuAuth.see_other_div)}} />
                            :
                            null
                        }
                        </Tab>
                    );
                })
            }
            </Tabs>
        </Container>
    );
};

TemplateView.propTypes = {
    group: PropTypes.string.isRequired,
    subgroup: PropTypes.string.isRequired,
};

export default TemplateView;
