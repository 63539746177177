import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import moment from "moment";
import TooltipButton from '../../../components/TooltipButton';
import TerimaBayarEditor from './TerimaBayarEditor';
import Loader from '../../../components/Loader';
import ModalAlert from '../../../components/ModalAlert';
import { useStateContext } from '../../../reducers';
import ErrorAlert from '../../../components/ErrorAlert';
import PaymentProofList from './PaymentProofList';
import { useSalesContext } from './Context';

function TerimaBayar(props) {
    const {accessLevel} = props;
    const [itemInEdit, setItemInEdit] = useState(null);
    const [reload, setReload] = useState(0);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [showProofList, setShowProofList] = useState(false);
    const [errorObj, setErrorObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const { apiCaller } = useStateContext();
    const { loadNotifCount } = useSalesContext();

    const handleDelete = useCallback((ucode) => {
        setLoading(true);
        apiCaller.delete(`payreceives/${ucode}`)
            .then(() => {
                setDeleteConfirmation(null);
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setErrorObj({
                    title: "Proses penghapusan gagal!",
                    message: "Harap hubungi admin/teknisi.",
                })
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => {
        return [
            // {
            //     Header: "",
            //     accessor: "check",
            //     Cell: ({row: {original}}) => {
            //         return (
            //             <Form.Check />
            //         );
            //     },
            //     width: 50,
            //     disableFilters: true,
            //     centered: true,
            // },
            {
                Header: "No. Penerimaan Pembayaran",
                accessor: "no_transaksi",
            },
            {
                Header: "Tanggal",
                id: "tanggal_transaksi",
                accessor: (row) => moment(row.tanggal_transaksi).format("DD-MM-YYYY"),
                disableFilters: true,
                sortType: ({values: {tanggal_transaksi: a}}, {values: {tanggal_transaksi: b}}) => {
                    const dateA = moment(a, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    const dateB = moment(b, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    // console.log({a, dateA, b, dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "Nama Customer",
                accessor: "nama_customer",
            },
            {
                Header: "Telepon",
                accessor: "telp",
            },
            {
                Header: "",
                id: "action",
                Cell: ({row: {original}}) => {
                    return (
                        <InputGroup className="d-flex flex-wrap justify-content-center align-items-center">
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Edit"
                                size="xs-custom"
                                variant="success"
                                className="me-1"
                                onClick={() => {
                                    setItemInEdit(original);
                                }}
                            >
                                <i className="fas fa-edit" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_delete > 0 &&
                            <TooltipButton
                                title="Hapus"
                                size="xs-custom"
                                variant="danger"
                                onClick={() => {
                                    setDeleteConfirmation(original);
                                }}
                                disabled={original.total_completed > 0}
                            >
                                <i className="fas fa-trash" />
                            </TooltipButton>
                        }
                        </InputGroup>
                    )
                },
                width: 200,
                sticky: "right",
            },
        ]
    }, [accessLevel]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="payreceives"
                reload={reload}
                reloadCallback={() => loadNotifCount()}
            />
        );
    }, [reload, columns, loadNotifCount]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                        {
                            accessLevel.can_delete > 0 &&
                            <Button size="xs-custom" variant="dark" className="me-1" onClick={() => setItemInEdit({})}><i className="fas fa-plus me-1" />Tambah Data</Button>
                        }
                            <Button size="xs-custom" variant="primary" className="me-1" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                            <Button size="xs-custom" variant="success" onClick={() => setShowProofList(true)}><i className="fas fa-search me-1" />Lihat Bukti Pembayaran</Button>
                        </InputGroup>
                    </Col>
                </Row>
            {
                grid
            }
            {
                itemInEdit ?
                <TerimaBayarEditor
                    onHide={() => setItemInEdit(null)}
                    data={itemInEdit}
                    onSave={() => {
                        setItemInEdit(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                />
                :
                null
            }
            {
                deleteConfirmation ?
                <ModalAlert
                    onHide={() => setDeleteConfirmation(null)}
                    onSave={() => handleDelete(deleteConfirmation.ucode)}
                    title="Konfirmasi Penghapusan Data"
                    bodyText={`Apakah anda yakin akan menghapus data surat jalan ${deleteConfirmation.no_transaksi}?`}
                />
                :
                null
            }
            {
                errorObj ?
                <ErrorAlert
                    onHide={() => setErrorObj(null)}
                    bodyText={errorObj.message}
                />
                :
                null
            }
            {
                showProofList &&
                <PaymentProofList
                    onHide={() => setShowProofList(false)}
                    onCreatePR={(defaultCustomer) => {
                        setShowProofList(false);
                        setItemInEdit({
                            defaultCustomer
                        });
                    }}
                    accessLevel={accessLevel}
                />
            }
            </Container>
        </>
    );
}

export default TerimaBayar;
