import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStateContext } from "../../../reducers";
import Loader from "../../../components/Loader";
import moment from "moment";
import { getCurrencyDisplay, getDecimalDisplay, numberToWords, useWindowDimensions } from "../../../utils/Common";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, PDFDownloadLink, Image } from '@react-pdf/renderer';
import OpenSansRegular from '../../../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../../../assets/fonts/OpenSans-Bold.ttf';
import OpenSansItalic from '../../../assets/fonts/OpenSans-Italic.ttf';
import { isMobile } from "react-device-detect";
import { Button, Container } from "react-bootstrap";

Font.register({
    family: 'open-sans',
    fonts: [
        {
            src: OpenSansRegular,
        },
        {
            src: OpenSansBold,
            fontWeight: "bold",
        },
        {
            src: OpenSansItalic,
            fontStyle: "italic",
        },
    ]
});

function InvoiceContent(props) {
    const {
        itemToShow
    } = props;

    const styles = StyleSheet.create({
        page: {
            fontFamily: "open-sans",
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        row: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        rowStretch: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-center",
            flexWrap: "wrap",
        },
        col: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
        },
        headerTitle: {
            width: "100%",
            fontSize: 14,
            fontWeight: "bold",
            marginVertical: 5,
        },
        header: {
            width: "100%",
            fontSize: 8,
            marginVertical: 2,
        },
        title: {
            width: "100%",
            fontSize: 20,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
            textDecoration: "underline",
        },
        headerDeliveryHeader: {
            width: "100%",
            fontSize: 10,
            fontWeight: "bold",
        },
        headerDeliveryDetail: {
            width: "100%",
            fontSize: 8,
            marginTop: 3,
        },
        descriptionTitle: {
            width: "12%",
            fontSize: 10,
            marginVertical: 2,
            color: "#777",
        },
        descriptionValue: {
            width: "28%",
            fontSize: 10,
            marginVertical: 2,
        },
        descriptionCenter: {
            width: "20%",
            fontSize: 12,
            marginVertical: 2,
            textAlign: "center",
            fontWeight: "bold",
        },
        colTitle: {
            fontWeight: "bold",
        },
        col1: {
            width: "4%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "left",
        },
        col2: {
            width: "17%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "left",
        },
        col3: {
            width: "22%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "left",
        },
        col4: {
            width: "8%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "right",
        },
        col5: {
            width: "18%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "right",
        },
        col6: {
            width: "9%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "right",
        },
        col7: {
            width: "15%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "right",
        },
        col8: {
            width: "22%",
            fontSize: 10,
            marginVertical: 2,
            textAlign: "right",
        },
        footerLeft: {
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: 5,
        },
        footerLeftContent: {
            fontSize: 10,
            textAlign: "left",
        },
        footerRight: {
            width: "40%",
            fontSize: 10.5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexWrap: "wrap",
            padding: 5,
        },
        footerRightTitle: {
            width: "38%",
            textAlign: "left",
            marginVertical: 2,
            fontWeight: "bold",
        },
        footerRightValue: {
            width: "62%",
            textAlign: "right",
            marginVertical: 2,
        },
        colFooter: {
            width: "100%",
            fontSize: 9,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
        },
        footer: {
            width: "30%",
            fontSize: 11,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: 5,
        },
        footerTitle: {
            width: "100%",
            textAlign: "center",
        },
        footerValue: {
            width: "100%",
            textAlign: "center",
            borderTopWidth: 1,
            marginTop: 180,
        },
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.rowStretch}>
                        <View style={{width: "32%"}}>
                            <View style={{...styles.headerTitle, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-center"}}>
                                <View style={{width: "30%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                                {
                                    itemToShow?.logo_divisi ?
                                    <Image
                                        src={`data:${itemToShow?.tipe_file_logo_divisi};base64,${itemToShow?.logo_divisi}`}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            objectFit: "contain",
                                        }}
                                    />
                                    :
                                    null
                                }
                                </View>
                                <View style={{width: "70%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start", marginLeft: "5px"}}>
                                    <Text style={styles.headerTitle}>{itemToShow?.nama_divisi}</Text>
                                    <Text style={styles.header}>{itemToShow?.alamat_divisi}</Text>
                                    <Text style={styles.header}>{itemToShow?.kota_divisi}</Text>
                                    <Text style={styles.header}>NPWP : {itemToShow?.npwp_divisi}</Text>
                                    <Text style={styles.header}>Telp : {itemToShow?.telp_divisi}, Fax : -</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{width: "32%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            <Text style={{...styles.title}}>SALES INVOICE</Text>
                        </View>
                        <View style={{width: "32%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingHorizontal: 3}}>
                            <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "right"}}>
                                <Text style={styles.headerDeliveryHeader}>No. Invoice</Text>
                                <Text style={styles.headerDeliveryDetail}>{itemToShow?.no_transaksi}</Text>
                            </View>
                            <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 5, textAlign: "right"}}>
                                <Text style={styles.headerDeliveryHeader}>Tanggal Invoice</Text>
                                <Text style={styles.headerDeliveryDetail}>{itemToShow?.tanggal_transaksi ? moment(itemToShow.tanggal_transaksi).format("DD-MM-YYYY") : "-"}</Text>
                            </View>
                            <View style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 5, textAlign: "right"}}>
                                <Text style={styles.headerDeliveryHeader}>Termin</Text>
                                <Text style={styles.headerDeliveryDetail}>{itemToShow?.jatuh_tempo}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{...styles.row, marginTop: 10}}>
                        <View style={styles.col}>
                            <Text style={styles.descriptionTitle}>Pelanggan:</Text>
                            <Text style={styles.descriptionValue}>{itemToShow?.nama_customer}</Text>
                        </View>
                    </View>
                    <View style={{...styles.row}}>
                        <View style={styles.col}>
                            <Text style={styles.descriptionTitle}>Alamat:</Text>
                            <Text style={styles.descriptionValue}>{itemToShow?.alamat_kirim}</Text>
                        </View>
                    </View>
                    <View style={{...styles.row, marginTop: 10, padding: 10, borderTopWidth: 1, borderBottomWidth: 1, paddingBottom: 15,}}>
                        <View style={{...styles.col, marginBottom: 3, borderBottomWidth: 0.5, padding: 5}}>
                            <Text style={{...styles.col1, ...styles.colTitle}}>No</Text>
                            <Text style={{...styles.col2, ...styles.colTitle}}>Kode Barang</Text>
                            <Text style={{...styles.col3, ...styles.colTitle}}>Nama Barang</Text>
                            <Text style={{...styles.col4, ...styles.colTitle}}>Qty</Text>
                            <Text style={{...styles.col5, ...styles.colTitle}}>Harga Satuan</Text>
                            <Text style={{...styles.col6, ...styles.colTitle}}>Disc</Text>
                            {/* <Text style={{...styles.col7, ...styles.colTitle}}>Net Sls</Text> */}
                            <Text style={{...styles.col8, ...styles.colTitle}}>Jumlah</Text>
                        </View>
                    {
                        itemToShow?.details.map((d, idx) => {
                            return (
                                // <View key={`row-${idx}`} style={{...styles.col, backgroundColor: idx % 2 === 0 ? "#ededed" : "transparent", marginVertical: 2, padding: 5}}>
                                <View key={`row-${idx}`} style={{...styles.col, backgroundColor: "transparent", marginVertical: 2, padding: 5}}>
                                    <Text style={styles.col1}>{idx+1}</Text>
                                    <Text style={styles.col2}>{d.kode_brg}</Text>
                                    <Text style={styles.col3}>{d.nama_brg}</Text>
                                    <Text style={styles.col4}>{d.qty_tersedia}</Text>
                                    <Text style={styles.col5}>{getCurrencyDisplay(d.harga)}</Text>
                                    <Text style={styles.col6}>{d.diskon ? getDecimalDisplay(d.diskon) : "-"}</Text>
                                    {/* <Text style={styles.col7}>{getCurrencyDisplay(d.harga * (100 - (d.diskon ?? 0)) / 100)}</Text> */}
                                    <Text style={styles.col8}>{getCurrencyDisplay(d.sub_total_bruto)}</Text>
                                </View>
                            );
                        })
                    }
                    </View>
                    <View style={{...styles.row, marginTop: 10, padding: 10}}>
                        <View style={styles.col}>
                            <View style={styles.footerLeft}>
                                <View style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap"}}>
                                    <Text style={{...styles.footerLeftContent, color: "#555"}}>
                                        Terbilang:
                                    </Text>
                                    <Text style={{...styles.footerLeftContent, fontStyle: "italic", marginHorizontal: 5}}>
                                        {numberToWords(itemToShow?.grand_total_netto)} rupiah
                                    </Text>
                                </View>
                                {/* <View style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", flexWrap: "wrap", marginTop: 10, borderWidth: 1, padding: 3,}}>
                                    <Text style={{...styles.footerLeftContent, width: "100%", fontWeight: "bold"}}>
                                        Transfer pembayaran ke:
                                    </Text>
                                    <Text style={{...styles.footerLeftContent, width: "100%", fontWeight: "bold"}}>
                                        Bank Panin Cabang Coklat 4005005338
                                    </Text>
                                    <Text style={{...styles.footerLeftContent, width: "100%", fontWeight: "bold"}}>
                                        Bank BCA Cabang Tidar 2145077999
                                    </Text>
                                    <Text style={{...styles.footerLeftContent, width: "100%", fontWeight: "bold"}}>
                                        Atas Nama PT. Multi Jaya Niaga Raya
                                    </Text>
                                    <Text style={{...styles.footerLeftContent, width: "100%", fontWeight: "bold", marginTop: 10}}>
                                        *Pembayaran menggunakan cek/giro baru dianggap sah setelah dana dicairkan di rekening Bank kami.
                                    </Text>
                                </View> */}
                            </View>
                            <View style={styles.footerRight}>
                                <Text style={{...styles.footerRightTitle}}>
                                    Sub Total
                                </Text>
                                <Text style={{...styles.footerRightValue}}>
                                    {getDecimalDisplay(itemToShow?.grand_total_bruto)}
                                </Text>
                                <Text style={{...styles.footerRightTitle}}>
                                    Discount
                                </Text>
                                <Text style={{...styles.footerRightValue}}>
                                    {itemToShow?.total_diskon ? getDecimalDisplay(itemToShow.total_diskon) : "-"}
                                </Text>
                                <Text style={{...styles.footerRightTitle}}>
                                    PPN
                                </Text>
                                <Text style={{...styles.footerRightValue}}>
                                    {getDecimalDisplay(itemToShow?.total_ppn)}
                                </Text>
                                <Text style={{...styles.footerRightTitle}}>
                                    Total Invoice
                                </Text>
                                <Text style={{...styles.footerRightValue}}>
                                    {getDecimalDisplay(itemToShow?.grand_total_netto)}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={{...styles.row, marginTop: 10, padding: 10}}>
                        <View style={styles.colFooter}>
                            <View style={styles.footer}>
                            </View>
                            <View style={styles.footer}>
                            </View>
                            <View style={styles.footer}>
                                <Text style={styles.footerTitle}>Disetujui Oleh,</Text>
                                <Text style={styles.footerValue}></Text>
                            </View>
                        </View>
                    </View>
                </View>
                <Text style={{textAlign: "center", paddingVertical: 10, fontSize: 12, fontStyle: "italic", color: "#666"}} render={({ pageNumber, totalPages }) => (
                    `halaman ${pageNumber} dari ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );
};

function InvoicePrint(props) {
    const [id, setId] = useState(null);
    const [itemToShow, setItemToShow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const { apiCaller, menuState, setMenuState } = useStateContext();
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setId(searchParams.get("id"));
    }, [searchParams]);

    const loadData = useCallback(() => {
        if (!id)
            return;

        setLoading(true);
        apiCaller.get(`salesorders/search/invoice?id=${id}`)
            .then((response) => {
                const {data: {data: {header, detail}}} = response;
                setItemToShow({
                    ...header,
                    total_diskon: header.grand_total_netto - header.grand_total_bruto - (header.total_ppn ?? 0),
                    details: [...detail]
                });
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [id, apiCaller]);

    useEffect(() => {
        if (!id)
            return;

        loadData();
    }, [id]);

    useEffect(() => {
        if (menuState?.showSidebar)
            setMenuState({type: "toggle", showSidebar: false});
    }, [menuState]);

    if (!itemToShow)
        return <></>;

    const styles = StyleSheet.create({
        viewer: {
            width,
            height: height - 6,
            fontFamily: "open-sans",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
        },
        downloadButton: {
        }
    });

    return (
        <>
        {
            loading && <Loader />
        }
        {
            isMobile ?
            <Container fluid className="vh-100 d-flex flex-wrap justify-content-center align-items-center bg-light">
                <PDFDownloadLink document={<InvoiceContent itemToShow={itemToShow} />} fileName={`INVOICE-${itemToShow.no_transaksi}-${moment(new Date()).format("YYYYMMDDHHmmss")}`}>
                {
                    ({ loading }) =>
                    (
                        // <View style={styles.viewer}>
                        // {
                        //     loading ?
                        //     <Text style={{fontStyle: "italic"}}>Loading...</Text>
                        //     :
                        //     <Text style={{fontStyle: "bold", color: "#fff", backgroundColor: "#bd1c1c", padding: 5}}>Download</Text>
                        // }
                        // </View>
                        loading ?
                        "Loading..."
                        :
                        <Button variant="print-download" size="sm-custom"><i className="fas fa-file-pdf me-2" />Download Invoice</Button>
                    )
                }
                </PDFDownloadLink>
            </Container>
            :
            <PDFViewer style={styles.viewer}>
                <InvoiceContent itemToShow={itemToShow} />
            </PDFViewer>
        }
        </>
    );
}

export default InvoicePrint;
