import React from 'react';
import { Container } from 'react-bootstrap';
import { useWindowDimensions } from '../utils/Common';

function Dashboard(props) {
    const {height} = useWindowDimensions();

    return (
        <Container fluid style={{height: height >= 800 ? "85vh" : height >= 540 ? "76vh" : "72vh"}} className={`main-container`}>
        </Container>
    );
}

export default Dashboard;
