import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import { objectWithoutProperties } from "../../../utils/Common";

function KaryawanEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller, authState } = useStateContext();

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama || !itemInEdit.ucode_divisi || !itemInEdit.ucode_posisi);
    }, [itemInEdit]);

    useEffect(() => {
        // console.log({data});
        const newItemInEdit = JSON.parse(JSON.stringify(data));
        if (data?.ucode_divisi)
            newItemInEdit.divisi = {
                ucode: data.ucode_divisi,
                nama: data.nama_divisi,
            };

        if (data?.ucode_posisi)
            newItemInEdit.posisi = {
                ucode: data.ucode_posisi,
                nama: data.nama_posisi,
            };

        setItemInEdit(newItemInEdit);
    }, [data]);

    const onDivisionSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_divisi: val?.ucode,
            divisi: val,
        });
    }, [itemInEdit]);

    const onPositionSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_posisi: val?.ucode,
            posisi: val,
        });
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "kode",
            "kode_posisi",
            "nama_posisi",
            "posisi",
            "kode_divisi",
            "nama_divisi",
            "divisi",
        ])};

        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`employees`, JSON.stringify(dataToSend)) : apiCaller.put(`employees/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="Karyawan"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="justify-content-start mb-3">
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Kode:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                            name="kode"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Nama: <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                            name="nama"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Divisi: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="divisions/search/parent/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onDivisionSelected(val)}
                            selectedItem={itemInEdit?.divisi}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Posisi: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="positions/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onPositionSelected(val)}
                            selectedItem={itemInEdit?.posisi}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Alamat:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.alamat ? itemInEdit.alamat : ""}
                            name="alamat"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            No. Telepon:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.telp ? itemInEdit.telp : ""}
                            name="telp"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Email:
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={itemInEdit !== null && itemInEdit.email ? itemInEdit.email : ""}
                            name="email"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            // disabled={true}
                        />
                    </Col>
                    <Col lg="12" className="mb-3">
                        <Form.Label>
                            Keterangan:
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                            name="keterangan"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                            rows={5}
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default KaryawanEditor;