import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import DefaultGrid from "../../../components/DefaultGrid";
import Loader from "../../../components/Loader";
import TooltipButton from "../../../components/TooltipButton";
import { useStateContext } from "../../../reducers";
import { ComboBox } from "../../../components/Dropdowns";

function UserEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState({
        ...data,
        ucode_karyawan: data.ucode_karyawan,
        karyawan: data.ucode_karyawan ? {ucode: data.ucode_karyawan, nama: data.nama_karyawan} : null,
        ucode_role: data.ucode_role,
        role: data.ucode_role ? {ucode: data.ucode_role, nama: data.nama_role} : null,
        spesifik_gudang: data.spesifik_gudang ? parseInt(data.spesifik_gudang) : 0,
    });
    const [loading, setLoading] = useState(false);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [reloadWarehouse, setReloadWarehouse] = useState(0);
    const [disableSave, setDisableSave] = useState(true);
    const { apiCaller, authState } = useStateContext();

    const loadDetail = useCallback(() => {
        if (!data?.ucode)
            return;
        
        setLoading(true);
        apiCaller.get(`users/search/detail/id?id=${data.ucode}`)
            .then((response) => {
                setSelectedWarehouses([...response.data.data]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, data?.ucode]);

    useEffect(() => {
        if (!data?.ucode)
            return;

        loadDetail();
    }, [data?.ucode]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.username || !itemInEdit.ucode_karyawan || !itemInEdit.ucode_role);
    }, [itemInEdit]);

    const handleSyncEmail = useCallback(() => {
        setLoading(true);
        apiCaller.get(`employees/search/email?id=${itemInEdit.ucode_karyawan}`)
            .then((response) => {
                // console.log({response});
                setItemInEdit({
                    ...itemInEdit,
                    username: response.data.data.email,
                });
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, itemInEdit]);

    const onEmployeeSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            karyawan: val,
            ucode_karyawan: val?.ucode,
            username: val?.username,
            ucode_divisi: val?.ucode_divisi,
        });
    }, [itemInEdit]);

    const onRoleSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            role: val,
            ucode_role: val?.ucode,
            spesifik_gudang: val?.spesifik_gudang,
        });
    }, [itemInEdit]);

    const onWarehouseSelected = useCallback((val) => {
        // console.log({val});
        setSelectedWarehouse(val ?? null);
    }, []);

    const warehouseColumns = useMemo(() => [
        {
            Header: "Kode",
            accessor: "kode_gudang",
        },
        {
            Header: "Nama",
            accessor: "nama_gudang",
        },
        {
            Header: "",
            id: "action",
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                        <TooltipButton
                            title="Hapus"
                            size="xs-custom"
                            variant="outline-danger"
                            className="me-2"
                            onClick={() => {
                                const filtered = selectedWarehouses.filter(c => c.ucode !== original.ucode);
                                setSelectedWarehouses(filtered);
                                setReloadWarehouse(reloadWarehouse === 1 ? 0 : 1);
                            }}
                        >
                            <i className="fas fa-trash-alt" />
                        </TooltipButton>
                    </div>
                )
            },
            maxWidth: 80,
            width: 80,
        },
    ], [selectedWarehouses, reloadWarehouse]);

    const warehouseGrid = useMemo(() => {
        return (
            <DefaultGrid
                columns={warehouseColumns}
                initData={selectedWarehouses}
                reload={reloadWarehouse}
                maxRowCount={selectedWarehouses.length}
            />
        );
    }, [warehouseColumns, selectedWarehouses, reloadWarehouse]);

    const onSelectWarehouseClick = useCallback(() => {
        if (selectedWarehouses.filter(c => c.ucode_gudang === selectedWarehouse?.ucode).length)
            return;

        setSelectedWarehouses([
            ...selectedWarehouses,
            {ucode: null, ucode_gudang: selectedWarehouse.ucode, kode_gudang: selectedWarehouse.kode, nama_gudang: selectedWarehouse.nama},
        ]);
        setReloadWarehouse(reloadWarehouse === 1 ? 0 : 1);
    }, [selectedWarehouses, selectedWarehouse, reloadWarehouse]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {
            ucode: itemInEdit.ucode ?? null,
            ucode_karyawan: itemInEdit.ucode_karyawan,
            username: itemInEdit.username,
            ucode_role: itemInEdit.ucode_role,
            sts: itemInEdit.ucode ? itemInEdit.sts : 0,
            warehouses: selectedWarehouses.map(w => ({ucode: w.ucode ?? null, ucode_gudang: w.ucode_gudang})),
        }
        // console.log({itemInEdit, dataToSend});
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`users`, JSON.stringify(dataToSend)) : apiCaller.put(`users/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, apiCaller, onSave, selectedWarehouses]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <CustomModal
            show={true}
            onHide={() => onHide()}
            title="User"
            titleDetails={data.ucode ? "Edit" : "Baru"}
            dialogClassName="modal-w-80"
            footer={footer}
        >
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Nama Karyawan: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="employees/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onEmployeeSelected(val)}
                            selectedItem={itemInEdit?.karyawan}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Username: <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={itemInEdit !== null && itemInEdit.username ? itemInEdit.username : ""}
                                    name="username"
                                    onChange={(e) => {
                                        handleTextChanged(e);
                                    }}
                                    autoComplete="off"
                                    disabled={true}
                                />
                                <InputGroup.Text
                                    onClick={() => {
                                        handleSyncEmail();
                                    }}
                                    disabled={!itemInEdit?.ucode_karyawan}
                                    className="cursor-pointer"
                                >
                                    <i className="fas fa-sync-alt"></i>
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col lg="4" md="12" className="mb-3">
                        <Form.Label>
                            Role: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="roles/search/name"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onRoleSelected(val)}
                            selectedItem={itemInEdit?.role}
                            authState={authState}
                            minLength={0}
                        />
                    </Col>
                </Row>
            {
                itemInEdit?.spesifik_gudang ?
                <>
                    <Row className="justify-content-md-start mb-3">
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Gudang: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="warehouses/search/division"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onWarehouseSelected(val)}
                                selectedItem={selectedWarehouse}
                                authState={authState}
                                minLength={0}
                                additionalParam={{
                                    id: itemInEdit?.ucode_divisi
                                }}
                                additionalButtons={[
                                    (
                                        <InputGroup.Text onClick={() => onSelectWarehouseClick()} className="cursor-pointer">
                                            <i className="fas fa-arrow-down"></i>
                                        </InputGroup.Text>
                                    )
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                    {
                        warehouseGrid
                    }
                    </Row>
                </>
                :
                null
            }
            </Container>
        </CustomModal>
    );
};

export default UserEditor;