import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

function ModalAlert(props) {
    const {
        onHide,
        onSave,
        title,
        bodyText,
        cancelButtonText,
        confirmButtonText,
        showCancelButton,
        headerClassname,
        centered,
    } = props;

    return (
        <Modal
            show={true}
            onHide={onHide}
            backdrop="static"
            keyboard={false}
            centered={centered}
        >
            <Modal.Header closeButton className={headerClassname}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="m-0 p-0">
                    <Col lg="12" className="m-0 p-0">
                        {bodyText}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            {
                showCancelButton &&
                <Button variant="secondary" onClick={onHide} size="sm-custom">
                    {cancelButtonText}
                </Button>
            }
                <Button variant="primary" onClick={onSave} size="sm-custom">
                    {confirmButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ModalAlert.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    showCancelButton: PropTypes.bool,
    headerClassname: PropTypes.string,
    centered: PropTypes.bool,
};

ModalAlert.defaultProps = {
    cancelButtonText: "Tidak",
    confirmButtonText: "Ya",
    showCancelButton: true,
    headerClassname: "",
    centered: false,
};

export default ModalAlert;