import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import moment from "moment";
import TooltipButton from '../../../components/TooltipButton';
import SuratJalanEditor from './SuratJalanEditor';
import Loader from '../../../components/Loader';
import ModalAlert from '../../../components/ModalAlert';
import { useStateContext } from '../../../reducers';
import { useSalesContext } from './Context';
import { SHOWN_ERROR_CODE, SJ_STATUS_CANCELED, SJ_STATUS_CONFIRMED, SJ_STATUS_DELIVERED, SJ_STATUS_NEW, SJ_STATUS_NEW_NAME, SJ_STATUS_SENDING } from '../../../utils/Common';
import ErrorAlert from '../../../components/ErrorAlert';
import { handleChangeDOStatus } from '../../../utils/api/sales';
import SuratJalanGREditor from './SuratJalanGREditor';
import SuratJalanVehicleNoEditor from './SuratJalanVehicleNoEditor';

function SuratJalan(props) {
    const {accessLevel} = props;
    const [itemInEdit, setItemInEdit] = useState(null);
    const [selectedDataForGR, setSelectedDataForGR] = useState(null);
    const [selectedDataForVehicleNo, setSelectedDataForVehicleNo] = useState(null);
    const [reload, setReload] = useState(0);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [confirmationObj, setConfirmationObj] = useState(null);
    const [errorObj, setErrorObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [exportToExcel, setExportToExcel] = useState(false);
    const [showOnlyEmptyGrNo, setShowOnlyEmptyGrNo] = useState(false);
    const { apiCaller, authState } = useStateContext();
    const { loadNotifCount } = useSalesContext();

    const loadStatusList = useCallback(() => {
        setLoading(true);
        // console.log({confirmationObj});
        apiCaller.get("statuses/search/type?type=SJ")
            .then((response) => {
                // console.log({response});
                setStatusList(response.data.data);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller]);

    useEffect(() => {
        loadStatusList();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setReload(reload === -1 ? -2 : -1);
        }, process.env.REACT_APP_RELOAD_INTERVAL);
      
        return () => clearInterval(interval);
    }, [reload]);

    const handlePrint = useCallback((ucode) => {
        window.open(`do/print?id=${ucode}`, "Surat Jalan", "width=800,height=600");
    }, []);

    const handlePrintReceipt = useCallback((ucode) => {
        window.open(`do/receipt/print?id=${ucode}`, "Tanda Terima", "width=800,height=600");
    }, []);

    const handleDelete = useCallback((ucode) => {
        setLoading(true);
        apiCaller.delete(`deliveryorders/${ucode}`)
            .then(() => {
                setDeleteConfirmation(null);
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [reload, apiCaller]);

    const columns = useMemo(() => {
        return [
            // {
            //     Header: "",
            //     accessor: "check",
            //     Cell: ({row: {original}}) => {
            //         return (
            //             <Form.Check />
            //         );
            //     },
            //     width: 50,
            //     disableFilters: true,
            //     centered: true,
            // },
            {
                Header: "No. Surat Jalan",
                accessor: "no_transaksi",
                id: "no_transaksi",
            },
            {
                Header: "No. BPB",
                accessor: "no_bukti_penerimaan_barang",
                id: "no_bukti_penerimaan_barang",
            },
            {
                Header: "Gudang",
                accessor: "nama_gudang",
                id: "nama_gudang",
            },
            {
                Header: "Tanggal",
                id: "tanggal_transaksi",
                type: "date",
                accessor: (row) => moment(row.tanggal_transaksi).format("DD-MM-YYYY"),
                disableFilters: true,
                sortType: ({values: {tanggal_transaksi: a}}, {values: {tanggal_transaksi: b}}) => {
                    const dateA = moment(a, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    const dateB = moment(b, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm");
                    // console.log({a, dateA, b, dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "No. Sales Order",
                accessor: "no_sales_order",
                id: "no_sales_order",
                Cell: ({row: {original}}) => {
                    const renderTooltip = (
                        <Popover id="popover-basic">
                            <Popover.Header as="h3">{original.no_sales_order}</Popover.Header>
                            <Popover.Body>
                                <Container fluid className="m-0 p-0">
                                    <Row className="m-0 p-0">
                                        <Col lg="4" className="m-0 p-0">
                                            <small>Tanggal:</small>
                                        </Col>
                                        <Col lg="8" className="m-0 p-0">
                                            <small><b>{moment(original.tanggal_sales_order).format("DD-MM-YYYY")}</b></small>
                                        </Col>
                                        <Col lg="4" className="m-0 p-0">
                                            <small>Customer:</small>
                                        </Col>
                                        <Col lg="8" className="m-0 p-0">
                                            <small><b>{original.nama_customer}</b></small>
                                        </Col>
                                        <Col lg="4" className="m-0 p-0">
                                            <small>Alamat:</small>
                                        </Col>
                                        <Col lg="8" className="m-0 p-0">
                                            <small><b>{original.alamat_kirim}</b></small>
                                        </Col>
                                        <Col lg="4" className="m-0 p-0">
                                            <small>Telp:</small>
                                        </Col>
                                        <Col lg="8" className="m-0 p-0">
                                            <small><b>{original.telp}</b></small>
                                        </Col>
                                    </Row>
                                </Container>
                            </Popover.Body>
                        </Popover>
                    );

                    return (
                        <>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                                trigger="click"
                            >
                                <span className="cursor-pointer">{original.no_sales_order}<i className="fas fa-info-circle text-dark ms-1" /></span>
                            </OverlayTrigger>
                        </>
                    );
                },
                width: 180,
            },
            {
                Header: "No. Purchase Order",
                accessor: "no_purchase_order",
                id: "no_purchase_order",
            },
            {
                Header: "No. GR",
                accessor: "no_goods_receipt_sap",
                id: "no_goods_receipt_sap",
            },
            {
                Header: "No. Kendaraan",
                accessor: "nopol",
                id: "nopol",
            },
            {
                Header: "Status",
                accessor: "nama_status",
                id: "nama_status",
            },
            {
                Header: "DO Tersedia",
                accessor: "has_delivery_order",
                id: "has_delivery_order",
                type: "boolean",
                Cell: ({row: {original}}) => {
                    return (
                        <>
                        {
                            original.has_delivery_order ?
                            <i className="fas fa-check-circle text-success" />
                            :
                            <i className="fas fa-times-circle text-danger" />
                        }
                        </>
                    );
                },
                disableFilters: true,
                centered: true,
                width: 100,
            },
            {
                Header: "Auto Generated",
                accessor: "from_so",
                id: "from_so",
                type: "boolean",
                Cell: ({row: {original}}) => {
                    return (
                        <>
                        {
                            original.from_so > 0 ?
                            <i className="fas fa-check-circle text-success" />
                            :
                            <i className="fas fa-times-circle text-danger" />
                        }
                        </>
                    );
                },
                disableFilters: true,
                centered: true,
                width: 80,
            },
            {
                Header: "Tanggal Kirim",
                accessor: (row) => row.tanggal_kirim ? moment(row.tanggal_kirim).format("DD-MM-YYYY HH:mm") : "-",
                id: "tanggal_kirim",
                type: "datetime",
                disableFilters: true,
                sortType: ({values: {tanggal_kirim: a}}, {values: {tanggal_kirim: b}}) => {
                    const dateA = a !== "-" ? moment(a, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    const dateB = b !== "-" ? moment(b, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    // console.log({a, dateA, b, dateB, check: dateA < dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "Tanggal Terkirim",
                accessor: (row) => row.tanggal_terima ? moment(row.tanggal_terima).format("DD-MM-YYYY HH:mm") : "-",
                id: "tanggal_terima",
                type: "datetime",
                disableFilters: true,
                sortType: ({values: {tanggal_terima: a}}, {values: {tanggal_terima: b}}) => {
                    const dateA = a !== "-" ? moment(a, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    const dateB = b !== "-" ? moment(b, "DD-MM-YYYY").format("YYYY-MM-DD") : "";
                    // console.log({a, dateA, b, dateB, check: dateA < dateB});
                    if(dateA < dateB)
                        return -1;
                    
                    if(dateA > dateB)
                        return 1;
                    
                    return 0;
                }
            },
            {
                Header: "",
                id: "action",
                Cell: ({row: {original}}) => {
                    return (
                        <InputGroup className="d-flex flex-wrap justify-content-start align-items-center">
                            <TooltipButton
                                title={original.kode_status !== SJ_STATUS_NEW && (!authState.override_status || original.has_delivery_order) ? "Lihat" : "Edit"}
                                size="xs-custom"
                                variant="success"
                                className="me-1"
                                onClick={() => {
                                    setItemInEdit(original);
                                }}
                            >
                                <i className={`fas fa-${original.kode_status !== SJ_STATUS_NEW && (!authState.override_status || original.has_delivery_order) ? "search" : "edit"}`} />
                            </TooltipButton>
                        {
                            accessLevel.can_edit > 0 && original.kode_status === SJ_STATUS_CONFIRMED &&
                            <TooltipButton
                                title={SJ_STATUS_NEW_NAME}
                                size="xs-custom"
                                variant="danger"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pengubahan Status",
                                        message: <span>Status Surat Jalan akan dikembalikan ke <b>{SJ_STATUS_NEW_NAME}</b>. Lanjutkan?</span>,
                                        onConfirmed: () => {
                                            setLoading(true);
                                            setConfirmationObj(null);

                                            handleChangeDOStatus(
                                                original.ucode,
                                                SJ_STATUS_NEW,
                                                apiCaller,
                                                () => {
                                                    setLoading(false);
                                                    setReload(reload ? 0 : 1);
                                                },
                                                () => {
                                                    setErrorObj({
                                                        message: "Proses perubahan status Surat Jalan gagal!",
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        },
                                    });
                                }}
                            >
                                <i className="fas fa-angle-double-left" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title={original.next_nama_status ? original.next_nama_status : ""}
                                size="xs-custom"
                                variant="primary"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pengubahan Status",
                                        message: <span>Status Surat Jalan akan diubah ke <b>{original.next_nama_status}</b> dan Surat Jalan tidak akan bisa diubah. Lanjutkan?</span>,
                                        onConfirmed: () => {
                                            setLoading(true);
                                            setConfirmationObj(null);

                                            handleChangeDOStatus(
                                                original.ucode,
                                                original.next_kode_status,
                                                apiCaller,
                                                () => {
                                                    setLoading(false);
                                                    setReload(reload ? 0 : 1);
                                                },
                                                () => {
                                                    setErrorObj({
                                                        message: "Proses perubahan status Surat Jalan gagal!",
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        },
                                    });
                                }}
                                disabled={original.kode_status === SJ_STATUS_DELIVERED || original.kode_status === SJ_STATUS_CANCELED || !original.ucode_gudang}
                            >
                                <i className={`fas fa-${original.kode_status === SJ_STATUS_DELIVERED || original.kode_status === SJ_STATUS_SENDING ? "check" : "angle-double-right"}`} />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Batal"
                                size="xs-custom"
                                variant="danger"
                                className="me-1"
                                onClick={() => {
                                    setConfirmationObj({
                                        title: "Konfirmasi Pengubahan Status",
                                        message: <span>Status Surat Jalan akan diubah ke <b className="text-danger">{statusList ? statusList.filter(s => s.kode === SJ_STATUS_CANCELED)[0].nama : ""}</b> dan Surat Jalan tidak akan bisa diubah. Lanjutkan?</span>,
                                        onConfirmed: () => {
                                            setConfirmationObj(null);
                                            setLoading(true);
                                            handleChangeDOStatus(
                                                original.ucode,
                                                SJ_STATUS_CANCELED,
                                                apiCaller,
                                                () => {
                                                    setLoading(false);
                                                    setReload(reload ? 0 : 1);
                                                },
                                                (error) => {
                                                    setErrorObj({
                                                        message: `Proses perubahan status Surat Jalan gagal!${error.response.status === SHOWN_ERROR_CODE ? ` ${error.response.data.data.message}` : ""}`,
                                                    });
                                                    setLoading(false);
                                                }
                                            );
                                        },
                                    })
                                }}
                                disabled={original.kode_status !== SJ_STATUS_NEW || original.kode_status === SJ_STATUS_CANCELED}
                            >
                                <i className="fas fa-times" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Input No. GR"
                                size="xs-custom"
                                variant="primary"
                                className="me-1"
                                onClick={() => {
                                    // console.log({original});
                                    setSelectedDataForGR(original);
                                }}
                                disabled={original.kode_status === SJ_STATUS_NEW || original.kode_status === SJ_STATUS_CANCELED || original.dari_customer}
                            >
                                <i className="fas fa-pen" />
                            </TooltipButton>
                        }
                        {
                            accessLevel.can_edit > 0 &&
                            <TooltipButton
                                title="Input No. Kendaraan"
                                size="xs-custom"
                                variant="primary"
                                className="me-1"
                                onClick={() => {
                                    // console.log({original});
                                    setSelectedDataForVehicleNo(original);
                                }}
                                disabled={original.kode_status === SJ_STATUS_NEW || original.kode_status === SJ_STATUS_CANCELED || original.dari_customer}
                            >
                                <i className="fas fa-truck-moving" />
                            </TooltipButton>
                        }
                            <TooltipButton
                                title="Print"
                                size="xs-custom"
                                variant="dark"
                                className="me-1"
                                onClick={() => {
                                    handlePrint(original.ucode);
                                }}
                                disabled={original.kode_status === SJ_STATUS_CANCELED}
                            >
                                <i className="fas fa-print" />
                            </TooltipButton>
                            <TooltipButton
                                title="Print Tanda Terima"
                                size="xs-custom"
                                variant="dark"
                                className="me-1"
                                onClick={() => {
                                    handlePrintReceipt(original.ucode);
                                }}
                                disabled={original.kode_status === SJ_STATUS_NEW || original.kode_status === SJ_STATUS_CANCELED || original.dari_customer}
                            >
                                <i className="fas fa-clipboard-list" />
                            </TooltipButton>
                        {/* {
                            accessLevel.can_delete > 0 &&
                            <TooltipButton
                                title="Hapus"
                                size="xs-custom"
                                variant="danger"
                                onClick={() => {
                                    setDeleteConfirmation(original);
                                }}
                                disabled={original.kode_status !== SJ_STATUS_NEW}
                            >
                                <i className="fas fa-trash" />
                            </TooltipButton>
                        } */}
                        </InputGroup>
                    )
                },
                width: 250,
                sticky: 'right',
            },
        ]
    }, [apiCaller, handlePrint, reload, accessLevel, handlePrintReceipt, statusList, authState]);

    const grid = useMemo(() => {
        return (
            <DefaultGrid
                columns={columns}
                url="deliveryorders"
                reload={reload}
                rowColorFunction={(data) => {
                    // console.log({data});
                    if (data.kode_status === SJ_STATUS_NEW)
                        return null;

                    if (data.kode_status === SJ_STATUS_CONFIRMED)
                        return {
                            backgroundColor: "#fcfade",
                        };

                    if (data.kode_status === SJ_STATUS_CANCELED)
                        return {
                            backgroundColor: "#ffcbc7",
                        };

                    if (data.kode_status === SJ_STATUS_SENDING)
                        return {
                            backgroundColor: "#fad9fc",
                        };
                    
                    return {
                        backgroundColor: "#e3fcde",
                    };
                }}
                reloadCallback={() => loadNotifCount()}
                initialSortBy={[
                    {
                        id: "tanggal_transaksi",
                        desc: "desc",
                    }
                ]}
                excelFileNameTemplate="surat_jalan_internal"
                exportToExcel={exportToExcel}
                setExportToExcel={setExportToExcel}
                additionalParams={`emptygr=${showOnlyEmptyGrNo}`}
            />
        );
    }, [reload, columns, loadNotifCount, exportToExcel, showOnlyEmptyGrNo]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
            <Container fluid>
                <Row className="mb-3">
                    <Col xs={12}>
                        <InputGroup>
                        {
                            accessLevel.can_add > 0 ?
                            <Button size="xs-custom" variant="dark" className="me-1" onClick={() => setItemInEdit({})}><i className="fas fa-plus me-1" />Tambah Data</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => setExportToExcel(true)}><i className="fas fa-file-excel me-1" />Export Excel</Button>
                            <Button size="xs-custom" variant="primary" onClick={() => setReload(reload === 0 ? 1 : 0)}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                        </InputGroup>
                    </Col>
                    <Col xs={12} className="mt-2">
                        <Form.Check
                            className="grid-filter-checkbox cursor-pointer d-flex flex-row justify-content-start align-items-center font-sm-custom"
                            label="Tampilkan data tanpa No. GR"
                            onChange={({target: {checked}}) => {
                                setShowOnlyEmptyGrNo(checked);
                                setReload(1);
                            }}
                            checked={showOnlyEmptyGrNo}
                        />
                    </Col>
                </Row>
            {
                grid
            }
            {
                itemInEdit ?
                <SuratJalanEditor
                    onHide={() => setItemInEdit(null)}
                    data={itemInEdit}
                    onSave={() => {
                        setItemInEdit(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                />
                :
                null
            }
            {
                deleteConfirmation ?
                <ModalAlert
                    onHide={() => setDeleteConfirmation(null)}
                    onSave={() => handleDelete(deleteConfirmation.ucode)}
                    title="Konfirmasi Penghapusan Data"
                    bodyText={`Apakah anda yakin akan menghapus data surat jalan ${deleteConfirmation.no_transaksi}?`}
                />
                :
                null
            }
            {
                confirmationObj ?
                <ModalAlert
                    onHide={() => setConfirmationObj(null)}
                    onSave={() => confirmationObj.onConfirmed()}
                    title={confirmationObj.title}
                    bodyText={confirmationObj.message}
                />
                :
                null
            }
            {
                errorObj ?
                <ErrorAlert
                    onHide={() => setErrorObj(null)}
                    bodyText={errorObj.message}
                />
                :
                null
            }
            {
                selectedDataForGR ?
                <SuratJalanGREditor
                    data={selectedDataForGR}
                    onClose={() => {
                        setSelectedDataForGR(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                />
                :
                null
            }
            {
                selectedDataForVehicleNo ?
                <SuratJalanVehicleNoEditor
                    data={selectedDataForVehicleNo}
                    onClose={() => {
                        setSelectedDataForVehicleNo(null);
                        setReload(reload === 1 ? 0 : 1);
                    }}
                />
                :
                null
            }
            </Container>
        </>
    );
}

export default SuratJalan;
