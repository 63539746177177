import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import DefaultGrid from '../../../components/DefaultGrid';
import Loader from '../../../components/Loader';
import { useStateContext } from '../../../reducers';
import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { XLSX_FILE_EXTENSION, XLSX_FILE_TYPE } from '../../../utils/Common';
import StokOpnameUploadModal from './StokOpnameUploadModal';
import { ComboBox } from '../../../components/Dropdowns';

function StokOpname(props) {
    const {accessLevel} = props;
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showAllDivision, setShowAllDivision] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [reloadWarehouse, setReloadWarehouse] = useState(false);
    const [lastOpname, setLastOpname] = useState(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [userDivision, setUserDivision] = useState(null);
    const { authState, apiCaller } = useStateContext();

    useEffect(() => {
        setShowAllDivision(accessLevel?.see_other_div ?? false);
    }, [accessLevel]);

    useEffect(() => {
        if (!authState?.div_code)
            return;

        setLoading(true);
        apiCaller.get(`divisions/search/code?code=${authState?.div_code}`)
            .then((response) => {
                // console.log({response});
                setUserDivision({
                    ...response.data.data
                });
                setReloadWarehouse(true);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [authState?.div_code]);

    const onDivisionSelected = useCallback((val) => {
        // console.log({val});
        if (selectedDivision?.ucode !== val?.ucode && selectedWarehouse)
        {
            setSelectedWarehouse(null);
            setReloadWarehouse(true);
        }

        setSelectedDivision(val ? {...val} : null);
    }, [selectedDivision, selectedWarehouse]);

    useEffect(() => {
        if (!reloadWarehouse)
            return;
        
        setSelectedWarehouse(null);
        setReloadWarehouse(false);
    }, [reloadWarehouse]);

    const onWarehouseSelected = useCallback((val) => {
        // console.log({val});
        setSelectedWarehouse(val ? {...val} : null);
    }, []);

    const loadData = useCallback(() => {
        if (!selectedDivision || !selectedWarehouse)
        {
            setLastOpname(null);
            return;
        }

        setLoading(true);

        apiCaller.get(`stocktakings/search/latest?division_id=${selectedDivision.ucode}&warehouse_id=${selectedWarehouse.ucode}`)
            .then((response) => {
                // console.log({response});
                setLastOpname(response.data?.data ? {...response.data.data} : null);
                setLoading(false);
                setReload(reload === 0 ? 1 : 0);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [selectedWarehouse, apiCaller, reload, selectedDivision]);

    useEffect(() => {
        loadData();
    }, [selectedWarehouse]);

    const handleDownloadTemplate = useCallback(() => {
        setLoading(true);
        apiCaller.get("items/active/all")
            .then((response) => {
                if (!response.data.data || response.data.data.length === 0)
                    return;

                const ws = XLSX.utils.json_to_sheet(response.data.data);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
                const data = new Blob([excelBuffer], {type: XLSX_FILE_TYPE})
                FileSaver.saveAs(data, `stok_opname_template_${selectedDivision.nama}_${selectedWarehouse.nama}_${moment(new Date()).format("YYYYMMDD_HHmmss")}${XLSX_FILE_EXTENSION}`);
            })
            .catch((error) => {
                console.error({error});
            })
            .finally(() => setLoading(false))
        ;
    }, [apiCaller, selectedDivision, selectedWarehouse]);

    const columns = useMemo(() => [
        {
            Header: "Kode Barang",
            accessor: "kode_brg",
        },
        {
            Header: "Nama Barang",
            accessor: "nama_brg",
        },
        {
            Header: "Kode Satuan",
            accessor: "kode_satuan",
        },
        {
            Header: "Nama Satuan",
            accessor: "nama_satuan",
        },
        {
            Header: "Qty",
            accessor: "qty_opname",
            disableFilters: true,
        },
    ], []);

    const grid = useMemo(() => {
        // console.log({lastOpname, reload});
        return (
            <DefaultGrid
                columns={columns}
                url={`stocktakings/detail/all`}
                additionalParams={lastOpname && lastOpname.ucode ? `id=${lastOpname.ucode}` : ""}
                reload={reload}
            />
        );
    }, [reload, columns, lastOpname]);

    return (
        <>
        {
            loading ? <Loader /> : null
        }
        {
            showUploadModal && (selectedDivision || userDivision) && selectedWarehouse &&
            <StokOpnameUploadModal
                division={{ucode: selectedDivision?.ucode ?? userDivision.ucode, nama: selectedDivision?.nama ?? userDivision.nama}}
                warehouse={{ucode: selectedWarehouse?.ucode, nama: selectedWarehouse?.nama}}
                onHide={() => setShowUploadModal(false)}
                onSave={() => {
                    setShowUploadModal(false);
                    loadData();
                }}
            />
        }
            <Container fluid>
                <Row className="mb-2 font-sm-custom">
                    {
                        showAllDivision ?
                        <Col lg={3} md={12}>
                            <Form.Label>
                                Divisi: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="divisions/search/name"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onDivisionSelected(val)}
                                selectedItem={selectedDivision}
                                authState={authState}
                                minLength={0}
                            />
                        </Col>
                        :
                        null
                    }
                    <Col lg={3} md={12}>
                        <Form.Label>
                            Gudang: <span className="text-danger">*</span>
                        </Form.Label>
                        <ComboBox
                            endpoint="warehouses/search/division"
                            idField="ucode"
                            valueField="nama"
                            isLookup={false}
                            onChange={(val) => onWarehouseSelected(val)}
                            selectedItem={selectedWarehouse}
                            authState={authState}
                            minLength={0}
                            additionalParam={{
                                id: selectedDivision?.ucode ?? userDivision?.ucode
                            }}
                            disabled={showAllDivision && !selectedDivision}
                        />
                    </Col>
                </Row>
                <Row className="mb-2 font-sm-custom">
                    <Col xs={12} className="d-flex flex-wrap justify-content-start align-items-center">
                        <InputGroup>
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => handleDownloadTemplate()} disabled={!selectedWarehouse}><i className="fas fa-file-download me-1" />Download Template</Button>
                        {
                            (accessLevel.can_add && accessLevel.can_edit > 0) ?
                            <Button size="xs-custom" variant="success" className="me-1" onClick={() => setShowUploadModal(true)} disabled={!selectedWarehouse}><i className="fas fa-file-upload me-1" />Upload Stok Sparepart</Button>
                            :
                            null
                        }
                            <Button size="xs-custom" variant="primary" onClick={() => loadData()} disabled={!selectedWarehouse}><i className="fas fa-sync-alt me-1" />Refresh</Button>
                            <span className="ms-3">Tanggal Opname Terakhir: {lastOpname ? moment(lastOpname.tanggal_transaksi).format("DD-MM-YYYY HH:mm") : "-"}</span>
                            <span className="ms-3">Tanggal Upload Terakhir: {lastOpname ? moment(lastOpname.created_at).format("DD-MM-YYYY HH:mm") : "-"}</span>
                        </InputGroup>
                    </Col>
                </Row>
                {
                    grid
                }
            </Container>
        </>
    );
}

export default StokOpname;
