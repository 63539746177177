import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import { ComboBox } from "../../../components/Dropdowns";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";
import { objectWithoutProperties } from "../../../utils/Common";
import BarangCustomerEditor from "./BarangCustomerEditor";
import BarangGrupEditor from "./BarangGrupEditor";
import BarangKategoriEditor from "./BarangKategoriEditor";

function BarangEditor(props) {
    const {
        onHide,
        data,
        onSave
    } = props;

    const [itemInEdit, setItemInEdit] = useState({...data, satuan: {ucode: data?.ucode_satuan, nama: data?.nama_satuan}});
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [showCategoryEditor, setShowCategoryEditor] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showGroupEditor, setShowGroupEditor] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [showCustomerEditor, setShowCustomerEditor] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const { apiCaller, authState } = useStateContext();

    const loadDetail = useCallback(() => {
        if (!data.ucode)
            return;

        setLoading(true);

        apiCaller.get(`items/search/id?id=${data.ucode}`)
            .then((response) => {
                const {data: {data: {categories, groups, customers}}} = response;
                // console.log({response, categories, groups});

                setSelectedCategories([...categories]);
                setSelectedGroups([...groups]);
                setSelectedCustomers([...customers]);
            })
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
    }, [data, apiCaller]);

    useEffect(() => {
        if (!data?.ucode)
            return;
        
        loadDetail();
    }, [data]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    useEffect(() => {
        setDisableSave(!itemInEdit || !itemInEdit.nama || !itemInEdit.ucode_satuan || !selectedCategories?.length || !selectedGroups?.length);
    }, [itemInEdit, selectedCategories, selectedGroups]);

    const handleSave = useCallback(() => {
        if (!itemInEdit)
            return;
        
        setLoading(true);
        // console.log({itemInEdit});
        const dataToSend = {...objectWithoutProperties(itemInEdit, [
            "satuan",
            "kode_satuan",
            "nama_satuan",
        ])};
        dataToSend.kategori_brg_list = selectedCategories.map(c => ({ucode: c.ucode}));
        dataToSend.group_brg_list = selectedGroups.map(c => ({ucode: c.ucode}));
        dataToSend.customer_list = selectedCustomers.map(c => ({ucode_customer: c.ucode_customer, kode: c.kode, nama: c.nama !== "" ? c.nama : null}));
        const request = dataToSend && !dataToSend.ucode ? apiCaller.post(`items`, JSON.stringify(dataToSend)) : apiCaller.put(`items/${dataToSend.ucode}`, JSON.stringify(dataToSend));

        request
            .then((response) => {
                // console.log({response});
                setLoading(false);
                onSave();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [itemInEdit, selectedCategories, selectedGroups, selectedCustomers, apiCaller, onSave]);

    const onItemUnitSelected = useCallback((val) => {
        // console.log({val});
        setItemInEdit({
            ...itemInEdit,
            ucode_satuan: val?.ucode,
            satuan: val,
        });
    }, [itemInEdit]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-between">
                <Col lg={8} md={12} className="d-flex flex-wrap justify-content-md-start m-0 p-0 px-1">
                    <Button
                        variant="primary"
                        type="submit"
                        className="me-1"
                        onClick={() => setShowCategoryEditor(true)}
                        size="sm-custom"
                    >
                        Kategori
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        className="me-1"
                        onClick={() => setShowGroupEditor(true)}
                        size="sm-custom"
                    >
                        Grup
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        className="me-1"
                        onClick={() => setShowCustomerEditor(true)}
                        size="sm-custom"
                    >
                        Nama Lain Customer
                    </Button>
                </Col>
                <Col lg={4} md={12} className="d-flex flex-wrap justify-content-md-end m-0 p-0 px-1">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="me-1"
                        onClick={() => onHide()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                    <Button
                        variant="success"
                        type="submit"
                        onClick={() => handleSave()}
                        disabled={disableSave}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [disableSave, onHide, handleSave]);

    return (
        <>
        {
            !showCategoryEditor && !showGroupEditor && !showCustomerEditor &&
            <CustomModal
                show={true}
                onHide={() => onHide()}
                title="Barang"
                titleDetails={data.ucode ? "Edit" : "Baru"}
                footer={footer}
            >
            {
                loading ? <Loader /> : null
            }
                <Container>
                    <Row className="justify-content-md-start mb-3">
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Kode:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.kode ? itemInEdit.kode : ""}
                                name="kode"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Nama: <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.nama ? itemInEdit.nama : ""}
                                name="nama"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Kode Lain:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.kode_lain ? itemInEdit.kode_lain : ""}
                                name="kode_lain"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Nama Lain:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={itemInEdit !== null && itemInEdit.nama_lain ? itemInEdit.nama_lain : ""}
                                name="nama_lain"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                            />
                        </Col>
                        <Col lg="6" md="12" className="mb-3">
                            <Form.Label>
                                Satuan: <span className="text-danger">*</span>
                            </Form.Label>
                            <ComboBox
                                endpoint="units/search/name"
                                idField="ucode"
                                valueField="nama"
                                isLookup={false}
                                onChange={(val) => onItemUnitSelected(val)}
                                selectedItem={itemInEdit?.satuan}
                                authState={authState}
                                minLength={0}
                            />
                        </Col>
                        <Col lg="12" className="mb-3">
                            <Form.Label>
                                Keterangan:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemInEdit !== null && itemInEdit.keterangan ? itemInEdit.keterangan : ""}
                                name="keterangan"
                                onChange={(e) => {
                                    handleTextChanged(e);
                                }}
                                autoComplete="off"
                                rows={5}
                            />
                        </Col>
                        <Col lg="12" className="mb-1">
                            <Form.Label>
                                Kategori: <span className="text-danger">*</span><b className="ms-2">{selectedCategories.map(c => c.nama).join(", ") ?? "-"}</b>
                            </Form.Label>
                        </Col>
                        <Col lg="12" className="mb-1">
                            <Form.Label>
                                Grup: <span className="text-danger">*</span><b className="ms-2">{selectedGroups.map(c => c.nama).join(", ") ?? "-"}</b>
                            </Form.Label>
                        </Col>
                    </Row>
                </Container>
            </CustomModal>
        }
        {
            showCategoryEditor &&
            <BarangKategoriEditor
                itemName={itemInEdit?.nama}
                initialCategories={selectedCategories}
                onHide={(selectedCategories, isReset = false) => {
                    setShowCategoryEditor(false);

                    if (!isReset)
                        setSelectedCategories(selectedCategories);
                }}
                authState={authState}
            />
        }
        {
            showGroupEditor &&
            <BarangGrupEditor
                itemName={itemInEdit?.nama}
                initialGroups={selectedGroups}
                onHide={(selectedGroups, isReset = false) => {
                    setShowGroupEditor(false);

                    if (!isReset)
                        setSelectedGroups(selectedGroups);
                }}
                authState={authState}
            />
        }
        {
            showCustomerEditor &&
            <BarangCustomerEditor
                itemName={itemInEdit?.nama}
                initialCustomers={selectedCustomers}
                onHide={(selectedCustomers, isReset = false) => {
                    setShowCustomerEditor(false);

                    if (!isReset)
                        setSelectedCustomers(selectedCustomers);
                }}
                authState={authState}
            />
        }
        </>
    );
};

export default BarangEditor;