import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import CustomModal from "../../../components/CustomModal";
import Loader from "../../../components/Loader";
import { useStateContext } from "../../../reducers";

function SuratJalanVehicleNoEditor(props) {
    const {
        data,
        onClose,
    } = props;

    const [loading, setLoading] = useState(false);
    const [itemInEdit, setItemInEdit] = useState({...data});
    const { apiCaller } = useStateContext();

    // useEffect(() => console.log({itemInEdit}), [itemInEdit]);

    const handleTextChanged = useCallback(({target: {value, name}}) => {
        // console.log({value, name});
        setItemInEdit({...itemInEdit, [name]: value});
    }, [itemInEdit]);

    const handleSave = useCallback(() => {
        setLoading(true);

        apiCaller.put(`deliveryorders/update/vehicleno/${itemInEdit.ucode}`, JSON.stringify({ucode: itemInEdit.ucode, nopol: itemInEdit.nopol}))
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, itemInEdit, onClose]);

    const footer = useMemo(() => (
        <Container fluid>
            <Row className="justify-content-md-end">
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => onClose()}
                        size="sm-custom"
                    >
                        Batal
                    </Button>
                </Col>
                <Col xl="2" lg="4" md="12" className="m-0 p-0 px-1 mb-2">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-100"
                        onClick={() => handleSave()}
                        disabled={!itemInEdit?.nopol || itemInEdit.nopol === ""}
                        size="sm-custom"
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
        </Container>
    ), [onClose, handleSave, itemInEdit]);

    return (
        <CustomModal
            show={true}
            onHide={() => onClose()}
            title="Input No. Polisi Kendaraan"
            titleDetails={itemInEdit?.no_transaksi ?? "N/A"}
            footer={footer}
            additionalContentClassName="modal-h-sm"
        >
        {
            loading ? <Loader /> : null
        }
            <Container>
                <Row className="m-0 p-0">
                    <Col lg="12" className="m-0 p-0">
                        <Form.Label>
                            No. Polisi Kendaraan: <span className="text-danger">*</span>
                        </Form.Label>
                    </Col>
                    <Col lg="12" className="m-0 p-0">
                        <Form.Control
                            value={itemInEdit !== null && itemInEdit.nopol ? itemInEdit.nopol : ""}
                            name="nopol"
                            onChange={(e) => {
                                handleTextChanged(e);
                            }}
                            autoComplete="off"
                        />
                    </Col>
                </Row>
            </Container>
        </CustomModal>
    );
};

export default SuratJalanVehicleNoEditor;
